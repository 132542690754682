import { useSearchParams } from '@remix-run/react';
import { type FunctionComponent } from 'react';
import { amountTypeText, bodyTypeText } from '~/constants/searchTypeText';
import { CarStockSearchAmountTypeEnum, CarStockSearchBodyTypeEnum } from '~/gql/generated/graphql';
import { useCarsSearchParams } from '~/hooks';
import styles from '~/styles/page/cars/carsIndex/car-search-result.module.css';

const blockClass = 'car-search-result';

export const CarsSearchResult: FunctionComponent = () => {
  const [searchParams] = useSearchParams();
  const { clearScope } = useCarsSearchParams();
  const bodyType = searchParams.get('bodyType');
  const amountType = searchParams.get('amountType');
  const nameKeyword = searchParams.get('nameKeyword');
  const searchText =
  bodyType && Object.values(CarStockSearchBodyTypeEnum).some((value) => value === bodyType) ?
  bodyTypeText[bodyType as CarStockSearchBodyTypeEnum] :
  amountType && Object.values(CarStockSearchAmountTypeEnum).some((value) => value === amountType) ?
  amountTypeText[amountType as CarStockSearchAmountTypeEnum] :
  nameKeyword ?
  nameKeyword :
  '';

  if (!searchText) {
    return <div />;
  }

  return (
    <div className={styles[blockClass]}>
      <p className={styles[`${blockClass}__title`]}>絞り込み</p>
      <div className={styles[`${blockClass}__text`]}>
        <p>{searchText}</p>
        <div className={styles[`${blockClass}__text-close`]} onClick={clearScope} />
      </div>
    </div>);

};