import liff from '@line/liff';
import { useDisclosure } from '@mantine/hooks';
import { useNavigate, useSearchParams } from '@remix-run/react';
import { useEffect, type FunctionComponent } from 'react';
import { useMutation } from 'urql';
import { Loading } from '~/components/parts';
import { LoginRetryModal } from '~/components/shared';
import { loginMutationDocument } from '~/gql/mutations';
import { useAnalytics } from '~/hooks';
import { useAuthContext, useLiffContext } from '~/providers';

export const CallbackIndex: FunctionComponent = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { setIsPauseFetchUser } = useAuthContext();
  const login = useMutation(loginMutationDocument)[1];
  const [opened, { open }] = useDisclosure(false);
  const redirectPath = searchParams.get('redirectPath');
  const loginSourcePath = searchParams.get('loginSourcePath');
  const action = searchParams.get('action');
  const customUserId = searchParams.get('customUserId');
  const gclid = searchParams.get('gclid');
  const isRedirectExternal = searchParams.get('redirectExternal') === 'true';
  const redirectUrl = searchParams.get('redirectUrl');
  const { isMiniApp } = useLiffContext();
  const { sendTrackEvent } = useAnalytics();

  useEffect(() => {
    if (!searchParams) return;

    liff.ready.then(async () => {
      const decodedRedirectPath = redirectPath ? decodeURI(redirectPath) : '/';
      if (!liff.isLoggedIn()) {
        handleError();
        return;
      }

      const idToken = liff.getIDToken();
      if (!idToken) {
        handleError();
        return;
      }

      try {
        const loginResult = await login({ input: { idToken: idToken, isCreateUser: true, fromMiniapp: isMiniApp } });
        const user = loginResult.data?.login?.user;
        const isCreatedUser = loginResult.data?.login?.isCreatedUser;

        if (!user) {
          handleError();
          return;
        }

        sendTrackEvent('create_user_event', {
          user_id: user.id,
          login_source_path: loginSourcePath,
          is_create: isCreatedUser,
          transition_source_custom_user_id: customUserId,
          action
        });

        if (isCreatedUser) {
          sendGclid(user.id);
        }

        setIsPauseFetchUser(false);

        if (isRedirectExternal && redirectUrl) {
          window.location.replace(redirectUrl);
          return;
        }

        navigate(decodedRedirectPath);
      } catch (e) {
        handleError();
      }
    });

    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const handleError = () => {
    open();
    sendTrackEvent('login_error');
  };

  const sendGclid = (userId: string) => {
    if (!gclid) return;

    sendTrackEvent('send_gclid', {
      userId,
      tracking_gclid: gclid
    });
  };

  return (
    <>
      <Loading isFull />
      <LoginRetryModal opened={opened} redirectPath={redirectPath || '/'} />
    </>
  );
};
