import { type FunctionComponent } from 'react';
import { Breadcrumb } from '~/components/shared';

export const ReturnPolicyIndex: FunctionComponent = () => {
  return (
    <>
      <Breadcrumb text='返品に関する特約' />
      <h1 className='headline1'>返品に関する特約</h1>
      <p className='c-text'>
        この返品に関する特約（以下「本特約」といいます。）は、BUDDICA・DIRECT株式会社（以下「当社」といいます）が別途定めるBUDDICA
        DIRECT利用規約の特約を定めるものです。本特約に定めのない事項は、本規約が適用されるものとし、本特約において特段の定めがない限り、本特約における用語の定義は本規約に定めるとおりとします。なお、本特約と本規約が矛盾抵触する場合は、本特約の定めを優先して適用するものとします。
      </p>
      <p className='c-text'>
        会員は、次の各項に定める条件をすべて満たした場合には、掲載企業との間で締結した売買契約を解除して、当該契約の対象車両を掲載企業に返品することができます。
      </p>
      <ol className='c-numList'>
        <li>納車日の翌日から7日以内に、当該車両の売買契約を解除する旨を記載した書面を、会員から掲載企業に発すること</li>
        <li>当該車両の納車後の走行距離が1,000ｋｍ以下であること</li>
        <li>当該車両の納車後に新たに傷・凹みが付いていないこと</li>
        <li>当該車両の納車後に事故を起こしていないこと</li>
        <li>当該車両の納車時に付されていた機能や機器が納車時の状態で存在していること</li>
        <li>当該車両の納車後に通常の走行以外の用途（レース、ラリー等）で使用していないこと</li>
        <li>納車時と解除時で、当該車両の車検証上の名義人が同一であること</li>
        <li>解除時に自動車税、駐車禁止違反反則金等の当該車両に係る債務が存在しないこと</li>
        <li>
          解除時に当該車両が債務を担保するための目的物となっているなど、当該車両に対する処分権限が妨げられる権利、事由が存在しないこと
        </li>
      </ol>
      <h2 className='headline2'>返品手続き</h2>
      <p className='c-text'>
        会員は、次の各号に定める手続を経て、掲載企業より売買契約で定めた売買代金の返還を受けることができます。
        <br />
        ※会員は、掲載企業が次の第3項に定める必要書類一式を発送した日の翌日から7日以内に、次の各号に定める手続を履行するものとします。
        <br />
        ※当該履行期限内に次の各号に定める手続の履行がなされない場合、掲載企業は、会員への通知により、売買契約の解除の不成立を確定させることができます。
      </p>
      <ol className='c-numList'>
        <li>掲載企業による当該車両の状態の確認</li>
        <li>当該車両の掲載企業への引渡し</li>
        <li>掲載企業に対する必要書類一式の交付</li>
      </ol>
      <h2 className='headline2'>返品手数料</h2>
      <p className='c-text'>掲載企業は、会員が負担すべき費用を会員に返還する金額から差し引くことができます。</p>
      <ol className='c-numList'>
        <li>返品に関わる手数料：一律3万円</li>
        <li>返品に関わる商品の送料：納車費用と同額</li>
        <li>返金のための振込手数料</li>
      </ol>
      <p className='c-text c-text--right'>2024年1月20日 制定</p>
    </>
  );
};
