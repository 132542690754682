import { type FunctionComponent } from 'react';
import { amountTypeText, bodyTypeText } from '~/constants/searchTypeText';
import { CarStockSearchAmountTypeEnum, CarStockSearchBodyTypeEnum } from '~/gql/generated/graphql';
import styles from '~/styles/page/cars/carsIndex/car-search-section.module.css';

const blockClass = 'car-search-section';

type BodyType = {
  key: CarStockSearchBodyTypeEnum;
  icon: string;
};

type AmountType = {
  key: CarStockSearchAmountTypeEnum;
  icon: string;
};

type Props = {
  onSelectType: (type: CarStockSearchBodyTypeEnum) => void;
  onSelectAmount: (type: CarStockSearchAmountTypeEnum) => void;
};

export const CarsSearchSection: FunctionComponent<Props> = (props) => {
  const { onSelectType, onSelectAmount } = props;
  const types: BodyType[] = [
  { key: CarStockSearchBodyTypeEnum.Keicar, icon: '/images/icons/ic_car_kei.svg' },
  { key: CarStockSearchBodyTypeEnum.Compact, icon: '/images/icons/ic_car_compact.svg' },
  { key: CarStockSearchBodyTypeEnum.Suv, icon: '/images/icons/ic_car_suv.svg' },
  { key: CarStockSearchBodyTypeEnum.Minivan, icon: '/images/icons/ic_car_minivan.svg' },
  { key: CarStockSearchBodyTypeEnum.Other, icon: '/images/icons/ic_car_other.svg' }];

  const amounts: AmountType[] = [
  { key: CarStockSearchAmountTypeEnum.Low, icon: '/images/icons/ic_coin_few.svg' },
  { key: CarStockSearchAmountTypeEnum.Mid, icon: '/images/icons/ic_coin_normal.svg' },
  { key: CarStockSearchAmountTypeEnum.High, icon: '/images/icons/ic_coin_many.svg' }];


  return (
    <div className={styles[blockClass]}>
      <div className={styles[`${blockClass}__group`]}>
        <p className={styles[`${blockClass}__group-title`]}>タイプから見つける</p>
        <div className={styles[`${blockClass}__group-tiles`]}>
          {types.map((type, index) =>
          <div className={styles[`${blockClass}__group-tile`]} key={index} onClick={() => onSelectType(type.key)}>
              <div>
                <img src={type.icon} alt='car' className={styles[`${blockClass}__group-tile__image`]} />
                <p className={styles[`${blockClass}__group-tile__text`]}>{bodyTypeText[type.key]}</p>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={styles[`${blockClass}__group`]}>
        <p className={styles[`${blockClass}__group-title`]}>価格から見つける</p>
        <div className={styles[`${blockClass}__group-tiles`]}>
          {amounts.map((amount, index) =>
          <div
            className={styles[`${blockClass}__group-price-tile`]}
            key={index}
            onClick={() => onSelectAmount(amount.key)}>

              <div>
                <img src={amount.icon} alt='car' className={styles[`${blockClass}__group-price-tile__image`]} />
                <p className={styles[`${blockClass}__group-price-tile__text`]}>{amountTypeText[amount.key]}</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>);

};