import { useNavigate } from '@remix-run/react';
import Cookies from 'js-cookie';
import { useMemo } from 'react';
import { SubmitButton } from '~/components/shared/SubmitButton/SubmitButton';
import type { UserQuery } from '~/gql/generated/graphql';
import { queryParamStringify } from '~/utils/url-param';

type Props = {
  carStockId: string;
  ulid: string;
  activeOneClickContracts: UserQuery['viewer']['activeOneClickContracts'] | undefined;
  handleClick: () => void;
};

export const OneClickContractButton = ({ carStockId, ulid, activeOneClickContracts, handleClick }: Props) => {
  const isActive = useMemo(() => {
    const carStockIds = activeOneClickContracts?.flatMap(({ carStock }) => carStock?.id || []) || [];
    return carStockIds.includes(carStockId);
  }, [activeOneClickContracts, carStockId]);
  const searchQueryParameter = useMemo(() => Cookies.get('search-query-parameter'), []);
  const qs = queryParamStringify({
    params: {
      carStockUlids: ulid,
      search: searchQueryParameter
    },
    includeQuestion: true
  });

  const navigate = useNavigate();
  return (
    <SubmitButton
      variant='contained'
      handleClick={() => {
        handleClick();
        navigate(`/one-click-contract/new${qs}`);
      }}
      text={isActive ? '相談中の車両です' : 'LINEで相談'}
      icon={<img src={'/images/icons/ic_line.svg'} alt='line-logo' />}
      disable={isActive}
    />
  );
};
