import { useInViewport } from '@mantine/hooks';
import { useLocation, useNavigate } from '@remix-run/react';
import { useEffect, useRef, useState, type FunctionComponent } from 'react';
import { graphql, useFragment, type FragmentType } from '~/gql/generated';
import { useFavorite } from '~/hooks';
import { useErrorFlashContext, useInfiniteScrollContext, useLineFavoriteModalContext } from '~/providers';
import styles from '~/styles/page/cars/carsMovies/car-card.module.css';

const blockClass = 'car-card';

const CarCardCarStockFragment = graphql(`
  fragment CarCard_carStock on CarStock {
    id
    ulid
    mileageKm
    carModelName
    shortGradeName
    salesStatus
    tags {
      id
      name
    }
    promotion {
      id
      catchCopy
      staffComment
      postedBy {
        id
        imageUrl
      }
    }
    video {
      id
      url
      thumbnailUrl
    }
  }
`);

type Props = {
  carStock: FragmentType<typeof CarCardCarStockFragment>;
  isFavorited: boolean;
  movieIndex: number;
  showPreview: boolean;
  openPopup: (index: number) => void;
};

export const CarCard: FunctionComponent<Props> = (props) => {
  const carStock = useFragment(CarCardCarStockFragment, props.carStock);
  const { isFavorited, openPopup, movieIndex, showPreview } = props;
  const navigate = useNavigate();
  const { favorite } = useFavorite();
  const { open: openLineFavoriteModal } = useLineFavoriteModalContext();
  const { open: openErrorFlash } = useErrorFlashContext();
  const location = useLocation();
  const elementId = `car-card-${carStock.id}`;
  const { storeElementId } = useInfiniteScrollContext();
  const [isShowVideo, setIsShowVideo] = useState(false);
  const [isLoadedVideo, setIsLoadedVideo] = useState(false);
  const [startShowVideoTimer, setStartShowVideoTimer] = useState(false);
  const { ref, inViewport } = useInViewport();
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (!showPreview) return;

    if (inViewport) {
      setStartShowVideoTimer(true);
    } else {
      setStartShowVideoTimer(false);
      setIsShowVideo(false);
      setIsLoadedVideo(false);
    }
  }, [inViewport, showPreview]);

  useEffect(() => {
    if (!startShowVideoTimer) return;

    setTimeout(async () => {
      setIsShowVideo(true);
    }, 700);
  }, [startShowVideoTimer]);

  useEffect(() => {
    if (!isShowVideo) return;
    if (!videoRef.current) return;

    videoRef.current.onloadeddata = () => {
      setIsLoadedVideo(true);
    };
  }, [isShowVideo]);

  const handleClickFavorite = async (e: React.MouseEvent) => {
    e.stopPropagation();

    const { isAuthError, isSuccess } = await favorite({
      isFavorited,
      carStockId: carStock.id,
      eventFrom: 'car_movies_card'
    });

    if (isAuthError) {
      openLineFavoriteModal();
      return;
    }

    if (!isSuccess) {
      openErrorFlash();
      return;
    }
  };

  const handleClickCard = () => {
    storeElementId(location.key, elementId);
    navigate(`/cars/${carStock.ulid}`);
  };

  return (
    <div
      className={[styles[blockClass], carStock.salesStatus === 'CLOSED' ? styles['is-soldout'] : ''].join(' ')}
      id={elementId}
      ref={ref}>

      <div className={styles[`${blockClass}__movie`]} onClick={() => openPopup(movieIndex)}>
        {carStock.video?.url &&
        <>
            {showPreview && isShowVideo &&
          <video
            ref={videoRef}
            src={carStock.video.url}
            className={styles[`${blockClass}__movie-video`]}
            playsInline
            autoPlay
            muted
            loop
            poster={carStock.video.thumbnailUrl || '/images/pictures/video_loading.png'}
            style={{ display: isLoadedVideo ? 'block' : 'none' }} />

          }
            <img
            src={carStock.video.thumbnailUrl || '/images/pictures/video_loading.png'}
            className={styles[`${blockClass}__movie-thumbnail`]}
            alt='video_thumbnail' />

          </>
        }
        <div className={styles[`${blockClass}__movie-favorite`]} onClick={handleClickFavorite}>
          <img
            src={`/images/icons/${isFavorited ? 'ic_favorite_filled.svg' : 'ic_favorite_white.svg'}`}
            alt='favorite' />

        </div>
        {carStock.salesStatus === 'CLOSED' && <div className={styles[`${blockClass}__movie-soldout`]}>SOLD OUT</div>}
      </div>
      <div className={styles[`${blockClass}__wrapper`]} onClick={handleClickCard}>
        {carStock.promotion &&
        <div className={styles[`${blockClass}__comment`]}>
            <p className={styles[`${blockClass}__comment-title`]}>{carStock.promotion.catchCopy}</p>
            <div className={styles[`${blockClass}__comment-staff`]}>
              <div className={styles[`${blockClass}__comment-staff__icon`]}>
                <img src={carStock.promotion.postedBy?.imageUrl || '/images/pictures/no_image.png'} alt='icon' />
              </div>
              <p className={styles[`${blockClass}__comment-staff__text`]}>{carStock.promotion.staffComment}</p>
            </div>
          </div>
        }
      </div>
    </div>);

};