import { type FunctionComponent } from 'react';
import styles from '~/styles/page/cars/carsIndex/car-special-detail.module.css';

const blockClass = 'car-specialDetail';

export const OutdoorView: FunctionComponent = () => {
  return (
    <>
      <div className={styles[`${blockClass}`]}>
        <div className={styles[`${blockClass}__main`]}>
          <img src='/images/pictures/special_outdoor_car.jpg' alt='' />
        </div>
        <h1 className={styles[`${blockClass}__title`]}>アウトドアに最適な車 特集</h1>
        <p className={styles[`${blockClass}__text`]}>自然の中で輝く、冒険に最適なモデルを紹介</p>
      </div>
    </>);

};