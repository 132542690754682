import type { VariantProps } from 'tailwind-variants';
import { tv } from 'tailwind-variants';

const negotiationLabelVariants = tv({
  base: 'absolute bottom-0 grid w-full place-items-center rounded-b-md bg-attention',
  variants: {
    size: {
      sm: 'h-[18px] text-[10px]',
      md: 'h-[30px] text-[14px]'
    }
  },
  defaultVariants: {
    size: 'sm'
  }
});

type Props = {
  isFavorite: boolean;
  count: number;
  src?: string;
  handleFavorite?: () => void;
  alt: string;
} & VariantProps<typeof negotiationLabelVariants>;
export const CarCardImageNegotiation = ({ isFavorite, size, count, src, handleFavorite, alt }: Props) => {
  return (
    <div className='relative size-full'>
      <div className='relative size-full'>
        <img
          className='size-full rounded-md border-2 border-solid border-attention object-cover'
          src={src ? src : '/images/pictures/no_image.png'}
          alt={alt}
        />
        <div className={negotiationLabelVariants({ size })}>
          <span className='font-bold'>{count}件商談中のおクルマ</span>
        </div>
      </div>
      <img
        onClick={(e) => {
          e.stopPropagation();
          handleFavorite?.();
        }}
        className='absolute right-3 top-3 size-6'
        src={`/images/icons/${isFavorite ? 'ic_favorite_filled.svg' : 'ic_favorite_white.svg'}`}
        alt='favorite'
      />
    </div>
  );
};
