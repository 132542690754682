import { useDisclosure } from '@mantine/hooks';
import { useSearchParams } from '@remix-run/react';
import { type FunctionComponent } from 'react';
import { CarStockSortTypeEnum } from '~/gql/generated/graphql';
import styles from '~/styles/page/cars/carsIndex/sort-section.module.css';
import { SortItemModal } from './SortItemModal';
import { sortTypeText } from '~/constants/sortTypeText';
import { useCarsSearchParams } from '~/hooks';

const blockClass = 'sort-section';

export const SortSection: FunctionComponent = () => {
  const { changeSortType } = useCarsSearchParams();
  const [searchParams] = useSearchParams();
  const [opened, { open, close }] = useDisclosure(false);
  const sortType = searchParams.get('sortType');
  const sortTypeEnum = sortType as CarStockSortTypeEnum | null || CarStockSortTypeEnum.Newer;
  const sortText = sortTypeText[sortTypeEnum];

  const selectSortType = (sortType: CarStockSortTypeEnum) => {
    changeSortType(sortType);
    close();
  };

  return (
    <>
      <div className={styles[`${blockClass}`]} onClick={open}>
        <img src='/images/icons/ic_sort.svg' alt='sort' className={styles[`${blockClass}__icon`]} />
        <p className={styles[`${blockClass}__text`]}>{sortText}</p>
      </div>
      <SortItemModal opened={opened} defaultSortType={sortTypeEnum} close={close} onSubmit={selectSortType} />
    </>);

};