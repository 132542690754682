import { Link, useNavigate } from '@remix-run/react';
import { type FunctionComponent, useState } from 'react';
import { type FragmentType, graphql, useFragment } from '~/gql/generated';
import styles from '~/styles/page/cars/carsIndex/car-movies.module.css';
import { CarMoviePopup } from '~/components/shared';
import { CarMovieCard } from './CarMovieCard';

const blockClass = 'car-movies';

const CarMoviesStockFragment = graphql(`
  fragment CarIndex_CarMovies_carStock on CarStock {
    id
    video {
      id
      url
      thumbnailUrl
    }
    ...CarMoviePopup_carStock
  }
`);

type Props = {
  carStocks: readonly FragmentType<typeof CarMoviesStockFragment>[];
};

export const CarsMovies: FunctionComponent<Props> = (props) => {
  const carStocks = useFragment(CarMoviesStockFragment, props.carStocks);
  const displayCarStocks = carStocks.filter((carStock) => carStock.video?.url).slice(0, 10);
  const [showMoviePopup, setShowMoviePopup] = useState(false);
  const [currentMovieIndex, setCurrentMovieIndex] = useState(0);
  const [isStopAutoPlay, setIsStopAutoPlay] = useState(false);
  const navigate = useNavigate();

  const openPopup = (index: number) => {
    setIsStopAutoPlay(true);
    setShowMoviePopup(true);
    setCurrentMovieIndex(index);
  };
  const closePopup = () => {
    setIsStopAutoPlay(false);
    setShowMoviePopup(false);
  };

  const nextMovie = () => {
    if (currentMovieIndex === displayCarStocks.length - 1) {
      navigate('/cars/movies');
      return;
    }
    setCurrentMovieIndex((currentMovieIndex + 1) % displayCarStocks.length);
  };

  const backMovie = () => {
    setCurrentMovieIndex((currentMovieIndex - 1 + displayCarStocks.length) % displayCarStocks.length);
  };

  return (
    <div className={styles[blockClass]}>
      <div className={styles[`${blockClass}__header`]}>
        <p className={styles[`${blockClass}__header-title`]}>車両の動画ツアー</p>
        <Link to='/cars/movies' className={styles[`${blockClass}__header-link`]}>
          もっと見る
        </Link>
      </div>
      <div className={styles[`${blockClass}__movies`]} id='car-movies-section'>
        {displayCarStocks.map((carStock, index) =>
        <CarMovieCard
          key={carStock.id}
          videoUrl={carStock.video!.url!}
          thumbnailUrl={carStock.video!.thumbnailUrl}
          isFirst={index === 0}
          isStopAutoPlay={isStopAutoPlay}
          onClick={() => openPopup(index)} />

        )}
      </div>
      {showMoviePopup &&
      <CarMoviePopup
        carStock={displayCarStocks[currentMovieIndex]}
        hideBackButton={currentMovieIndex === 0}
        hideNextButton={false}
        nextMovie={nextMovie}
        backMovie={backMovie}
        closePopup={closePopup} />

      }
    </div>);

};