import { useEffect, useRef, useState, type FunctionComponent } from 'react';
import { Breadcrumb } from '~/components/shared';

import { ReviewsModal } from './ReviewsModal';

import { useFetcher, useSearchParams } from '@remix-run/react';
import { graphql, useFragment, type FragmentType } from '~/gql/generated';
import { UserReviewReviewTypeEnum, type ReviewsIndex_UserReviewFragment } from '~/gql/generated/graphql';
import { type loader } from '~/routes/reviews';
import styles from '~/styles/page/reviews/reviews-index.module.css';
import { ReviewCard } from './ReviewCard';

const blockClass = 'reviews-index';

const ReviewsIndexUserReviewFragment = graphql(`
  fragment ReviewsIndex_userReview on UserReview {
    id
    ...ReviewCard_userReview
    ...ReviewModal_userReview
  }
`);

type Props = {
  firstUserReviews: FragmentType<typeof ReviewsIndexUserReviewFragment>[];
  firstHasNextUserReviews: boolean;
  firstEndCursor?: string | null;
};

export const ReviewsIndex: FunctionComponent<Props> = (props) => {
  const firstUserReviews = useFragment(ReviewsIndexUserReviewFragment, props.firstUserReviews);
  const { firstEndCursor, firstHasNextUserReviews } = props;
  const [userReviews, setUserReviews] = useState(firstUserReviews);
  const [hasNextPage, setHasNextPage] = useState(firstHasNextUserReviews);
  const [endCursor, setEndCursor] = useState(firstEndCursor);
  const [showMoreUserReviewId, setShowMoreUserReviewId] = useState<string | null>(null);
  const showMoreUserReview = userReviews.find((userReview) => userReview.id === showMoreUserReviewId);
  const [searchParams, setSearchParams] = useSearchParams();
  const reviewType = searchParams.get('reviewType') || UserReviewReviewTypeEnum.Buying;
  const observerTarget = useRef(null);
  const fetcher = useFetcher<typeof loader>();

  useEffect(() => {
    setUserReviews(firstUserReviews);
    setHasNextPage(firstHasNextUserReviews);
    setEndCursor(firstEndCursor);
  }, [firstEndCursor, firstHasNextUserReviews, firstUserReviews]);

  useEffect(() => {
    const fetchedData = fetcher.data;
    if (fetchedData && fetchedData.userReviews.length > 0) {
      setUserReviews((prev) => [...prev, ...fetchedData.userReviews] as ReviewsIndex_UserReviewFragment[]);
      setEndCursor(fetchedData.endCursor);
      setHasNextPage(fetchedData.hasNextPage);
    }
  }, [fetcher.data]);

  useEffect(() => {
    const observerElement = observerTarget.current;

    const observer = new IntersectionObserver(([entry]) => {
      if (entry?.isIntersecting && fetcher.state === 'idle' && hasNextPage) {
        fetcher.submit({ endCursor: endCursor ?? '', reviewType });
      }
    });

    if (observerElement) {
      observer.observe(observerElement);
    }

    return () => {
      if (observerElement) {
        observer.unobserve(observerElement);
      }
    };
  }, [endCursor, fetcher, hasNextPage, reviewType]);

  return (
    <>
      <Breadcrumb text='ご利用いただいたお客様の声' />
      <h1 className='headline1'>お客様の声</h1>

      <div className={styles[`${blockClass}__score`]}>
        <p className={styles[`${blockClass}__score-lead`]}>またバディカダイレクトを利用したい</p>
        <div className={styles[`${blockClass}__score-score`]}>
          <strong>8.5</strong> / 10
        </div>
        <p className={styles[`${blockClass}__score-caution`]}>
          ※2024年5月時点
          <br />
          ※自社アンケートにいただいた回答の平均値
        </p>
      </div>

      <div className={styles[`${blockClass}__tabs`]}>
        <p
          className={[
          styles[`${blockClass}__tabs-tab`],
          reviewType === UserReviewReviewTypeEnum.Buying ? styles[`${blockClass}__tabs-tab-active`] : ''].
          join(' ')}
          onClick={() => setSearchParams({ reviewType: UserReviewReviewTypeEnum.Buying })}>

          購入
        </p>
        <p
          className={[
          styles[`${blockClass}__tabs-tab`],
          reviewType === UserReviewReviewTypeEnum.Selling ? styles[`${blockClass}__tabs-tab-active`] : ''].
          join(' ')}
          onClick={() => setSearchParams({ reviewType: UserReviewReviewTypeEnum.Selling })}>

          売却
        </p>
      </div>

      {userReviews.map((userReview) =>
      <ReviewCard key={userReview.id} userReview={userReview} showMore={setShowMoreUserReviewId} />
      )}

      <div ref={observerTarget}>{hasNextPage && <div className={styles[`${blockClass}__loader`]} />}</div>

      <ReviewsModal
        opened={!!showMoreUserReview}
        close={() => setShowMoreUserReviewId(null)}
        userReview={showMoreUserReview} />

    </>);

};