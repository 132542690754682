import { useLocation, useParams } from '@remix-run/react';
import { useEffect, useRef, useState, type FunctionComponent } from 'react';
import { useClient } from 'urql';
import { Breadcrumb, CarCardGroup } from '~/components/shared';
import { NewNotificationBanner } from '~/components/shared/banners';
import { graphql, useFragment, type FragmentType } from '~/gql/generated';
import { CarSalesStatusEnum, TagGroupEnum, type SpecialShow_CarStockFragment } from '~/gql/generated/graphql';
import { useAuth } from '~/hooks';
import { useDeliveryAddressContext, useInfiniteScrollContext } from '~/providers';
import { pathToTagGroup, pathToTitle, type TagGroupSlug } from '~/routes/car-specials.$slug';
import styles from '~/styles/page/specialShow/special-show.module.css';
import { FamilyView } from './FamilyView';
import { InitialSelectionView } from './InitialSelectionView';
import { KawaiiView } from './KawaiiView';
import { OutdoorView } from './OutdoorView';
import { ResaleView } from './ResaleView';

const FETCH_CAR_STOCKS_COUNT = 20;

const blockClass = 'special-show';

const SpecialShowCarStocksFragment = graphql(`
  fragment SpecialShow_carStock on CarStock {
    id
    ...CarCardGroup_carStock
  }
`);

const specialShowComponentQueryDocument = graphql(`
  query SpecialShowComponent(
    $first: Int
    $after: String
    $salesStatus: CarSalesStatusEnum
    $tagGroup: TagGroupEnum
    $prefectureCode: Int
  ) {
    carStocks(first: $first, after: $after, salesStatus: $salesStatus, tagGroup: $tagGroup) {
      edges {
        node {
          id
          landTransportCost(prefectureCode: $prefectureCode)
          ...SpecialShow_carStock
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`);

const useCarStocks = ({
  firstCarStocks,
  firstEndCursor,
  firstHasNextCarStocks




}: {firstCarStocks: readonly SpecialShow_CarStockFragment[];firstEndCursor?: string | null;firstHasNextCarStocks: boolean;}): {
  carStocks: readonly SpecialShow_CarStockFragment[];
  hasNextCarStocks: boolean;
  initializing: boolean;
  observerTarget: React.MutableRefObject<null>;
} => {
  const [carStocks, setCarStocks] = useState(firstCarStocks);
  const [endCursor, setEndCursor] = useState(firstEndCursor);
  const [hasNextCarStocks, setHasNextCarStocks] = useState(firstHasNextCarStocks);
  const [initializing, setInitializing] = useState(true);
  const [isScrolledBottom, setIsScrolledBottom] = useState(false);
  const { prefectureCode } = useDeliveryAddressContext();
  const observerTarget = useRef(null);
  const location = useLocation();
  const client = useClient();
  const { infiniteScroll, addScrolledCursor } = useInfiniteScrollContext();
  const params = useParams();
  const specialSlug = (params.slug || '') as TagGroupSlug;
  const tagGroup = pathToTagGroup[specialSlug];

  useEffect(() => {
    setCarStocks(firstCarStocks);
    setHasNextCarStocks(firstHasNextCarStocks);
    setEndCursor(firstEndCursor);
    initializeCarStocks();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstCarStocks, firstEndCursor, firstHasNextCarStocks]);

  const initializeCarStocks = async () => {
    const targetCursors = infiniteScroll[location.key]?.cursors || [];

    if (targetCursors.length === 0) {
      setInitializing(false);
      return;
    }

    targetCursors.forEach(async (cursor, index) => {
      const variables = {
        salesStatus: CarSalesStatusEnum.OnSale,
        first: FETCH_CAR_STOCKS_COUNT,
        after: cursor,
        tagGroup: tagGroup,
        prefectureCode
      };
      const result = await client.query(specialShowComponentQueryDocument, variables);
      if (result.error) return;

      const resultCarStocks = (result.data?.carStocks?.edges?.map((edge) => edge?.node).filter((node) => !!node) ||
      []) as SpecialShow_CarStockFragment[];
      setCarStocks((prev) => [...prev, ...resultCarStocks]);

      if (index === targetCursors.length - 1) {
        const resultHasNextPage = result.data?.carStocks?.pageInfo?.hasNextPage || false;
        const resultEndCursor = result.data?.carStocks?.pageInfo?.endCursor;
        setHasNextCarStocks(resultHasNextPage);
        setEndCursor(resultEndCursor);
      }
    });
    setInitializing(false);
    setTimeout(() => {
      const elementId = infiniteScroll[location.key]?.elementId;
      if (!elementId) return;
      const targetElement = document.getElementById(elementId);
      if (!targetElement) return;
      targetElement.scrollIntoView({ block: 'center' });
    }, 100);
  };

  const fetchNextCarStocks = async () => {
    if (!hasNextCarStocks) {
      return;
    }
    if (!isScrolledBottom) return;

    const currentEndCursor = endCursor;
    const variables = {
      salesStatus: CarSalesStatusEnum.OnSale,
      first: FETCH_CAR_STOCKS_COUNT,
      after: endCursor,
      tagGroup: tagGroup,
      prefectureCode
    };
    const result = await client.query(specialShowComponentQueryDocument, variables);
    if (result.error) {
      setIsScrolledBottom(false);
      return;
    }
    const resultCarStocks = (result.data?.carStocks?.edges?.map((edge) => edge?.node).filter((node) => !!node) ||
    []) as SpecialShow_CarStockFragment[];
    const resultHasNextPage = result.data?.carStocks?.pageInfo?.hasNextPage || false;
    const resultEndCursor = result.data?.carStocks?.pageInfo?.endCursor;
    setCarStocks((prev) => [...prev, ...resultCarStocks]);
    setHasNextCarStocks(resultHasNextPage);
    setEndCursor(resultEndCursor);

    if (currentEndCursor) {
      addScrolledCursor(location.key, currentEndCursor);
    }

    setIsScrolledBottom(false);
  };

  useEffect(() => {
    if (!isScrolledBottom) return;
    fetchNextCarStocks();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isScrolledBottom]);

  useEffect(() => {
    setIsScrolledBottom(false);
  }, [location]);

  useEffect(() => {
    if (initializing) return;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          if (hasNextCarStocks) {
            setIsScrolledBottom(true);
          }
        }
      },
      { threshold: 1.0 }
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initializing]);

  return { carStocks, hasNextCarStocks, initializing, observerTarget };
};

type Props = {
  carStocks: FragmentType<typeof SpecialShowCarStocksFragment>[];
  firstHasNextCarStocks: boolean;
  firstEndCursor?: string | null;
};

export const SpecialShow: FunctionComponent<Props> = (props) => {
  const firstCarStocks = useFragment(SpecialShowCarStocksFragment, props.carStocks);
  const { firstHasNextCarStocks, firstEndCursor } = props;
  const { carStocks, hasNextCarStocks, observerTarget } = useCarStocks({
    firstCarStocks,
    firstEndCursor,
    firstHasNextCarStocks
  });
  const groupedCarStocks = [
  carStocks.slice(0, 3),
  ...carStocks.slice(3).reduce<(typeof carStocks)[]>((acc, carStock, index) => {
    if (index % 5 === 0) {
      return [...acc, [carStock]];
    }
    return [...acc.slice(0, -1), [...acc[acc.length - 1], carStock]];
  }, [])];

  const { user } = useAuth();
  const favoritedCarStocks = user?.favoritedCarStocks || [];
  const favoritedCarStockIds = favoritedCarStocks.map((carStock) => carStock.id);
  const params = useParams();
  const specialSlug = (params.slug || '') as TagGroupSlug;
  const tagGroup = pathToTagGroup[specialSlug];

  return (
    <>
      <Breadcrumb text={pathToTitle[specialSlug]} />
      <div className={styles[blockClass]}>
        {tagGroup === TagGroupEnum.InitialSelection ?
        <InitialSelectionView /> :
        tagGroup === TagGroupEnum.Resale ?
        <ResaleView /> :
        tagGroup === TagGroupEnum.Outdoor ?
        <OutdoorView /> :
        tagGroup === TagGroupEnum.Family ?
        <FamilyView /> :
        tagGroup === TagGroupEnum.Kawaii ?
        <KawaiiView /> :

        <></>
        }
        {groupedCarStocks.map((carStocks, index) =>
        <CarCardGroup key={index} carStocks={carStocks} favoritedCarStockIds={favoritedCarStockIds} />
        )}
        {!hasNextCarStocks && <NewNotificationBanner />}
        <div ref={observerTarget}>
          {hasNextCarStocks &&
          <div
            className={[
            styles[`${blockClass}__loader`],
            carStocks.length === 0 ? styles[`${blockClass}__loader--hide`] : ''].
            join(' ')} />

          }
        </div>
      </div>
    </>);

};