import { type FunctionComponent } from 'react';
import { useQuery } from 'urql';
import { StandardError } from '~/components/errors';
import { Loading } from '~/components/parts';
import { BottomNav, SmallCarCard } from '~/components/shared';
import { graphql } from '~/gql/generated';
import { useAnalytics, useAuth } from '~/hooks';
import { useDeliveryAddressContext } from '~/providers';
import styles from '~/styles/page/favoritesIndex/favorites-index.module.css';
import { FavoriteEmpty } from './FavoriteEmpty';
import { SubmitButton } from '~/components/shared/SubmitButton/SubmitButton';
import { useNavigate } from '@remix-run/react';

const blockClass = 'favorites-index';

const FavoritesIndexComponentQueryDocument = graphql(`
  query FavoritesIndexComponent($prefectureCode: Int) {
    viewer {
      id
      favoritedCarStocks {
        id
        ulid
        landTransportCost(prefectureCode: $prefectureCode)
        ...SmallCarCard_carStock
      }
    }
  }
`);

export const FavoritesIndex: FunctionComponent = () => {
  const { isAuthenticated, fetching: userFetching } = useAuth();
  const { prefectureCode } = useDeliveryAddressContext();
  const [{ data, fetching: favoriteFetching, error }] = useQuery({
    query: FavoritesIndexComponentQueryDocument,
    variables: { prefectureCode }
  });
  const favoritedCarStocks = data?.viewer?.favoritedCarStocks || [];
  const navigate = useNavigate();
  const { sendTrackEvent } = useAnalytics();

  if (userFetching || favoriteFetching) {
    return <Loading />;
  }

  if (isAuthenticated && error) {
    return <StandardError />;
  }

  if (!favoritedCarStocks?.length) {
    return (
      <>
        <FavoriteEmpty isNotLogin={!isAuthenticated} />
        <BottomNav />
      </>);

  }

  return (
    <div className={styles[blockClass]}>
      <p className={styles[`${blockClass}__title`]}>お気に入り（{favoritedCarStocks.length}件）</p>
      <div className={styles[`${blockClass}__cars`]}>
        {favoritedCarStocks.map((favoritedCarStock) =>
        <div className={styles[`${blockClass}__cars-item`]} key={favoritedCarStock!.id}>
            <SmallCarCard carStock={favoritedCarStock!} isFavorited={true} isDisplayInquired={true} />
          </div>
        )}
      </div>
      <SubmitButton
        text='LINEで相談する'
        icon={<img src={'/images/icons/ic_line.svg'} alt='line-logo' />}
        variant='contained'
        handleClick={() => {
          sendTrackEvent('click_inquire_favorites');
          navigate('/add_line_friend');
        }} />

      <BottomNav />
    </div>);

};