import { useEffect, useRef, type FunctionComponent } from 'react';
import { TrackableLink } from '~/components/parts';
import { Breadcrumb } from '~/components/shared';
import styles from '~/styles/page/ainakano/ainakano-index.module.css';

const blockClass = 'ainakano';

const LineButton: FunctionComponent = () => {
  return (
    <p className={styles[`${blockClass}__line`]}>
      <TrackableLink
        to='https://lin.ee/m4Yig0N'
        className={styles[`${blockClass}__line-button`]}
        analyticsData={{ eventName: 'click_ai_nakano_experience_button' }}>

        <img src={'/images/ainakano/line_icon.svg'} alt='line' />
        <span>
          中野
          <ruby>
            愛<rt>●</rt>
          </ruby>
          作に相談する
        </span>
      </TrackableLink>
    </p>);

};

export const AiNakanoIndex: FunctionComponent = () => {
  const twitterRef1 = useRef(null);
  const twitterRef2 = useRef(null);
  const twitterRef3 = useRef(null);
  const twitterRef4 = useRef(null);

  useEffect(() => {
    // scriptを読み込み
    const script = document.createElement('script');
    script.src = 'https://platform.twitter.com/widgets.js';
    document.body.appendChild(script);
    // アンマウント時に一応scriptタグを消しておく
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    // ツイートの埋め込みを実行
    (window as any).twttr?.widgets.load(twitterRef1.current);
    (window as any).twttr?.widgets.load(twitterRef2.current);
    (window as any).twttr?.widgets.load(twitterRef3.current);
    (window as any).twttr?.widgets.load(twitterRef4.current);
  }, []);

  return (
    <>
      <Breadcrumb text='チャットAI、中野愛作' />
      <div className={styles[`${blockClass}__mv`]}>
        <img
          src={'/images/ainakano/mv.png'}
          alt='中野優作の弟!?!?愛車選びの相談ができる中野愛作 前向きに、誠実にクルマの質問に答えます！' />

      </div>

      <LineButton />

      <div className={styles[`${blockClass}__recommend`]}>
        <h2 className={styles[`${blockClass}__recommend-heading`]}>こんな方におすすめ</h2>
        <div className={styles[`${blockClass}__recommend-detail`]}>
          <ul className={styles[`${blockClass}__recommend-point`]}>
            <li className={styles[`${blockClass}__recommend-pointItem`]}>クルマ選びを誰に相談して良いかわからない</li>
            <li className={styles[`${blockClass}__recommend-pointItem`]}>相談する内容も決まってない</li>
            <li className={styles[`${blockClass}__recommend-pointItem`]}>相談するのも時間を奪いそうで申し訳ない</li>
          </ul>
          <p className={styles[`${blockClass}__recommend-description`]}>
            そんな悩みを気軽に楽しく解決できるサービスが「中野愛作」です。
            <br />
            中古車、クルマに関する発信を行う弊社代表 中野優作（YouTube登録者30万人）。
            <br />
            そんな中野の情報やクルマの情報をデータ化し、まるで中野に相談してるようなAIによるbotを作成しました。
          </p>
        </div>
      </div>
      <div className={styles[`${blockClass}__evaluation`]}>
        <h2 className={styles[`${blockClass}__evaluation-heading`]}>
          テスト版はこんな
          <br />
          評価をいただきました
        </h2>
        <div className={styles[`${blockClass}__evaluation-detail`]}>
          <img src={'/images/ainakano/survey.png'} alt='テスト版利用後アンケート' />
        </div>
      </div>

      <div className={styles[`${blockClass}__feedback`]}>
        <h2 className={styles[`${blockClass}__feedback-heading`]}>いただいたフィードバック</h2>
        <h3 className={styles[`${blockClass}__feedback-lead`]}>フィードバックをもとにアップデートしました！</h3>
        <div className={styles[`${blockClass}__feedback-detail`]}>
          <ul className={styles[`${blockClass}__feedback-comment`]}>
            <li className={styles[`${blockClass}__feedback-commentItem`]}>
              <p className={styles[`${blockClass}__feedback-commentItem-text`]}>
                もっと早い段階で、候補の車のURLを上げてくれても良いかもしれないです。
                <br />
                何車種か候補出した時に、実際にそのURLがあると、検討の候補にもなってくるかと思います。
                <br />
                <strong>K様</strong>
              </p>
            </li>
            <li className={styles[`${blockClass}__feedback-commentItem`]}>
              <p className={styles[`${blockClass}__feedback-commentItem-text`]}>
                バディカの在庫の確認は、Webサイトでとなりましたが、会話の中で確認もできるといいなと感じました。
                <br />
                <strong>S様</strong>
              </p>
            </li>
            <li className={styles[`${blockClass}__feedback-commentItem`]}>
              <p className={styles[`${blockClass}__feedback-commentItem-text`]}>
                車の候補以外にも、選び方とか実際に数値として比較した場合とかも知れたらいいなと思いました。
                <br />
                <strong>T様</strong>
              </p>
            </li>
          </ul>
        </div>
      </div>

      <div className={styles[`${blockClass}__update`]}>
        <h2 className={styles[`${blockClass}__update-heading`]}>今回のアップデート内容</h2>
        <div className={styles[`${blockClass}__update-detail`]}>
          <ul className={styles[`${blockClass}__update-list`]}>
            <li className={styles[`${blockClass}__update-listItem`]}>
              <div className={styles[`${blockClass}__update-listItem-icon`]}>
                <img src={'/images/ainakano/feature_icon_1.svg'} alt='icon' />
              </div>
              <p className={styles[`${blockClass}__update-listItem-text`]}>
                実際に購入できる在庫データを元に提案を行います。
              </p>
              <div className={styles[`${blockClass}__update-listItem-image`]}>
                <img src={'/images/ainakano/update01.png'} alt='' />
              </div>
            </li>
            <li className={styles[`${blockClass}__update-listItem`]}>
              <div className={styles[`${blockClass}__update-listItem-icon`]}>
                <img src={'/images/ainakano/feature_icon_2.svg'} alt='icon' />
              </div>
              <p className={styles[`${blockClass}__update-listItem-text`]}>
                YouTubeで過去に話した内容をさらに学習しています。
              </p>
              <div className={styles[`${blockClass}__update-listItem-image`]}>
                <img src={'/images/ainakano/update02.png'} alt='' />
              </div>
            </li>
            <li className={styles[`${blockClass}__update-listItem`]}>
              <div className={styles[`${blockClass}__update-listItem-icon`]}>
                <img src={'/images/ainakano/feature_icon_3.svg'} alt='icon' />
              </div>
              <p className={styles[`${blockClass}__update-listItem-text`]}>
                GPT-4oに対応！24時間即レスを実現しました。
              </p>
            </li>
          </ul>
        </div>
      </div>

      <div className={styles[`${blockClass}__line-wrap`]}>
        <LineButton />
      </div>

      <div className={styles[`${blockClass}__sns`]}>
        <h2 className={styles[`${blockClass}__sns-heading`]}>テスト版に対するSNS上での声</h2>

        <div className={styles[`${blockClass}__sns-tweet`]}>
          <div
            dangerouslySetInnerHTML={{
              __html: `
            <blockquote class='twitter-tweet' data-conversation='none'>
              <p lang='ja' dir='ltr'>
                中野さんほんとこんなくだらない質問にもポジティブに；；なんていい人なんだ；；{' '}
                <a href='https://t.co/37fLNlc3iC'>pic.twitter.com/37fLNlc3iC</a>
              </p>
              &mdash; 真帆山むったん (@mustang_maho){' '}
              <a href='https://twitter.com/mustang_maho/status/1771998614237380700?ref_src=twsrc%5Etfw'>March 24, 2024</a>
            </blockquote>
            `
            }}
            ref={twitterRef1} />


          <div
            dangerouslySetInnerHTML={{
              __html: `
            <blockquote class='twitter-tweet' data-conversation='none'>
              <p lang='ja' dir='ltr'>
                使ってみました！少し変化球的な質問をしましたが、回答なども自然で驚きました！{' '}
                <a href='https://t.co/t1QxV9RSC7'>pic.twitter.com/t1QxV9RSC7</a>
              </p>
              &mdash; y.s (@szktomm){' '}
              <a href='https://twitter.com/szktomm/status/1771858691563659589?ref_src=twsrc%5Etfw'>March 24, 2024</a>
            </blockquote>
            `
            }}
            ref={twitterRef2} />


          <div
            dangerouslySetInnerHTML={{
              __html: `
            <blockquote class='twitter-tweet' data-conversation='none'>
              <p lang='ja' dir='ltr'>
                絶対、めちゃくちゃ褒めてくれる、、、！！🥺{' '}
                <a href='https://t.co/dEVKLvMIp9'>pic.twitter.com/dEVKLvMIp9</a>
              </p>
              &mdash; タマ (@ehZW4hCFLbhy6zS){' '}
              <a href='https://twitter.com/ehZW4hCFLbhy6zS/status/1771823541568065769?ref_src=twsrc%5Etfw'>
                March 24, 2024
              </a>
            </blockquote>
            `
            }}
            ref={twitterRef3} />


          <div
            dangerouslySetInnerHTML={{
              __html: `
            <blockquote class='twitter-tweet' data-conversation='none'>
              <p lang='ja' dir='ltr'>
                面白いです！！良い意味でAI感がない！！ <a href='https://t.co/A8q7DJAUHI'>pic.twitter.com/A8q7DJAUHI</a>
              </p>
              &mdash; ^_^ (@shousa_shosa){' '}
              <a href='https://twitter.com/shousa_shosa/status/1771740745709273272?ref_src=twsrc%5Etfw'>March 24, 2024</a>
            </blockquote>
            `
            }}
            ref={twitterRef4} />

        </div>
        <div className={styles[`${blockClass}__sns-bottom`]}>
          <p className={styles[`${blockClass}__sns-bottom-heading`]}>
            「人に相談するまでもない、相談するのも申し訳ない、相談したい内容も決まってない」
          </p>
          <p className={styles[`${blockClass}__sns-bottom-text`]}>
            ふんわりしたご相談に対応できるように制作しました。
            <br />
            ぜひ、あなたの楽しいクルマライフをこちらの中野愛作とともに見つけてみてください！
          </p>
        </div>
      </div>

      <div className={styles[`${blockClass}__line-wrap`]}>
        <LineButton />
      </div>
    </>);

};