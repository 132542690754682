import { type FunctionComponent } from 'react';
import styles from '~/styles/page/cloudsignComplete/cloudsign-complete.module.css';

export const CloudsignComplete: FunctionComponent = () => {
  return (
    <div className={styles.main}>
      <h1 className={styles.title}>契約締結完了</h1>
      <div className={styles.img}>
        <img src={'/images/icons/ic_check_circle_green.svg'} alt='check_green' />
      </div>
      <p className={styles.description}>書類の合意締結が完了しました。</p>
    </div>);

};