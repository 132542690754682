import dayjs from 'dayjs';
import { type FunctionComponent } from 'react';
import { graphql, useFragment, type FragmentType } from '~/gql/generated';
import { UserReviewReviewTypeEnum } from '~/gql/generated/graphql';
import styles from '~/styles/page/reviews/review-card.module.css';

const blockClass = 'review-card';

const ReviewCardUserReviewFragment = graphql(`
  fragment ReviewCard_userReview on UserReview {
    id
    reviewType
    satisfactionRate
    closedMonth
    makeName
    carModelName
    title
    conveniencePoint
    staffComment
    staff {
      id
      fullName
    }
  }
`);

type Props = {
  userReview: FragmentType<typeof ReviewCardUserReviewFragment>;
  showMore: (userReviewId: string) => void;
};

export const ReviewCard: FunctionComponent<Props> = (props) => {
  const userReview = useFragment(ReviewCardUserReviewFragment, props.userReview);

  const showMore = () => {
    props.showMore(userReview.id);
  };

  return (
    <div className={styles[`${blockClass}`]}>
      {userReview.satisfactionRate &&
      <div className={styles[`${blockClass}__star`]}>
          {Array.from({ length: userReview.satisfactionRate }).map((_, index) =>
        <img src={'/images/icons/ic_star2.svg'} alt='star' key={index} />
        )}
        </div>
      }

      <h2 className={styles[`${blockClass}__title`]}>{userReview.title}</h2>

      <div className={styles[`${blockClass}__head`]}>
        {userReview.closedMonth &&
        <>
            <p className={styles[`${blockClass}__date`]}>{dayjs(userReview.closedMonth).format('YYYY年MM月')}</p>
            <p>&nbsp;|&nbsp;</p>
          </>
        }
        <p>
          {[
          userReview.makeName,
          userReview.carModelName,
          userReview.reviewType === UserReviewReviewTypeEnum.Buying ? '購入' : '売却'].

          filter(Boolean).
          join(' ')}
        </p>
      </div>

      {userReview.conveniencePoint ?
      <p className={styles[`${blockClass}__text`]}>
          <span className={styles[`${blockClass}__text-main`]}>
            {userReview.conveniencePoint.split(/\n/).map((text, index) =>
          <p key={index}>{text}</p>
          )}
          </span>
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <span className={styles[`${blockClass}__text-more`]} onClick={showMore}>
              もっと見る
            </span>
          </div>
        </p> :

      <div className={styles[`${blockClass}__text`]}>
          <span className={styles[`${blockClass}__text-more`]} onClick={showMore}>
            もっと見る
          </span>
        </div>
      }

      {userReview.staffComment &&
      <div className={styles[`${blockClass}__comment`]} onClick={showMore}>
          <p className={styles[`${blockClass}__comment-title`]}>担当者からのコメント</p>
          <p className={styles[`${blockClass}__comment-text`]}>
            {userReview.staffComment.split(/\n/).map((text, index) =>
          <p key={index}>{text}</p>
          )}
          </p>
        </div>
      }
    </div>);

};