import { Link } from '@remix-run/react';
import { useState, type FunctionComponent, type ReactNode } from 'react';
import { TrackableLink } from '~/components/parts';
import { Breadcrumb, MediaSection, ReviewSection } from '~/components/shared';
import styles from '~/styles/page/request/request-index.module.css';

const blockClass = 'request';

type FapProps = {
  title: string;
  text: string | ReactNode;
};

const Faq: FunctionComponent<FapProps> = ({ title, text }) => {
  const [open, setOpen] = useState(false);

  return (
    <li className={styles[`${blockClass}__faq-item`]} onClick={() => setOpen(!open)}>
      <div className={styles[`${blockClass}__faq-head`]}>
        <h3 className={styles[`${blockClass}__faq-title`]}>{title}</h3>
      </div>
      {open &&
      <div className={styles[`${blockClass}__faq-body`]}>
          <p className={styles[`${blockClass}__faq-text`]}>{text}</p>
        </div>
      }
    </li>);

};

const LineBanner: FunctionComponent = () => {
  return (
    <p className={styles[`${blockClass}__line-banner`]}>
      <TrackableLink
        to='/add_line_friend?inflow_source_message=notifynew'
        analyticsData={{ eventName: 'click_registration_line_banner' }}>

        <img src={'/images/banners/line_friend.png'} alt='' />
      </TrackableLink>
    </p>);

};

export const RequestIndex: FunctionComponent = () => {
  const linkEventName = 'open_request_form';
  const formLink = '/add_line_friend?inflow_source_message=notifynew';
  const gtmClass = 'gtm-cta-request';

  return (
    <>
      <Breadcrumb text='クルマのリクエスト' />
      <div className={styles[`${blockClass}__message`]}>
        <h1 className={styles[`${blockClass}__message-subTitle`]}>クルマのリクエスト</h1>
        <p className={styles[`${blockClass}__message-title`]}>
          欲しい車が
          <br />
          見つからない？
        </p>
        <p className={styles[`${blockClass}__message-text`]}>
          なら、私たちが
          <br />
          あなたの愛車を探します！
        </p>
        <div className={styles[`${blockClass}__message-img`]}>
          <img src={'/images/request/img_message.png'} alt='' />
        </div>
      </div>

      <h2 className={styles[`${blockClass}__title`]}>こんな方におすすめ</h2>

      <ul className={styles[`${blockClass}__point`]}>
        <li className={styles[`${blockClass}__point-item`]}>
          <p className={styles[`${blockClass}__point-label`]}>Point 1</p>
          <h2 className={styles[`${blockClass}__point-title`]}>探している車がサイトにない</h2>
          <p className={styles[`${blockClass}__point-text`]}>
            サイトに掲載していない車両でも、全国のネットワークを駆使してお探しすることが可能です。もちろん追加費用等もございません。リクエストいただければご提案し、必要なら調達しお届けすることも可能です。
          </p>
          <div className={styles[`${blockClass}__point-img--s`]}>
            <img src={'/images/request/img_point_1.jpg'} alt='' />
          </div>
        </li>

        <li className={styles[`${blockClass}__point-item`]}>
          <p className={styles[`${blockClass}__point-label`]}>Point 2</p>
          <h2 className={styles[`${blockClass}__point-title`]}>自分に合う車がわからない</h2>
          <p className={styles[`${blockClass}__point-text`]}>
            とりあえず200万円以下で、とか、家族4人で乗れて荷物がよく入る車、でもOKです。遠慮なくご相談してください。初めての車選びもぜひお任せください。
          </p>
          <div className={styles[`${blockClass}__point-img--s`]}>
            <img src={'/images/request/img_point_2.jpg'} alt='' />
          </div>
        </li>
      </ul>

      <p className={styles[`${blockClass}__button`]}>
        <TrackableLink
          to={formLink}
          analyticsData={{ eventName: linkEventName }}
          className={[styles[`${blockClass}__step-button`], gtmClass].join(' ')}>

          クルマのリクエストはこちら
        </TrackableLink>
      </p>

      <h2 className={styles[`${blockClass}__title`]}>バディカダイレクトの特徴</h2>
      <p className={styles[`${blockClass}__titleCaption`]}>安心してご購入いただける特徴</p>

      <ul className={styles[`${blockClass}__point`]}>
        <li className={styles[`${blockClass}__point-item`]}>
          <p className={styles[`${blockClass}__point-label`]}>実績</p>
          <h2 className={styles[`${blockClass}__point-title`]}>中古車業者7万社中1位！</h2>
          <p className={styles[`${blockClass}__point-text`]}>
            母体となるBUDDICAは、国内最大級の中古車業者同士のクルマの売買サイトで70,000社中1位に輝いています。
          </p>
          <div className={styles[`${blockClass}__point-img`]}>
            <img src={'/images/request/img_feature_1.png'} alt='' />
          </div>
        </li>

        <li className={styles[`${blockClass}__point-item`]}>
          <p className={styles[`${blockClass}__point-label`]}>品質</p>
          <h2 className={styles[`${blockClass}__point-title`]}>完全な透明性</h2>
          <p className={styles[`${blockClass}__point-text`]}>
            全ての車両に、有資格者による車両鑑定書をつけています。後から言った言わないのトラブルも防止できますし、説明しないとわからない修復歴も記載し透明性を担保しています。
          </p>
          <div className={styles[`${blockClass}__point-img`]}>
            <img src={'/images/request/img_feature_2.png'} alt='' />
          </div>
        </li>

        <li className={styles[`${blockClass}__point-item`]}>
          <p className={styles[`${blockClass}__point-label`]}>簡単</p>
          <h2 className={styles[`${blockClass}__point-title`]}>オンライン完結</h2>
          <p className={styles[`${blockClass}__point-text`]}>
            車選びのご相談から納車まですべてがオンラインでらくらく完結します。
          </p>
          <div className={styles[`${blockClass}__point-img`]}>
            <img src={'/images/request/img_feature_3.png'} alt='' />
          </div>
        </li>
      </ul>

      <h2 className={styles[`${blockClass}__title`]} id='support'>
        バディカダイレクトのサポート内容
      </h2>
      <p className={styles[`${blockClass}__titleCaption`]}>安心してご購入いただける仕組み</p>

      <h2 className={styles[`${blockClass}__title`]}>ご相談の流れ</h2>
      <p className={styles[`${blockClass}__titleCaption`]}>4STEPで簡単に相談できます</p>

      <ol id='request-consultation-flow' className={styles[`${blockClass}__step`]}>
        <li className={styles[`${blockClass}__step-item`]}>
          <p className={styles[`${blockClass}__step-num`]}>1</p>
          <div className={styles[`${blockClass}__step-outer`]}>
            <div className={styles[`${blockClass}__step-img`]}>
              <img src={'/images/request/icon_step_1.svg'} alt='' />
            </div>
            <div className={styles[`${blockClass}__step-inner`]}>
              <h3 className={styles[`${blockClass}__step-title`]}>LINEに登録する</h3>
              <p className={styles[`${blockClass}__step-text`]}>
                バディカダイレクトの公式LINEと連携します。
                <br />
                30秒で登録完了！
              </p>
            </div>
          </div>
          <p>
            <TrackableLink
              to={formLink}
              analyticsData={{ eventName: linkEventName }}
              className={[styles[`${blockClass}__step-button`], gtmClass].join(' ')}>

              <img src={'/images/icons/ic_line.svg'} className={styles[`${blockClass}__step-button-icon`]} alt='line' />
              <span className={styles[`${blockClass}__button-text`]}>まずはLINEに登録</span>
            </TrackableLink>
          </p>
        </li>

        <li className={styles[`${blockClass}__step-item`]}>
          <p className={styles[`${blockClass}__step-num`]}>2</p>
          <div className={styles[`${blockClass}__step-outer`]}>
            <div className={styles[`${blockClass}__step-img`]}>
              <img src={'/images/request/icon_step_2.svg'} alt='' />
            </div>
            <div className={styles[`${blockClass}__step-inner`]}>
              <h3 className={styles[`${blockClass}__step-title`]}>希望条件を入力</h3>
              <p className={styles[`${blockClass}__step-text`]}>ご希望の条件を入力してフォームを送信してください。</p>
            </div>
          </div>
          <p>
            <TrackableLink
              to={formLink}
              analyticsData={{ eventName: linkEventName }}
              className={[styles[`${blockClass}__step-button`], gtmClass].join(' ')}>

              <span className={styles[`${blockClass}__button-text`]}>クルマのリクエスト</span>
            </TrackableLink>
          </p>
        </li>

        <li className={styles[`${blockClass}__step-item`]}>
          <p className={styles[`${blockClass}__step-num`]}>3</p>
          <div className={styles[`${blockClass}__step-outer`]}>
            <div className={styles[`${blockClass}__step-img`]}>
              <img src={'/images/request/icon_step_3.svg'} alt='' />
            </div>
            <div className={styles[`${blockClass}__step-inner`]}>
              <h3 className={styles[`${blockClass}__step-title`]}>相談方法を決める</h3>
              <p className={styles[`${blockClass}__step-text`]}>あなたの相談方法に合わせて対応します。</p>
              <ul className={styles[`${blockClass}__step-point`]}>
                <li>チャット</li>
                <li>ビデオ通話</li>
                <li>電話</li>
                <li>来店</li>
              </ul>
            </div>
          </div>
        </li>

        <li className={styles[`${blockClass}__step-item`]}>
          <p className={styles[`${blockClass}__step-num`]}>4</p>
          <div className={styles[`${blockClass}__step-outer`]}>
            <div className={styles[`${blockClass}__step-img`]}>
              <img src={'/images/request/icon_step_4.svg'} alt='' />
            </div>
            <div className={styles[`${blockClass}__step-inner`]}>
              <h3 className={styles[`${blockClass}__step-title`]}>スタッフに相談する</h3>
              <p className={styles[`${blockClass}__step-text`]}>
                ご希望の連絡方法で担当者からご連絡します。
                <br />
                いただいたお問い合わせは順次行いますので、1日程度お待ちください。
              </p>
            </div>
          </div>
        </li>
      </ol>

      <p className={styles[`${blockClass}__button`]}>
        <TrackableLink
          to={formLink}
          analyticsData={{ eventName: linkEventName }}
          className={[styles[`${blockClass}__step-button`], gtmClass].join(' ')}>

          クルマのリクエストはこちら
        </TrackableLink>
      </p>

      <h2 className={styles[`${blockClass}__title`]}>バディカダイレクトのスタッフ</h2>
      <p className={styles[`${blockClass}__titleCaption`]}>あなたのカーライフに寄り添います</p>
      <p className={styles[`${blockClass}__staff-caption`]}>
        あなたのカーライフを特別なものに変えるため、心を込めてサポートいたします。お気軽にご相談ください！
      </p>
      <ul className={styles[`${blockClass}__staff`]}>
        <li className={styles[`${blockClass}__staff-item`]}>
          <Link to='/member/yusa'>
            <div className={styles[`${blockClass}__staff-img`]}>
              <img src={'/images/members/yusa/list.jpg'} alt='' width={'100%'} />
            </div>
            <div className={styles[`${blockClass}__staff-inner`]}>
              <p className={styles[`${blockClass}__staff-name`]}>由佐 朋也</p>
              <p className={styles[`${blockClass}__staff-text`]}>
                BUDDICAの創業メンバー。お客様の未来を想像し全国のお客様に「愛車」を届けます。
              </p>
              <p className={styles[`${blockClass}__staff-link`]}>詳細を見る</p>
            </div>
          </Link>
        </li>

        <li className={styles[`${blockClass}__staff-item`]}>
          <Link to='/member/kurita'>
            <div className={styles[`${blockClass}__staff-img`]}>
              <img src={'/images/members/kurita/list.jpg'} alt='' width={'100%'} />
            </div>
            <div className={styles[`${blockClass}__staff-inner`]}>
              <p className={styles[`${blockClass}__staff-name`]}>栗田 政浩</p>
              <p className={styles[`${blockClass}__staff-text`]}>
                お客様が最高の愛車に出会えるように全力でサポートし、楽しいクルマ選びを一緒にしていきましょう。
              </p>
              <p className={styles[`${blockClass}__staff-link`]}>詳しく見る</p>
            </div>
          </Link>
        </li>

        <li className={styles[`${blockClass}__staff-item`]}>
          <Link to='/member/hasegawa'>
            <div className={styles[`${blockClass}__staff-img`]}>
              <img src={'/images/members/hasegawa/list.jpg'} alt='' width={'100%'} />
            </div>
            <div className={styles[`${blockClass}__staff-inner`]}>
              <p className={styles[`${blockClass}__staff-name`]}>長谷川 奨</p>
              <p className={styles[`${blockClass}__staff-text`]}>
                お客様の理想をとことんお伺いします！！その上で最高の一台を共に拘り抜いて創造していきましょう！！
              </p>
              <p className={styles[`${blockClass}__staff-link`]}>詳細を見る</p>
            </div>
          </Link>
        </li>

        <li className={styles[`${blockClass}__staff-item`]}>
          <Link to='/member/midorikawa'>
            <div className={styles[`${blockClass}__staff-img`]}>
              <img src={'/images/members/midorikawa/list.jpg'} alt='' width={'100%'} />
            </div>
            <div className={styles[`${blockClass}__staff-inner`]}>
              <p className={styles[`${blockClass}__staff-name`]}>緑川 凱久</p>
              <p className={styles[`${blockClass}__staff-text`]}>
                あなたと一生のお付き合いする覚悟で誰よりも寄り添って一緒に考えます。楽しいクルマ探しの旅をしましょう！
              </p>
              <p className={styles[`${blockClass}__staff-link`]}>詳細を見る</p>
            </div>
          </Link>
        </li>
      </ul>
      <p className={[styles[`${blockClass}__staff-button`], 'c-button is-secondary'].join(' ')}>
        <Link to='/members/'>もっと見る</Link>
      </p>

      <div className={styles[`${blockClass}__review`]}>
        <ReviewSection />
      </div>

      <p className={styles[`${blockClass}__button`]}>
        <TrackableLink
          to={formLink}
          analyticsData={{ eventName: linkEventName }}
          className={[styles[`${blockClass}__step-button`], gtmClass].join(' ')}>

          クルマのリクエストはこちら
        </TrackableLink>
      </p>

      <LineBanner />

      <h2 className={styles[`${blockClass}__title`]}>よくあるご質問</h2>
      <p className={styles[`${blockClass}__titleCaption`]}>ご質問いただく内容をまとめました</p>

      <ul className={styles[`${blockClass}__faq`]}>
        <Faq
          title='50万円以下で買える車はありますか？'
          text='はい、50万円以下でご購入いただける車も取り揃えております。ご予算に合わせた車両をご提案いたしますので、お気軽にご相談ください。' />

        <Faq
          title='車のことがよくわからないのですが、どうすればいいですか？'
          text='ご心配ありません。私たちの専門スタッフが、基本から丁寧にご説明し、ご購入のサポートをいたします。' />

        <Faq
          title='探している車が見つからない場合はどうすればいいですか？'
          text={
          <>
              お探しの車が見つからない場合でもご安心ください。私たちの広範なネットワークと専用ルートを活用して、ご希望に沿った車両をお探しします。
              <br />
              ※世間の売値相場が合わないなどのお車に関してはご了承ください。
            </>
          } />


        <Faq
          title='問い合わせた内容への返信はどれくらいで来ますか？'
          text='お問い合わせいただいた内容には、原則として1営業日以内に返信いたします。ただし、お問い合わせの内容によってはもう少し時間がかかる場合がありますので、ご了承ください。' />

        <Faq
          title='相談だけでも大丈夫ですか？押し売りはありませんか？'
          text='もちろん、相談だけでも大歓迎です。当社では、お客様に無理な販売をすることはありませんので、ご安心ください。お客様のニーズに合わせた最適な提案を心がけております。' />

      </ul>

      <MediaSection />

      <div className={styles[`${blockClass}__footerFiexd`]}>
        <p>
          <TrackableLink
            to={formLink}
            analyticsData={{ eventName: linkEventName }}
            className={[styles[`${blockClass}__step-button`], gtmClass].join(' ')}>

            クルマのリクエストはこちら
          </TrackableLink>
        </p>
      </div>
    </>);

};