import { useNavigate, useSearchParams } from '@remix-run/react';
import { useEffect, type FunctionComponent } from 'react';
import { useMutation, useQuery } from 'urql';
import { StandardError } from '~/components/errors';
import { Loading } from '~/components/parts';
import { SmallCarCard } from '~/components/shared';
import { graphql } from '~/gql/generated';
import { type InquiresNewInquireCarStockMutation } from '~/gql/generated/graphql';
import { useAnalytics, useAuth } from '~/hooks';
import { useDeliveryAddressContext, useErrorFlashContext } from '~/providers';
import formStyles from '~/styles/page/inquires/inquiresNew/inquires-form.module.css';
import styles from '~/styles/page/inquires/inquiresNew/inquires-new.module.css';
import { errorHandle } from '~/utils/graphql/errorHandle';

const blockClass = 'inquires-new';

const InquiresNewComponentQueryDocument = graphql(`
  query InquiresNewComponent($ulids: [String!], $prefectureCode: Int) {
    carStocks(ulids: $ulids) {
      nodes {
        id
        landTransportCost(prefectureCode: $prefectureCode)
        ...SmallCarCard_carStock
      }
    }
  }
`);

const ContactsNewCreateInquiry = graphql(`
  mutation InquiresNewInquireCarStock($input: InquireCarStocksInput!) {
    inquireCarStocks(input: $input) {
      inquiry {
        id
        user {
          id
          firstName
          lastName
          zipCode
          inquiries {
            id
            carStocks {
              id
            }
          }
        }
      }
      errors {
        message
        path
      }
    }
  }
`);

export const InquiresNew: FunctionComponent = () => {
  const [searchParams] = useSearchParams();
  const paramsCarStockUlids = searchParams.get('carStockUlids')?.split(',') || [];
  const searchQueryParameter = searchParams.get('search');
  const { prefectureCode } = useDeliveryAddressContext();
  const [{ data, fetching, error }] = useQuery({
    query: InquiresNewComponentQueryDocument,
    variables: { ulids: paramsCarStockUlids, prefectureCode },
    pause: paramsCarStockUlids.length === 0
  });
  const carStocks = data?.carStocks?.nodes?.filter((node) => !!node) || [];
  const carStockIds = carStocks.map((carStock) => carStock!.id);
  const createInquiry = useMutation(ContactsNewCreateInquiry)[1];
  const navigate = useNavigate();
  const { user } = useAuth();
  const favoritedCarStockIds = user?.favoritedCarStocks?.map((favoritedCarStock) => favoritedCarStock.id) || [];
  const { open: openErrorFlash } = useErrorFlashContext();
  const { sendTrackEvent } = useAnalytics();

  useEffect(() => {
    if (!user) return;

    sendTrackEvent('inquires_new_search', {
      search_query_parameter: searchQueryParameter || ''
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  if (paramsCarStockUlids.length === 0) {
    return <StandardError />;
  }

  if (fetching) {
    return <Loading />;
  }

  if (carStocks.length === 0) {
    return <StandardError />;
  }

  if (error) {
    return <StandardError />;
  }

  const handleSubmit = async () => {
    try {
      const { data, error } = await createInquiry({
        input: {
          carStockIds: carStockIds
        }
      });

      const { hasError } = errorHandle<InquiresNewInquireCarStockMutation['inquireCarStocks']>(
        data?.inquireCarStocks,
        error
      );
      if (hasError) {
        openErrorFlash();
        return;
      }
    } catch (e) {
      openErrorFlash();
      return;
    }

    sendTrackEvent('submit_inquire_car_stocks');
    navigate('/inquires/complete');
  };

  return (
    <div className={styles[blockClass]}>
      <p className={styles[`${blockClass}__title`]}>
        {carStocks.length > 1 && 'まとめて'}お問い合わせ（{carStocks.length}件）
      </p>
      <div className={styles[`${blockClass}__cars`]}>
        {carStocks.map((carStock) =>
        <div className={styles[`${blockClass}__cars-item`]} key={carStock!.id}>
            <SmallCarCard
            carStock={carStock!}
            isFavorited={favoritedCarStockIds.includes(carStock!.id)}
            unclickable={true}
            imageHeight={164} />

          </div>
        )}
      </div>
      <button className={formStyles[`inquires-form__button`]} onClick={handleSubmit}>
        送信
      </button>
    </div>);

};