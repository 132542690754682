import { type FunctionComponent } from 'react';
import { Breadcrumb } from '~/components/shared';

export const PrivacyIndex: FunctionComponent = () => {
  return (
    <>
      <Breadcrumb text='プライバシーポリシー' />
      <h1 className='headline1'>プライバシーポリシー</h1>
      <p className='c-text'>
        BUDDICA・DIRECT
        株式会社株式会社（以下「当社」といいます）は、当社が提供するサービス（以下「当社サービス」といいます）のユーザーの個人情報を含む情報の取扱いについて、以下のとおりプライバシーポリシー（以下「本ポリシー」といいます）を定めます。
        <br />
        なお、本ポリシーにおける用語の定義は、特に定めのない限り、個人情報の保護に関する法律（平成15 年法律第57
        号）（以下「個人情報保護法」といいます）及び関連法令に定めるところによるものとします。
      </p>

      <h2 className='headline2'>1.当社の名称・住所・代表者の氏名</h2>
      <p className='c-text'>
        BUDDICA・DIRECT 株式会社
        <br />
        〒150-0002 東京都渋谷区渋谷二丁目 14-13 岡崎ビル 1008
        <br />
        代表取締役 中野 優作
      </p>

      <h2 className='headline2'>2.関係法令等の遵守</h2>
      <p className='c-text'>
        当社は、個人情報保護法その他の法令及び個人情報保護委員会のガイドラインその他のガイドラインを遵守し、個人データの適正な取扱を行います。
      </p>

      <h2 className='headline2'>3.個人情報の適切な取得</h2>
      <ol className='c-numList'>
        <li>
          当社は、個人情報を取得する際には、利用目的を公表または通知し（本ポリシーによる公表を含みます）、また直接本人から契約書その他の書面（電磁的記録を含みます）に記載された個人情報を取得する場合にはあらかじめ利用目的を明示し、適法かつ公正な手段によって取得します。
        </li>
        <li>当社は、利用目的達成に必要な範囲で適正に個人情報を利用します。</li>
      </ol>

      <h2 className='headline2'>4.利用目的</h2>
      <p className='c-text'>当社は、以下の目的で取得した個人情報を利用します。</p>
      <ol className='c-numList'>
        <li>
          当社サービスのユーザーに関する個人情報
          <ol>
            <li>当社サービスの提供・運営に必要なご連絡</li>
            <li>ユーザーからのお問合せ・ご依頼事項への対応</li>
            <li>当社サービス提供にあたっての本人確認・認証</li>
            <li>当社サービスの案内及び提供</li>
            <li>契約の履行(サービスの実施等)</li>
            <li>ユーザーにご自身の登録情報の閲覧や変更、削除、ご利用状況の閲覧を行っていただくため</li>
            <li>個人を識別できない形式に加工した統計データを作成するため</li>
            <li>当社が主催又は参加するセミナー・展示会等のイベントに関するご案内</li>
            <li>ユーザー満足度調査等のアンケートの依頼</li>
            <li>広報誌や会社情報等の提供</li>
            <li>研究開発に開発するため</li>
            <li>上記の利用目的に付随する目的</li>
            <li>その他、ユーザーにご同意をいただいた目的</li>
          </ol>
        </li>
        <li>
          取引先に関する個人情報
          <ol>
            <li>当社の取引先管理のため</li>
            <li>業務上必要なご連絡のため</li>
            <li>契約の履行のため</li>
            <li>その他営業活動等のため</li>
          </ol>
        </li>
        <li>
          株主の個人情報
          <ol>
            <li>株主総会の開催・運営のため</li>
            <li>株式関連業務のため</li>
          </ol>
        </li>
        <li>
          採用応募者の個人情報
          <br />
          人事採用活動（書類審査、面接、評価、応募者への連絡等）のため
        </li>
        <li>
          従業員の個人情報
          <br />
          人事労務の管理、報酬の支払い、社会保険・福利厚生等の管理のため
        </li>
        <li>
          お問合せいただいた方の個人情報
          <br />
          お問合せに対応するため
        </li>
      </ol>

      <h2 className='headline2'>5.利用目的の変更</h2>
      <ol className='c-numList'>
        <li>
          当社は、利用目的が変更前と関連性を有すると合理的に認められる場合に限り、前項の利用目的を変更するものとします。
        </li>
        <li>
          利用目的の変更を行った場合には、変更後の目的について、当社所定の方法により、ユーザーに通知又は公表するものとします。
        </li>
      </ol>

      <h2 className='headline2'>6.共同利用</h2>
      <p className='c-text'>
        株式会社 BUDDICA、株式会社 Seibii および当社は、本ポリシー記載の利用目的のほか、株式会社 BUDDICA、株式会社
        Seibii
        および当社のグループとしての業務の適切かつ円滑な遂行のために必要な範囲内で、個人の氏名、住所、電話番号等の連絡先、その他それぞれが保有するすべての個人データをについて、互いに共同利用する場合があります。その場合の当該グループにおける個人データの管理責任者は、BUDDICA・DIRECT
        株式会社となります。
      </p>

      <h2 className='headline2'>7.第三者提供</h2>
      <ol className='c-numList'>
        <li>
          当社は、次に掲げる場合又は個人情報保護法その他の法令で認められる場合を除いて、第三者に個人情報を提供することはありません。
          <ol>
            <li>本人の同意がある場合</li>
            <li>人の生命、身体又は財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき</li>
            <li>
              公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき
            </li>
            <li>
              国の機関もしくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき
            </li>
            <li>当社が利用目的の達成に必要な範囲内において個人情報の取扱いの全部又は一部を委託する場合</li>
            <li>合併その他の事由による事業の承継に伴って個人情報が提供される場合</li>
            <li>
              前項に定める場合を含め、個人情報を特定の者との間で共同して利用する場合であって、その旨並びに共同して利用される個人情報の項目、共同して利用する者の範囲、利用する者の利用目的及び当該個人情報の管理について責任を有する者の氏名又は名称について、あらかじめ本人に通知し、又は本人が容易に知り得る状態に置いた場合
            </li>
          </ol>
        </li>
        <li>
          当社は、取得した個人関連情報（個人情報保護法第2 条第7
          項に定める「個人関連情報」をいいます）を本ポリシーに掲げる利用目的で第三者に提供することがあります。この場合、提供先において、当該個人関連情報を個人データとして取得することがあります。また、当社は、取得した個人関連情報と当社が保有する個人情報とを紐付けた上で利用する場合があります。なお、この場合の利用目的は、本ポリシーに定めるとおりです。
        </li>
      </ol>

      <h2 className='headline2'>8.個人情報取扱業務の委託について</h2>
      <p className='c-text'>
        当社は、利用目的の達成に必要な範囲内において、個人情報取扱業務の全部又は一部を外部委託することがあります。この場合、当社は当該委託先に対して必要かつ適切な監督を行います。
      </p>

      <h2 className='headline2'>9.安全管理措置に関する事項</h2>
      <p className='c-text'>
        当社は、個人データについて、漏えい、滅失又は毀損の防止等、その管理のために必要かつ適切な安全管理措置を講じます。また、個人データを取り扱う従業者や委託先に対して、必要かつ適切な監督を行います。安全管理措置の概要は、基本方針の策定、組織的安全管理措置、人的安全管理措置、物理的安全管理措置及び技術的安全管理措置の実施並びに外的環境の把握となりますが、具体的な安全管理措置の内容は、ご本人から後記のお問い合わせ窓口よりご連絡いただけましたら遅滞なく回答いたします。
      </p>

      <h2 className='headline2'>10.Cookie等を利用して取得する情報について</h2>
      <p className='c-text'>
        当社では、当社サービスの向上のため、Cookie（類似の技術を含みます。以下同様です）を利用し、ご本人の行動履歴（アクセスしたURL、コンテンツ、参照順序等）や、年齢や性別、職業、居住地域、ご来店履歴、位置情報等個人が特定できない属性情報（それらの組み合わせによっても個人が特定できないもの）を取得することがあります。
        <br />
        また、 当社は、サイト内での訪問履歴を参照し、最適なコンテンツを配信するためにCookie
        を利用し、当社及び広告配信業者等の第三者がご本人の情報を取得することがあります。配信には、広告配信事業者のサービスやマーケティングツールなどを活用することがあります。
        <br />
        なお、ご自身の情報の取得を望まれない場合は、ご利用のブラウザの設定によりCookie
        の受け取りを拒否することが可能です。この場合、一部の当社サービスが正常にご利用いただけなくなる可能性がありますのでご留意ください。
      </p>

      <h2 className='headline2'>11.個人データの開示等</h2>
      <ol className='c-numList'>
        <li>
          当社は、本人から保有個人データの利用目的の通知、開示、内容の訂正、追加もしくは削除又は利用停止、消去もしくは第三者への提供の停止（以下、「開示等」と総称します）を求められたときは、本人に対し、遅滞なくこれを開示します。ただし、次のいずれかに該当する場合など、開示等の請求に応じることができないときは、その旨を遅滞なく通知します。なお、保有個人データの一部又は全部について削除又は利用停止、消去もしくは第三者への提供の停止がなされた場合、ご要望に沿った当社サービスの提供、取引等ができなくなることがありますので、あらかじめご了承下さい。
          <ol>
            <li>本人又は第三者の生命、身体、財産その他の権利利益を害するおそれがある場合</li>
            <li>当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合</li>
            <li>本人確認ができない場合</li>
            <li>代理人による請求に際して代理権が確認できない場合</li>
            <li>所定の提出書類に不備があった場合</li>
            <li>所定の提出書類の不備により保有個人データを特定できなかった場合</li>
            <li>開示等のご請求の対象が保有個人データに該当しない場合</li>
            <li>開示等が法令に違反することとなる場合</li>
          </ol>
        </li>
        <li>
          保有個人データの開示及び利用目的の通知に際しては、1 件あたり1,000
          円の手数料が必要となりますのであらかじめご了承ください。
        </li>
        <li>
          開示等のご請求に際して取得した個人情報は、ご請求への対応に必要な範囲でのみ適切に取り扱います。また、ご請求に伴いご提出いただいた書面等は返却いたしかねますので、あらかじめご了承ください。
        </li>
      </ol>

      <h2 className='headline2'>12.プライバシーポリシーの変更</h2>
      <p className='c-text'>
        当社は、個人情報の取扱いに関する運用状況を随時見直し、継続的な改善に努めるものとし、必要に応じて、本ポリシーの内容を変更することがあります。
      </p>

      <h2 className='headline2'>13.お問合せ窓口</h2>
      <p className='c-text'>
        BUDDICA・DIRECT 株式会社 個人情報保護管理責任者 小川 靖人
        <br />
        <a href='https://forms.gle/mqXkgrZ2X3rL1yi6A' target='_blank' rel='noreferrer'>
          お問合せフォーム
        </a>
        よりご連絡ください。
      </p>
      <p className='c-text c-text--right'>2024年1月22日制定</p>
    </>
  );
};
