import { useInViewport } from '@mantine/hooks';
import { useState, type FunctionComponent, useRef, useEffect } from 'react';
import styles from '~/styles/page/cars/carsIndex/car-movie-card.module.css';

const blockClass = 'car-movie-card';

type Props = {
  videoUrl: string;
  thumbnailUrl?: string | null;
  isFirst: boolean;
  isStopAutoPlay: boolean;
  onClick: () => void;
};

export const CarMovieCard: FunctionComponent<Props> = ({
  videoUrl,
  thumbnailUrl,
  isFirst,
  isStopAutoPlay,
  onClick
}) => {
  const [isShowVideo, setIsShowVideo] = useState(false);
  const [isLoadedVideo, setIsLoadedVideo] = useState(false);
  const [startShowVideoTimer, setStartShowVideoTimer] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const { ref, inViewport } = useInViewport();

  useEffect(() => {
    if (isFirst) {
      setIsShowVideo(true);
    }
  }, [isFirst]);

  useEffect(() => {
    const parentElement = document.getElementById('car-movies-section');
    if (!parentElement) return;

    parentElement.addEventListener('scroll', () => {
      const element = ref.current;
      if (!element) return;

      const elementRect = element.getBoundingClientRect();
      const elementLeft = elementRect.left;
      const elementRight = elementRect.right;

      const windowWidth = window.innerWidth;
      const centerPosition = windowWidth / 2;

      if (elementLeft <= centerPosition && elementRight >= centerPosition) {
        setStartShowVideoTimer(true);
      } else {
        setStartShowVideoTimer(false);
        setIsShowVideo(false);
        setIsLoadedVideo(false);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!startShowVideoTimer) return;

    setTimeout(async () => {
      setIsShowVideo(true);
    }, 700);
  }, [startShowVideoTimer]);

  useEffect(() => {
    if (!isShowVideo) return;
    if (!videoRef.current) return;

    videoRef.current.onloadeddata = () => {
      setIsLoadedVideo(true);
    };
  }, [isShowVideo]);

  useEffect(() => {
    if (!videoRef.current) return;

    if (inViewport) {
      if (!isFirst) return;
      setIsShowVideo(true);
    } else {
      setIsShowVideo(false);
      setIsLoadedVideo(false);
    }
  }, [inViewport, isFirst]);

  return (
    <div className={styles[blockClass]} onClick={onClick} ref={ref}>
      {isShowVideo && !isStopAutoPlay &&
      <video
        ref={videoRef}
        src={videoUrl}
        className={styles[`${blockClass}__video`]}
        playsInline
        autoPlay
        muted
        poster={thumbnailUrl || '/images/pictures/video_loading.png'}
        style={{ display: isLoadedVideo ? 'block' : 'none' }} />

      }
      <img
        src={thumbnailUrl || '/images/pictures/video_loading.png'}
        className={styles[`${blockClass}__thumbnail`]}
        alt='video_thumbnail' />

    </div>);

};