import { useLocation, useSearchParams } from '@remix-run/react';
import { useEffect, useRef, useState, type FunctionComponent } from 'react';
import { useClient } from 'urql';
import { CarDeliveryAddress, CarMoviePopup, CarStatusBar } from '~/components/shared';
import { graphql, useFragment, type FragmentType } from '~/gql/generated';
import type { CarMovies_CarStockFragment } from '~/gql/generated/graphql';
import { CarSalesStatusEnum } from '~/gql/generated/graphql';
import { useAuth } from '~/hooks';
import { useDeliveryAddressContext, useInfiniteScrollContext } from '~/providers';
import { variablesFromSearchParams } from '~/routes/cars.movies';
import styles from '~/styles/page/cars/carsMovies/cars-movies.module.css';
import { CarCard } from './CarCard';

const blockClass = 'cars-movies';

const CarMoviesCarStocksFragment = graphql(`
  fragment CarMovies_carStock on CarStock {
    id
    ...CarCard_carStock
    ...CarMoviePopup_carStock
  }
`);

const carsMoviesComponentQueryDocument = graphql(`
  query CarsMoviesComponent(
    $first: Int
    $after: String
    $hasVideo: Boolean
    $salesStatus: CarSalesStatusEnum
    $prefectureCode: Int
  ) {
    carStocks(first: $first, after: $after, hasVideo: $hasVideo, salesStatus: $salesStatus) {
      edges {
        node {
          id
          landTransportCost(prefectureCode: $prefectureCode)
          ...CarMovies_carStock
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`);

const useCarStocks = ({
  firstCarStocks,
  firstEndCursor,
  firstHasNextCarStocks




}: {firstCarStocks: readonly CarMovies_CarStockFragment[];firstEndCursor?: string | null;firstHasNextCarStocks: boolean;}): {
  carStocks: readonly CarMovies_CarStockFragment[];
  hasNextCarStocks: boolean;
  initializing: boolean;
  observerTarget: React.MutableRefObject<null>;
  setIsFetchNextCarStocks: React.Dispatch<React.SetStateAction<boolean>>;
} => {
  const [carStocks, setCarStocks] = useState(firstCarStocks);
  const [endCursor, setEndCursor] = useState(firstEndCursor);
  const [hasNextCarStocks, setHasNextCarStocks] = useState(firstHasNextCarStocks);
  const [initializing, setInitializing] = useState(true);
  const [isFetchNextCarStocks, setIsFetchNextCarStocks] = useState(false);
  const [searchParams] = useSearchParams();
  const { prefectureCode } = useDeliveryAddressContext();
  const observerTarget = useRef(null);
  const location = useLocation();
  const client = useClient();
  const { infiniteScroll, addScrolledCursor } = useInfiniteScrollContext();

  useEffect(() => {
    setCarStocks(firstCarStocks);
    setHasNextCarStocks(firstHasNextCarStocks);
    setEndCursor(firstEndCursor);
    initializeCarStocks();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstCarStocks, firstEndCursor, firstHasNextCarStocks]);

  const initializeCarStocks = async () => {
    const targetCursors = infiniteScroll[location.key]?.cursors || [];

    if (targetCursors.length === 0) {
      setInitializing(false);
      return;
    }

    targetCursors.forEach(async (cursor, index) => {
      const variables = {
        ...variablesFromSearchParams(searchParams),
        ...{ after: cursor, prefectureCode: prefectureCode }
      };
      const result = await client.query(carsMoviesComponentQueryDocument, variables);
      if (result.error) return;

      const resultCarStocks = (result.data?.carStocks?.edges?.map((edge) => edge?.node).filter((node) => !!node) ||
      []) as CarMovies_CarStockFragment[];
      setCarStocks((prev) => [...prev, ...resultCarStocks]);

      if (index === targetCursors.length - 1) {
        const resultHasNextPage = result.data?.carStocks?.pageInfo?.hasNextPage || false;
        const resultEndCursor = result.data?.carStocks?.pageInfo?.endCursor;
        setHasNextCarStocks(resultHasNextPage);
        setEndCursor(resultEndCursor);
      }
    });
    setInitializing(false);
    setTimeout(() => {
      const elementId = infiniteScroll[location.key]?.elementId;
      if (!elementId) return;
      const targetElement = document.getElementById(elementId);
      if (!targetElement) return;
      targetElement.scrollIntoView({ block: 'center' });
    }, 100);
  };

  const fetchNextCarStocks = async () => {
    if (!hasNextCarStocks) {
      return;
    }
    if (!isFetchNextCarStocks) return;

    const currentEndCursor = endCursor;
    const variables = {
      ...variablesFromSearchParams(searchParams),
      ...{ after: endCursor, prefectureCode: prefectureCode }
    };
    const result = await client.query(carsMoviesComponentQueryDocument, variables);
    if (result.error) {
      setIsFetchNextCarStocks(false);
      return;
    }
    const resultCarStocks = (result.data?.carStocks?.edges?.map((edge) => edge?.node).filter((node) => !!node) ||
    []) as CarMovies_CarStockFragment[];
    const resultHasNextPage = result.data?.carStocks?.pageInfo?.hasNextPage || false;
    const resultEndCursor = result.data?.carStocks?.pageInfo?.endCursor;
    setCarStocks((prev) => [...prev, ...resultCarStocks]);
    setHasNextCarStocks(resultHasNextPage);
    setEndCursor(resultEndCursor);

    if (currentEndCursor) {
      addScrolledCursor(location.key, currentEndCursor);
    }

    setIsFetchNextCarStocks(false);
  };

  useEffect(() => {
    if (!isFetchNextCarStocks) return;
    fetchNextCarStocks();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchNextCarStocks]);

  useEffect(() => {
    setIsFetchNextCarStocks(false);
  }, [location]);

  useEffect(() => {
    if (initializing) return;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          if (hasNextCarStocks) {
            setIsFetchNextCarStocks(true);
          }
        }
      },
      { threshold: 1.0 }
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initializing]);

  return { carStocks, hasNextCarStocks, initializing, observerTarget, setIsFetchNextCarStocks };
};

type Props = {
  carStocks: FragmentType<typeof CarMoviesCarStocksFragment>[];
  firstHasNextCarStocks: boolean;
  firstEndCursor?: string | null;
};

export const CarsMovies: FunctionComponent<Props> = (props) => {
  const firstCarStocks = useFragment(CarMoviesCarStocksFragment, props.carStocks);
  const { firstHasNextCarStocks, firstEndCursor } = props;
  const { carStocks, hasNextCarStocks, observerTarget, setIsFetchNextCarStocks } = useCarStocks({
    firstCarStocks,
    firstEndCursor,
    firstHasNextCarStocks
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const { user } = useAuth();
  const favoritedCarStocks = user?.favoritedCarStocks || [];
  const favoritedCarStockIds = favoritedCarStocks.map((carStock) => carStock.id);
  const [showMoviePopup, setShowMoviePopup] = useState(false);
  const [currentMovieIndex, setCurrentMovieIndex] = useState(0);
  const [isStopAutoPlay, setIsStopAutoPlay] = useState(false);
  const { setValues } = useDeliveryAddressContext();

  const openPopup = (index: number) => {
    setIsStopAutoPlay(true);
    setShowMoviePopup(true);
    setCurrentMovieIndex(index);
  };
  const closePopup = () => {
    setIsStopAutoPlay(false);
    setShowMoviePopup(false);
  };

  const nextMovie = () => {
    setCurrentMovieIndex((currentMovieIndex + 1) % carStocks.length);

    if (currentMovieIndex >= carStocks.length - 2) {
      setIsFetchNextCarStocks(true);
    }
  };

  const backMovie = () => {
    setCurrentMovieIndex((currentMovieIndex - 1 + carStocks.length) % carStocks.length);
  };

  const changeStatus = (status: CarSalesStatusEnum) => {
    setSearchParams((prev) => {
      prev.set('status', status);
      return prev;
    });
  };

  const changeZipCodeAndAddress = (zipCode?: string, address?: string, prefectureCode?: number) => {
    setValues({ zipCode, address, prefectureCode });
    setSearchParams((prev) => {
      prefectureCode ? prev.set('prefectureCode', prefectureCode.toString()) : prev.delete('prefectureCode');
      return prev;
    });
  };

  return (
    <div className={styles[blockClass]}>
      <CarStatusBar
        status={
        searchParams.get('status') === CarSalesStatusEnum.Closed ?
        CarSalesStatusEnum.Closed :
        CarSalesStatusEnum.OnSale
        }
        onChangeStatus={changeStatus} />

      <div style={{ margin: '16px 0' }}>
        <CarDeliveryAddress onComplete={changeZipCodeAndAddress} />
      </div>
      <p className={styles[`${blockClass}__title`]}>車両の動画ツアー</p>
      <div className={styles[`${blockClass}__cars`]}>
        {carStocks.map((carStock, index) =>
        <CarCard
          carStock={carStock}
          isFavorited={favoritedCarStockIds.includes(carStock.id)}
          key={index}
          movieIndex={index}
          openPopup={openPopup}
          showPreview={index % 4 === 0 && !isStopAutoPlay} />

        )}
      </div>
      <div ref={observerTarget}>{hasNextCarStocks && <div className={styles[`${blockClass}__loader`]} />}</div>
      {showMoviePopup && carStocks.length > 0 &&
      <CarMoviePopup
        carStock={carStocks[currentMovieIndex]}
        hideBackButton={currentMovieIndex === 0}
        hideNextButton={currentMovieIndex === carStocks.length - 1}
        nextMovie={nextMovie}
        backMovie={backMovie}
        closePopup={closePopup} />

      }
    </div>);

};