import { useState, type FunctionComponent, useMemo } from 'react';
import styles from '~/styles/page/inquires/inquiresComplete/inquires-complete.module.css';
import { Link } from '@remix-run/react';
import liff from '@line/liff';
import { useLine } from '~/hooks';

const blockClass = 'inquires-complete';

export const InquiresComplete: FunctionComponent = () => {
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const { lineTokeUrl } = useLine();
  const os = useMemo(() => liff.getOS(), []);

  return (
    <div className={styles[blockClass]}>
      <p className={styles[`${blockClass}__title`]}>送信完了</p>
      <div className={styles[`${blockClass}__main`]}>
        <p>ご相談いただきありがとうございます</p>
        <img src='/images/pictures/contact.svg' alt='thank you' />
      </div>
      <p className={styles[`${blockClass}__text`]}>LINE宛にメッセージをお送りしましたのでご確認くださいませ。</p>

      <div className={styles[`${blockClass}__staff`]}>
        <div className={styles[`${blockClass}__staff-img`]}>
          <img src='/images/pictures/img_staff.png' alt='' />
        </div>
        <p className={styles[`${blockClass}__staff-text`]}>
          お客様のご質問や要望に、できる限り丁寧かつ満足いただけるようお答えするために、スタッフ一同尽力いたします。
        </p>
      </div>
      {(os === 'android' || os === 'ios') &&
      <a href={lineTokeUrl} className={styles[`${blockClass}__button`]}>
          <img src={'/images/icons/ic_line.svg'} className={styles[`${blockClass}__button-icon`]} alt='line' />
          <span className={styles[`${blockClass}__button-text`]}>LINEを開く</span>
        </a>
      }
      <div className={styles[`${blockClass}__unsend`]}>
        <p
          className={[
          styles[`${blockClass}__unsend-header`],
          isOpenMenu ? styles[`${blockClass}__unsend-header--open`] : ''].
          join(' ')}
          onClick={() => setIsOpenMenu(!isOpenMenu)}>

          メッセージが届かない方
        </p>
        {isOpenMenu &&
        <div className={styles[`${blockClass}__unsend-body`]}>
            <p>
              ・LINEで相手を友達リストに追加しているか確認してください。追加されていなければ、友達追加をお願いします。
            </p>
            <p>
              ・アカウントをブロックしていないか確認してください。ブロックしていた場合は、ブロック解除を行ってください。
            </p>
            <p>2点対応後、再度お問合わせくださいませ。</p>
          </div>
        }
      </div>
      <Link to='/' className={styles[`${blockClass}__top-button`]}>
        TOPに戻る
      </Link>
    </div>);

};