import { type FunctionComponent } from 'react';
import { Breadcrumb } from '~/components/shared';

export const InitialDefectSupportIndex: FunctionComponent = () => {
  return (
    <>
      <Breadcrumb text='初期不良無償対応保証に関する規約' />
      <h1 className='headline1'>初期不良無償対応保証に関する規約</h1>
      <p className='c-text'>
        BUDDICA・DIRECT 株式会社（以下「当社」といいます。）は、当社が管理運営するプラットフォームである「BUDDICA
        DIRECT」（以下「本プラットフォーム」といいます。）において車両を購入したユーザー（以下「会員」といいます。）に対し、本初期不良無償対応保証に関する規約（以下「本規約」といいます。）に基づき、初期不良無償対応保証（以下「本保証」といいます。）を提供します。なお、本プラットフォームの利用に当たっては、当社が別途定める「BUDDICA
        DIRECT 利用規約」が適用されるものとします。
      </p>
      <h2 className='headline2'>第1章 本保証の内容</h2>

      <h3 className='headline3'>第 1 条（保証要件）</h3>
      <ol className='c-numList'>
        <li>
          本保証は、本プラットフォームにおける円滑な取引実現のため、当社が本規約に基づいて会員のために提供するものです。
        </li>
        <li>本保証の適用対象である車両（以下「本保証対象車両」といいます。）は、当社が指定した車両とします。</li>
        <li>
          会員は、本保証対象車両に不具合が発生した場合において、かかる不具合が、当社が別途定める「保証対象部品一覧表」に含まれる部品を主な原因として生じたものであるときは、当社に本保証の適用を求める（申告する）ことができます。ただし、会員は、第2
          条（保証期間）に定める保証期間内において当該申告をしなければならず、当該不具合が保証期間内に発生した場合であっても、保証期間満了後に当該申告をした場合は本保証を適用しないものとします。
        </li>
      </ol>

      <h3 className='headline3'>第 2 条（保証期間）</h3>
      <p className='c-text'>保証期間は、会員への納車日を起算点とし、30 日（納車日を含む）の経過をもって終了します。</p>
      <h3 className='headline3'>第 3 条（譲渡の禁止）</h3>
      <ol className='c-numList'>
        <li>
          会員は、当社の事前の承諾なく、本保証を受ける権利を第三者に対し、譲渡、移転、担保設定、その他の処分をすることはできないものとします。
        </li>
        <li>前項の規定は、会員が、本保証対象車両を譲渡した場合も適用します。</li>
      </ol>

      <h3 className='headline3'>第 4 条（届出事項の変更の届出）</h3>
      <p className='c-text'>
        会員が、その住所や連絡先その他の事項を変更した場合には、遅滞なく、その旨を当社に届け出ることとします。
      </p>

      <h3 className='headline3'>第 5 条（本保証適用除外事由）</h3>
      <ol className='c-numList'>
        <li>会員の求める修理が、他の保証の対象となる場合には、本保証は適用しないものとします。</li>
        <li>
          会員が、本保証対象車両の売主（当該売主が法人である場合には、その役職員を含みます。）の6
          親等以内の親族である場合には、本保証の適用はないものとします。
        </li>
        <li>
          本保証対象車両の走行距離が、会員への納車日時点で以下の各号の基準以上の場合には、本保証は適用しないものとします。
          <ol className='c-numList'>
            <p>1. 国産車：150,000㎞</p>
            <p>2. 輸入車：100,000㎞</p>
          </ol>
        </li>
        <li>
          次の各号のいずれかに該当する現象または不具合については、本保証は適用しないものとします。
          <ol className='c-numList'>
            <p>1. 外観上の現象</p>
            <p>2. 使用摩耗あるいは経年変化により発生する現象</p>
            <p>3. 運転の仕方に起因する現象</p>
            <p>4. 改造部品が取り付けられていた場合の不具合</p>
            <p>5. その他これらに準じる現象または不具合</p>
          </ol>
        </li>
        <li>
          次の各号にいずれかに起因する不具合については、本保証を適用しないものとします。
          <ol className='c-numList'>
            <p>1. メーカーが指定する定期交換部品の交換未実施</p>
            <p>2. 法令に違反する方法による車両使用時の不具合</p>
            <p>3. その他これらに準じる不具合</p>
          </ol>
        </li>
      </ol>

      <h2 className='headline2'>第2章 修理の実施</h2>
      <h3 className='headline3'>第6条（保証実施手順）</h3>
      <ol className='c-numList'>
        <li>
          会員は、当社に対し、以下の条件においてのみ、本保証の適用を求めることができます。
          <ol className='c-numList'>
            <p>1. 保証期間内に会員本人が当社に連絡をし、点検・整備や修理について当社の事前の承認を得ること</p>
            <p>2. 当社の指定する整備・修理工場に車両を引き渡し、かつ、同工場で実際に整備・修理を行うこと</p>
            <p>3. 当社は修理費を前号に規定する整備・修理工場へ直接支払うこと</p>
          </ol>
        </li>
        <li>会員が前項の規定に違反して、点検・整備や修理を行った場合には、本保証を適用しません。</li>
      </ol>

      <h3 className='headline3'>第7条（身分証の呈示）</h3>
      <p className='c-text'>
        当社は、会員から本保証の適用を求められたときは、必要に応じて、会員の身分証明書、車両の車検証及び点検整備記録簿その他の資料の呈示を求めることができるものとします。会員がこれに応じない場合、本保証は適用しないものとします。
      </p>
      <h3 className='headline3'>第8条（交換対象部品等）</h3>
      <p className='c-text'>
        本保証の適用により車両を修理する場合において、部品等の交換が必要になったときは、会員は部品・油脂類等のグレード、種類、製造会社等を指定することはできず、当社がこれを指定するものとします。
      </p>
      <h3 className='headline3'>第9条（改造車両）</h3>
      <p className='c-text'>以下に定める改造車両については、本保証を適用しないものとします。</p>
      <ol className='c-numList'>
        <li>エンジンの出力アップを目的にした改造が施された車両</li>
        <li>
          ハイドロリクスサスペンション・ハイルーフ・ボディーリフトアップ・シフト変更・エンジンボアアップ等の加工、または加工歴が確認された車両
        </li>
        <li>違法な改造が施された車両</li>
        <li>その他これらに準じる車両</li>
      </ol>

      <h3 className='headline3'>第10条（保証適用の判断基準・範囲）</h3>
      <ol className='c-numList'>
        <li>
          当社は、本保証適用の判定については、当社が別途定める「保証対象部品一覧表」に含まれる部品を主な原因として生じたものか否かを基に行うものとします。
        </li>
        <li>
          不具合が発生した部品が複数であり、かつ、当該不具合が一連のものと判断される場合、主原因の部品が「保証対象部品一覧表」に記載されていないときには、本保証の適用はないものとします。
        </li>
      </ol>

      <h2 className='headline2'>第3章 雑則</h2>
      <h3 className='headline3'>第11条（個人情報）</h3>
      <ol className='c-numList'>
        <li>
          会員は、当社が、氏名、性別、生年月日、年齢、職業、メールアドレス、住所、電話番号、その他の車両に関する情報、その他本保証に関するサービス提供において知り得た会員に関する個人情報を次の各号の目的に利用することに同意します。
          <ol className='c-numList'>
            <p>1. 本保証にかかる各種案内の提供</p>
            <p>2. 本保証の内容、契約者情報の記録、管理、保存</p>
            <p>3. 本保証の適用の有無及び適用範囲の調査</p>
            <p>4. 車両の点検・整備修理に関する業務及びこれらに付随する業務の実施</p>
          </ol>
        </li>
        <li>
          会員は、本保証の適用に必要な限度において、当社が会員の個人情報を整備・修理工場に提供することに同意します。
        </li>
        <li>
          前二項に定めるほか、当社は、会員の個人情報について、当社プライバシーポリシー（https://buddica.direct/privacy/）に従って利用及び管理等するものとし、会員は、これに同意するものとします。
        </li>
      </ol>

      <h3 className='headline3'>第12条（免責）</h3>
      <p className='c-text'>
        本保証の提供にあたり当社が会員に対し負担する損害賠償額は、損害の事由が生じた時点から遡って過去1
        年間に当該会員が当社に対し支払った金員の総額（当該総額が0円の場合は1
        万円とします。）を上限とします。ただし、当社の故意又は重過失による場合を除きます。
      </p>

      <h3 className='headline3'>第13条（本規約の変更）</h3>
      <p className='c-text'>
        当社は、当社が必要と判断した場合、いつでも本規約の変更、追加、削除等（以下本条において「変更等」といいます）をすることができるものとします。当社は、本規約を変更等する場合には、相当期間を定めて、当該変更等の内容及び効力発生時期を、本プラットフォーム上での適宜の場所における掲載その他の適切な方法により事前に周知するものとし、変更等に係る規約は、当該効力発生時期が到来した時点でその効力が生じるものとします。
      </p>

      <h3 className='headline3'>第14条（管轄）</h3>
      <p className='c-text'>
        本規約および本保証に関連する訴訟が生じた場合、その訴額に応じて、東京地方裁判所または東京簡易裁判所を第一審の専属的合意管轄裁判所とします。
      </p>

      <p className='c-text c-text--right'>2024年1月20日 制定</p>
    </>
  );
};
