import { type FunctionComponent } from 'react';
import { type FragmentType, graphql, useFragment } from '~/gql/generated';
import styles from '~/styles/page/cars/carsShow/car-spec.module.css';

const blockClass = 'car-spec';

const CarSpecCarStockFragment = graphql(`
  fragment CarSpec_carStock on CarStock {
    id
    slideDoorSpecText
    carStockDetail {
      seatingCapacity
      displaySystematicColor
      transmissionTypeGroup
      engineDisplacement
      fuelTypeText
    }
  }
`);

type Props = {
  carStock: FragmentType<typeof CarSpecCarStockFragment>;
};

export const CarSpec: FunctionComponent<Props> = (props) => {
  const carStock = useFragment(CarSpecCarStockFragment, props.carStock);
  const carStockDetail = carStock.carStockDetail;
  const specs = [
  {
    title: '定員',
    text: carStockDetail?.seatingCapacity ? `${carStock.carStockDetail?.seatingCapacity}人乗り` : 'ー'
  },
  { title: '色', text: carStockDetail?.displaySystematicColor || 'ー' },
  { title: 'ミッション', text: carStockDetail?.transmissionTypeGroup || 'ー' },
  { title: '排気量', text: carStockDetail?.engineDisplacement ? `${carStockDetail?.engineDisplacement}cc` : 'ー' },
  { title: '燃料', text: carStockDetail?.fuelTypeText || 'ー' },
  { title: 'スライドドア', text: carStock.slideDoorSpecText || 'ー' }];

  return (
    <div className={styles[blockClass]}>
      <p className={styles[`${blockClass}__title`]}>スペック</p>
      <div className={styles[`${blockClass}__table`]}>
        {specs.map((spec, index) =>
        <div className={styles[`${blockClass}__table-row`]} key={index}>
            <p className={styles[`${blockClass}__table-title`]}>{spec.title}</p>
            <p className={styles[`${blockClass}__table-text`]}>{spec.text}</p>
          </div>
        )}
      </div>
    </div>);

};