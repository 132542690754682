import { useState, type FunctionComponent } from 'react';
import styles from '~/styles/page/cars/carsShow/car-equipment.module.css';
import { type FragmentType, graphql, useFragment } from '~/gql/generated';
import {
  type CarEquipment_CarStockFragment,
  CarStockOptionTypeEnum,
  MainCarStockEquipmentEnum,
  SubCarStockEquipmentGroupEnum } from
'~/gql/generated/graphql';

const blockClass = 'car-equipment';

const CarEquipmentCarStockFragment = graphql(`
  fragment CarEquipment_carStock on CarStock {
    id
    mainCarStockEquipments {
      key
      value
      text
    }
    subCarStockEquipments {
      active
      text
      group
    }
  }
`);

type EquipmentDetailGroupProps = {
  equipments: {
    active: boolean;
    text: string;
  }[];
  title: string;
};

const EquipmentDetailGroup: FunctionComponent<EquipmentDetailGroupProps> = ({ equipments, title }) => {
  return (
    <div className={styles[`${blockClass}__detail-group`]}>
      <p className={styles[`${blockClass}__detail-title`]}>{title}</p>
      <div className={styles[`${blockClass}__detail-list`]}>
        {equipments.map((equipment, index) =>
        <div className={styles[`${blockClass}__detail-item`]} key={index}>
            <img
            src={`/images/icons/${equipment.active ? 'ic_circle_orange.svg' : 'ic_cross.svg'}`}
            alt={equipment.text}
            className={styles[`${blockClass}__detail-item__icon`]} />

            <span className={!equipment.active ? styles[`${blockClass}__detail-item__text--disabled`] : ''}>
              {equipment.text}
            </span>
          </div>
        )}
      </div>
    </div>);

};

type EquipmentDetailProps = {
  carStock: CarEquipment_CarStockFragment;
};

const EquipmentDetail: FunctionComponent<EquipmentDetailProps> = ({ carStock }) => {
  const subCarStockEquipments = carStock.subCarStockEquipments || [];
  const normalEquipments = subCarStockEquipments.filter(
    (equipment) => equipment.group === SubCarStockEquipmentGroupEnum.Normal
  );
  const safetyEquipments = subCarStockEquipments.filter(
    (equipment) => equipment.group === SubCarStockEquipmentGroupEnum.Safety
  );
  const interiorEquipments = subCarStockEquipments.filter(
    (equipment) => equipment.group === SubCarStockEquipmentGroupEnum.Interior
  );
  const exteriorEquipments = subCarStockEquipments.filter(
    (equipment) => equipment.group === SubCarStockEquipmentGroupEnum.Exterior
  );

  return (
    <div className={styles[`${blockClass}__detail`]}>
      <EquipmentDetailGroup title='基本装備' equipments={normalEquipments} />
      <EquipmentDetailGroup title='安全装備' equipments={safetyEquipments} />
      <EquipmentDetailGroup title='内装' equipments={interiorEquipments} />
      <EquipmentDetailGroup title='外装' equipments={exteriorEquipments} />
    </div>);

};

type EquipmentItemProps = {
  icon: string;
  text: string;
};

const EquipmentItem: FunctionComponent<EquipmentItemProps> = (props) => {
  const { icon, text } = props;

  return (
    <div className={styles[`${blockClass}__item`]}>
      <img src={`/images/icons/${icon}`} alt={text} className={styles[`${blockClass}__item-icon`]} />
      <p className={styles[`${blockClass}__item-text`]}>{text}</p>
    </div>);

};

type MainEquipmentListProps = {
  carStock: CarEquipment_CarStockFragment;
};

const MainEquipmentList: FunctionComponent<MainEquipmentListProps> = ({ carStock }) => {
  const mainCarStockEquipments = carStock.mainCarStockEquipments || [];
  const driveRecorder = mainCarStockEquipments.find(
    (equipment) => equipment.key === MainCarStockEquipmentEnum.DriveRecorder
  );
  const etc = mainCarStockEquipments.find((equipment) => equipment.key === MainCarStockEquipmentEnum.Etc);
  const navigation = mainCarStockEquipments.find((equipment) => equipment.key === MainCarStockEquipmentEnum.Navigation);
  const tv = mainCarStockEquipments.find((equipment) => equipment.key === MainCarStockEquipmentEnum.Tv);
  const camera = mainCarStockEquipments.find((equipment) => equipment.key === MainCarStockEquipmentEnum.Camera);
  const carKey = mainCarStockEquipments.find((equipment) => equipment.key === MainCarStockEquipmentEnum.CarKey);
  const slideDoor = mainCarStockEquipments.find((equipment) => equipment.key === MainCarStockEquipmentEnum.SlideDoor);
  const brake = mainCarStockEquipments.find(
    (equipment) => equipment.key === MainCarStockEquipmentEnum.CollisionDamageReductionBrakes
  );
  const airconditioner = mainCarStockEquipments.find(
    (equipment) => equipment.key === MainCarStockEquipmentEnum.AutomaticAirConditioning
  );
  const nonSmokingCar = mainCarStockEquipments.find(
    (equipment) => equipment.key === MainCarStockEquipmentEnum.NonSmokingCar
  );

  const equipmentList = [
  {
    icon: driveRecorder?.value ? 'ic_gear_drareco_on.svg' : 'ic_gear_drareco_off.svg',
    text: driveRecorder?.text || 'ドラレコ'
  },
  { icon: etc?.value ? 'ic_gear_etc_on.svg' : 'ic_gear_etc_off.svg', text: etc?.text || 'ETC' },
  {
    icon: navigation?.value ? 'ic_gear_navigation_on.svg' : 'ic_gear_navigation_off.svg',
    text: navigation?.text || 'ナビ'
  },
  {
    icon:
    tv?.value === CarStockOptionTypeEnum.FullSegTv ?
    'ic_gear_tv_on_fullseg.svg' :
    tv?.value === CarStockOptionTypeEnum.OneSegTv ?
    'ic_gear_tv_on_oneseg.svg' :
    'ic_gear_tv_off.svg',
    text: tv?.text || 'TV'
  },
  {
    icon:
    camera?.value === CarStockOptionTypeEnum.SurroundCamera ?
    'ic_gear_camera_on_all.svg' :
    camera?.value === CarStockOptionTypeEnum.BackMonitor ?
    'ic_gear_camera_on_back.svg' :
    'ic_gear_camera_off.svg',
    text: camera?.text || 'カメラ'
  },
  {
    icon:
    carKey?.value === CarStockOptionTypeEnum.SmartKey ?
    'ic_gear_key_smart_on.svg' :
    carKey?.value === CarStockOptionTypeEnum.KeylessEntry ?
    'ic_gear_key_less_on.svg' :
    'ic_gear_key_off.svg',
    text: carKey?.text || '鍵'
  },
  {
    icon:
    slideDoor?.value === CarStockOptionTypeEnum.DualPowerSlideDoors ?
    'ic_gear_door_on_both.svg' :
    slideDoor?.value === CarStockOptionTypeEnum.DualSlideSinglePowerDoor ?
    'ic_gear_door_on_one.svg' :
    'ic_gear_door_off.svg',
    text: slideDoor?.text || '電動スライドドア'
  },
  {
    icon: brake?.value ? 'ic_gear_brake_on.svg' : 'ic_gear_brake_off.svg',
    text: brake?.text || '衝突被害軽減ブレーキ'
  },
  {
    icon: airconditioner?.value ? 'ic_gear_airconditioner_on.svg' : 'ic_gear_airconditioner_off.svg',
    text: airconditioner?.text || 'エアコン'
  },
  {
    icon: nonSmokingCar?.value ? 'ic_gear_nonsmoking_on.svg' : 'ic_gear_nonsmoking_off.svg',
    text: nonSmokingCar?.text || '禁煙車'
  }];


  return (
    <div className={styles[`${blockClass}__list`]}>
      <div className={styles[`${blockClass}__row`]}>
        {equipmentList.slice(0, 5).map((equipment, index) =>
        <EquipmentItem key={index} icon={equipment.icon} text={equipment.text} />
        )}
      </div>
      <div className={styles[`${blockClass}__row`]}>
        {equipmentList.slice(5, 10).map((equipment, index) =>
        <EquipmentItem key={index} icon={equipment.icon} text={equipment.text} />
        )}
      </div>
    </div>);

};

type Props = {
  carStock: FragmentType<typeof CarEquipmentCarStockFragment>;
};

export const CarEquipment: FunctionComponent<Props> = (props) => {
  const carStock = useFragment(CarEquipmentCarStockFragment, props.carStock);
  const [isDetailOpen, setIsDetailOpen] = useState(false);

  return (
    <div className={styles[blockClass]}>
      <p className={styles[`${blockClass}__title`]}>装備</p>
      <MainEquipmentList carStock={carStock} />
      {isDetailOpen && <EquipmentDetail carStock={carStock} />}
      <p
        className={`${styles[`${blockClass}__more`]} ${isDetailOpen && styles[`${blockClass}__more--active`]}`}
        onClick={() => setIsDetailOpen(!isDetailOpen)}>

        もっと見る
      </p>
    </div>);

};