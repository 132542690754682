import { Link } from '@remix-run/react';
import { useState, type FunctionComponent, type ReactNode } from 'react';
import { TrackableLink } from '~/components/parts';
import { Breadcrumb, MediaSection, ReviewSectionSelling } from '~/components/shared';
import styles from '~/styles/page/selling/selling-index.module.css';

const blockClass = 'selling';

type FapProps = {
  title: string;
  text: string | ReactNode;
};

const Faq: FunctionComponent<FapProps> = ({ title, text }) => {
  const [open, setOpen] = useState(false);

  return (
    <li className={styles[`${blockClass}__faq-item`]} onClick={() => setOpen(!open)}>
      <div className={styles[`${blockClass}__faq-head`]}>
        <h3 className={styles[`${blockClass}__faq-title`]}>{title}</h3>
      </div>
      {open &&
      <div className={styles[`${blockClass}__faq-body`]}>
          <p className={styles[`${blockClass}__faq-text`]}>{text}</p>
        </div>
      }
    </li>);

};

const LineBanner: FunctionComponent = () => {
  return (
    <p className={styles[`${blockClass}__line-banner`]}>
      <TrackableLink
        to='/add_line_friend?inflow_source_message=notifynew'
        analyticsData={{ eventName: 'click_registration_line_banner' }}>

        <img src={'/images/banners/line_friend.png'} alt='' />
      </TrackableLink>
    </p>);

};

export const SellingIndex: FunctionComponent = () => {
  const linkEventName = 'open_selling_form';
  const formLink = '/add_line_friend?inflow_source_message=selling';

  return (
    <>
      <Breadcrumb text='クルマを売りたい方' />
      <div className={styles[`${blockClass}__message`]}>
        <h1 className={styles[`${blockClass}__message-subTitle`]}>クルマを売りたい方</h1>
        <p className={styles[`${blockClass}__message-title`]}>
          <span>無料</span>愛車買取査定
        </p>
        <p className={styles[`${blockClass}__message-text`]}>
          査定だけでもOK！
          <br />
          フォーム入力と写真の送付だけで
          <br />
          プロが金額を見積もります！
        </p>
        <div className={styles[`${blockClass}__message-img`]}>
          <img src={'/images/selling/img_message.png'} alt='' />
        </div>
        <ul className={styles[`${blockClass}__message-list`]}>
          <li className={styles[`${blockClass}__message-item`]}>
            <div className={styles[`${blockClass}__message-itemIcon`]}>
              <img src={'/images/selling/message_icon_1.svg'} alt='' />
            </div>
            <p className={styles[`${blockClass}__message-itemText`]}>
              査定だけも
              <br />
              OK！
            </p>
          </li>

          <li className={styles[`${blockClass}__message-item`]}>
            <div className={styles[`${blockClass}__message-itemIcon`]}>
              <img src={'/images/selling/message_icon_2.svg'} alt='' />
            </div>
            <p className={styles[`${blockClass}__message-itemText`]}>
              電話
              <br />
              一切なし
            </p>
          </li>

          <li className={styles[`${blockClass}__message-item`]}>
            <div className={styles[`${blockClass}__message-itemIcon`]}>
              <img src={'/images/selling/message_icon_3.svg'} alt='' />
            </div>
            <p className={styles[`${blockClass}__message-itemText`]}>全国対応</p>
          </li>

          <li className={styles[`${blockClass}__message-item`]}>
            <div className={styles[`${blockClass}__message-itemIcon`]}>
              <img src={'/images/selling/message_icon_4.svg'} alt='' />
            </div>
            <p className={styles[`${blockClass}__message-itemText`]}>
              LINEで
              <br />
              相談
            </p>
          </li>
        </ul>
      </div>

      <h2 className={styles[`${blockClass}__title`]}>バディカダイレクト査定の特徴</h2>
      <p className={styles[`${blockClass}__titleCaption`]}>選んでいただける理由</p>

      <ul className={styles[`${blockClass}__feature`]}>
        <li className={styles[`${blockClass}__feature-item`]}>
          <h2 className={styles[`${blockClass}__feature-title`]}>
            <span className={styles[`${blockClass}__feature-icon`]}>
              <img src={'/images/selling/feature_icon_1.svg'} alt='' />
            </span>
            <span>電話は一切行いません</span>
          </h2>
          <p className={styles[`${blockClass}__feature-text`]}>
            希望がない限りラインでのやり取りで完結します。急ぎの場合であったり、電話ではないと確認できない事項があればお電話のご提案をしますが、こちらからいきなりお電話をかけ、貴重なお時間をいただくことはありません。
          </p>
        </li>
        <li className={styles[`${blockClass}__feature-item`]}>
          <h2 className={styles[`${blockClass}__feature-title`]}>
            <span className={styles[`${blockClass}__feature-icon`]}>
              <img src={'/images/selling/feature_icon_2.svg'} alt='' />
            </span>
            <span>
              最短10分、平均3時間
              <br />
              LINEで気軽に相談
            </span>
          </h2>
          <p className={styles[`${blockClass}__feature-text`]}>
            LINEで必要事項を送ってください。もし車検証があればかなり精度高い下取りのお値段をスタッフよりお返事します！お気軽に相談ください。
          </p>
        </li>
        <li className={styles[`${blockClass}__feature-item`]}>
          <h2 className={styles[`${blockClass}__feature-title`]}>
            <span className={styles[`${blockClass}__feature-icon`]}>
              <img src={'/images/selling/feature_icon_3.svg'} alt='' />
            </span>
            <span>
              LINEで契約まで完結！
              <br />
              車も引き取りに行きます
            </span>
          </h2>
          <p className={styles[`${blockClass}__feature-text`]}>
            車の傷のチェックやメーターのチェックなども全てバディカダイレクト上で行うため、オンラインで簡単に終わります。
            <br />
            買取が完了すれば車を輸送する専門業者が車を引き取りに行きます。
          </p>
        </li>
      </ul>

      <p className={styles[`${blockClass}__button`]}>
        <TrackableLink to={formLink} analyticsData={{ eventName: linkEventName }}>
          無料：愛車査定はこちら
        </TrackableLink>
      </p>

      <div className={styles[`${blockClass}__relieve`]}>
        <h2 className={styles[`${blockClass}__relieve-title`]}>こんな方も安心してください！</h2>
        <ul className={styles[`${blockClass}__relieve-list`]}>
          <li className={styles[`${blockClass}__relieve-item`]}>ローンが残ってる</li>
          <li className={styles[`${blockClass}__relieve-item`]}>事故歴がある</li>
          <li className={styles[`${blockClass}__relieve-item`]}>他社で値段がつかないと言われた</li>
        </ul>
        <p className={styles[`${blockClass}__relieve-text`]}>
          バディカダイレクトなら
          <br />
          すべて買取可能です{' '}
        </p>
        <div className={styles[`${blockClass}__relieve-img`]}>
          <img src={'/images/selling/img_relieve.png'} alt='' />
        </div>
      </div>

      <h2 className={styles[`${blockClass}__title`]}>愛車査定の流れ</h2>
      <p className={styles[`${blockClass}__titleCaption`]}>オンライン完結でカンタン査定</p>

      <ol id='selling-consultation-flow' className={styles[`${blockClass}__step`]}>
        <li className={styles[`${blockClass}__step-item`]}>
          <p className={styles[`${blockClass}__step-num`]}>1</p>
          <div className={styles[`${blockClass}__step-outer`]}>
            <div className={styles[`${blockClass}__step-img`]}>
              <img src={'/images/selling/icon_step_1.svg'} alt='' />
            </div>
            <div className={styles[`${blockClass}__step-inner`]}>
              <h3 className={styles[`${blockClass}__step-title`]}>LINEに登録する</h3>
              <p className={styles[`${blockClass}__step-text`]}>
                バディカダイレクトの公式LINEと連携します。
                <br />
                30秒で登録完了！
              </p>
            </div>
          </div>
          <p>
            <TrackableLink
              to={formLink}
              analyticsData={{ eventName: linkEventName }}
              className={styles[`${blockClass}__step-button`]}>

              <img src={'/images/icons/ic_line.svg'} className={styles[`${blockClass}__step-button-icon`]} alt='line' />
              <span className={styles[`${blockClass}__button-text`]}>まずはLINEに登録</span>
            </TrackableLink>
          </p>
        </li>

        <li className={styles[`${blockClass}__step-item`]}>
          <p className={styles[`${blockClass}__step-num`]}>2</p>
          <div className={styles[`${blockClass}__step-outer`]}>
            <div className={styles[`${blockClass}__step-img`]}>
              <img src={'/images/selling/icon_step_2.svg'} alt='' />
            </div>
            <div className={styles[`${blockClass}__step-inner`]}>
              <h3 className={styles[`${blockClass}__step-title`]}>愛車情報の入力</h3>
              <p className={styles[`${blockClass}__step-text`]}>
                愛車情報を入力してフォームから送信してください。
                <br />
                最初の時点では車検証がなくても大丈夫ですが、車検証があるとスピーディに査定が可能です。
              </p>
            </div>
          </div>
          <p>
            <TrackableLink
              to={formLink}
              analyticsData={{ eventName: linkEventName }}
              className={styles[`${blockClass}__step-button`]}>

              <span className={styles[`${blockClass}__button-text`]}>無料：愛車査定はこちら</span>
            </TrackableLink>
          </p>
        </li>

        <li className={styles[`${blockClass}__step-item`]}>
          <p className={styles[`${blockClass}__step-num`]}>3</p>
          <div className={styles[`${blockClass}__step-outer`]}>
            <div className={styles[`${blockClass}__step-img`]}>
              <img src={'/images/selling/icon_step_3.svg'} alt='' />
            </div>
            <div className={styles[`${blockClass}__step-inner`]}>
              <h3 className={styles[`${blockClass}__step-title`]}>写真をLINEで送付</h3>
              <p className={styles[`${blockClass}__step-text`]}>
                内装と外装、傷凹みの写真をLINEで送付してください。
                <br />
                外装/内装の写真点数が多いほど正確に買取査定ができます。
              </p>
            </div>
          </div>
          <div className={styles[`${blockClass}__step-row`]}>
            <p className={styles[`${blockClass}__step-subtitle`]}>送付写真のイメージ</p>
            <div className={styles[`${blockClass}__step-col`]}>
              <img src={'/images/selling/img_selling03_1.jpg'} alt='' width={'100%'} />
            </div>
            <div className={styles[`${blockClass}__step-col`]}>
              <img src={'/images/selling/img_selling03_2.jpg'} alt='' width={'100%'} />
            </div>
            <div className={styles[`${blockClass}__step-col`]}>
              <img src={'/images/selling/img_selling03_3.jpg'} alt='' width={'100%'} />
            </div>
            <div className={styles[`${blockClass}__step-col`]}>
              <img src={'/images/selling/img_selling03_4.jpg'} alt='' width={'100%'} />
            </div>
          </div>
          <p className={styles[`${blockClass}__step-link`]}>
            <a href='https://media.buddica.direct/contents/selling_manual.pdf' className=''>
              送付写真について詳しく見る
            </a>
          </p>
        </li>

        <li className={styles[`${blockClass}__step-item`]}>
          <p className={styles[`${blockClass}__step-num`]}>4</p>
          <div className={styles[`${blockClass}__step-outer`]}>
            <div className={styles[`${blockClass}__step-img`]}>
              <img src={'/images/selling/icon_step_4.svg'} alt='' />
            </div>
            <div className={styles[`${blockClass}__step-inner`]}>
              <h3 className={styles[`${blockClass}__step-title`]}>担当から価格をご連絡</h3>
              <p className={styles[`${blockClass}__step-text`]}>
                買取価格をご連絡します。概算ではなく実際のお支払いする金額をご連絡します。
              </p>
            </div>
          </div>
          <ul className={styles[`${blockClass}__step-notice`]}>
            <li>
              ※<span>提示金額は10日以内で手放した際の金額</span>になります
            </li>
            <li>
              ※<span>写真の送付がない場合、概算の査定額</span>になります
            </li>
          </ul>
        </li>

        <li className={styles[`${blockClass}__step-item`]}>
          <p className={styles[`${blockClass}__step-num`]}>5</p>
          <div className={styles[`${blockClass}__step-outer`]}>
            <div className={styles[`${blockClass}__step-img`]}>
              <img src={'/images/selling/icon_step_5.svg'} alt='' />
            </div>
            <div className={styles[`${blockClass}__step-inner`]}>
              <h3 className={styles[`${blockClass}__step-title`]}>契約</h3>
              <p className={styles[`${blockClass}__step-text`]}>
                金額に納得いただけたら契約に進みます。
                <br />
                もちろん、査定のみでも大丈夫です。
              </p>
            </div>
          </div>
        </li>
      </ol>

      <h2 className={styles[`${blockClass}__title`]}>ご契約後の流れ</h2>
      <p className={styles[`${blockClass}__titleCaption`]}>4STEPで簡単!</p>

      <ol id='selling-consultation-flow' className={styles[`${blockClass}__step`]}>
        <li className={styles[`${blockClass}__step-item`]}>
          <p className={styles[`${blockClass}__step-num`]}>1</p>
          <div className={styles[`${blockClass}__step-outer`]}>
            <div className={styles[`${blockClass}__step-img`]}>
              <img src={'/images/selling/icon_step_after_1.svg'} alt='' />
            </div>
            <div className={styles[`${blockClass}__step-inner`]}>
              <h3 className={styles[`${blockClass}__step-title`]}>引き取り日の調整</h3>
              <p className={styles[`${blockClass}__step-text`]}>バディカの担当者からLINEで日程調整の連絡をします。</p>
            </div>
          </div>
        </li>

        <li className={styles[`${blockClass}__step-item`]}>
          <p className={styles[`${blockClass}__step-num`]}>2</p>
          <div className={styles[`${blockClass}__step-outer`]}>
            <div className={styles[`${blockClass}__step-img`]}>
              <img src={'/images/selling/icon_step_after_2.svg'} alt='' />
            </div>
            <div className={styles[`${blockClass}__step-inner`]}>
              <h3 className={styles[`${blockClass}__step-title`]}>書類のやりとり</h3>
              <p className={styles[`${blockClass}__step-text`]}>
                契約後は郵送でのやりとりで完結します。
                <br />
                バディカから必要な書類を郵送します。
              </p>
            </div>
          </div>
        </li>

        <li className={styles[`${blockClass}__step-item`]}>
          <p className={styles[`${blockClass}__step-num`]}>3</p>
          <div className={styles[`${blockClass}__step-outer`]}>
            <div className={styles[`${blockClass}__step-img`]}>
              <img src={'/images/selling/icon_step_after_3.svg'} alt='' />
            </div>
            <div className={styles[`${blockClass}__step-inner`]}>
              <h3 className={styles[`${blockClass}__step-title`]}>愛車のお引き取り</h3>
              <p className={styles[`${blockClass}__step-text`]}>
                専門業者がお客様のご指定した場所まで車両を引き取りに行きます。当日は鍵の受け渡しと書類原本の確認で完了します。
              </p>
            </div>
          </div>
        </li>

        <li className={styles[`${blockClass}__step-item`]}>
          <p className={styles[`${blockClass}__step-num`]}>4</p>
          <div className={styles[`${blockClass}__step-outer`]}>
            <div className={styles[`${blockClass}__step-img`]}>
              <img src={'/images/selling/icon_step_after_4.svg'} alt='' />
            </div>
            <div className={styles[`${blockClass}__step-inner`]}>
              <h3 className={styles[`${blockClass}__step-title`]}>お振り込み</h3>
              <p className={styles[`${blockClass}__step-text`]}>
                車両と書類が店頭到着後、1週間以内に口座へお振込みします。
                <br />
                ※ローンの残債が残ってる場合は10日前後お日にちいただきます。
              </p>
            </div>
          </div>
        </li>
      </ol>

      <p className={styles[`${blockClass}__button`]}>
        <TrackableLink to={formLink} analyticsData={{ eventName: linkEventName }}>
          無料：愛車査定はこちら
        </TrackableLink>
      </p>

      <h2 className={styles[`${blockClass}__title`]}>バディカダイレクトのスタッフ</h2>
      <p className={styles[`${blockClass}__titleCaption`]}>あなたの愛車を査定するスタッフ達</p>
      <p className={styles[`${blockClass}__staff-caption`]}>
        私たちスタッフが、あなたの愛車を次のステージへ進むお手伝いをいたします。
      </p>
      <ul className={styles[`${blockClass}__staff`]}>
        <li className={styles[`${blockClass}__staff-item`]}>
          <Link to='/member/yusa'>
            <div className={styles[`${blockClass}__staff-img`]}>
              <img src={'/images/members/yusa/list.jpg'} alt='' width={'100%'} />
            </div>
            <div className={styles[`${blockClass}__staff-inner`]}>
              <p className={styles[`${blockClass}__staff-name`]}>由佐 朋也</p>
              <p className={styles[`${blockClass}__staff-text`]}>
                BUDDICAの創業メンバー。お客様の未来を見据え、全国のお客様に「愛車」の真価を伝える査定を行います。
              </p>
              <p className={styles[`${blockClass}__staff-link`]}>詳細を見る</p>
            </div>
          </Link>
        </li>

        <li className={styles[`${blockClass}__staff-item`]}>
          <Link to='/member/kurita'>
            <div className={styles[`${blockClass}__staff-img`]}>
              <img src={'/images/members/kurita/list.jpg'} alt='' width={'100%'} />
            </div>
            <div className={styles[`${blockClass}__staff-inner`]}>
              <p className={styles[`${blockClass}__staff-name`]}>栗田 政浩</p>
              <p className={styles[`${blockClass}__staff-text`]}>
                お客様の愛車が正当な評価を受けるよう、心を込めて査定をサポートします！
              </p>
              <p className={styles[`${blockClass}__staff-link`]}>詳しく見る</p>
            </div>
          </Link>
        </li>

        <li className={styles[`${blockClass}__staff-item`]}>
          <Link to='/member/hasegawa'>
            <div className={styles[`${blockClass}__staff-img`]}>
              <img src={'/images/members/hasegawa/list.jpg'} alt='' width={'100%'} />
            </div>
            <div className={styles[`${blockClass}__staff-inner`]}>
              <p className={styles[`${blockClass}__staff-name`]}>長谷川 奨</p>
              <p className={styles[`${blockClass}__staff-text`]}>
                お客様の理想を深く理解するため、熱心にお聞きします！そして、その理想に基づく最適な査定を心がけます。
              </p>
              <p className={styles[`${blockClass}__staff-link`]}>詳細を見る</p>
            </div>
          </Link>
        </li>

        <li className={styles[`${blockClass}__staff-item`]}>
          <Link to='/member/midorikawa'>
            <div className={styles[`${blockClass}__staff-img`]}>
              <img src={'/images/members/midorikawa/list.jpg'} alt='' width={'100%'} />
            </div>
            <div className={styles[`${blockClass}__staff-inner`]}>
              <p className={styles[`${blockClass}__staff-name`]}>緑川 凱久</p>
              <p className={styles[`${blockClass}__staff-text`]}>
                あなたの愛車の旅を長期にわたってサポートする覚悟で、どこよりも寄り添い、丁寧な査定を心がけます。
              </p>
              <p className={styles[`${blockClass}__staff-link`]}>詳細を見る</p>
            </div>
          </Link>
        </li>
      </ul>
      <p className={[styles[`${blockClass}__staff-button`], 'c-button is-secondary'].join(' ')}>
        <Link to='/members/'>もっと見る</Link>
      </p>

      <div className={styles[`${blockClass}__review`]}>
        <ReviewSectionSelling />
        <p className={[styles[`${blockClass}__review-button`], 'c-button is-secondary'].join(' ')}>
          <Link to='/reviews/'>もっと見る</Link>
        </p>
      </div>

      <p className={styles[`${blockClass}__button`]}>
        <TrackableLink to={formLink} analyticsData={{ eventName: linkEventName }}>
          無料：愛車査定はこちら
        </TrackableLink>
      </p>

      <LineBanner />

      <h2 className={styles[`${blockClass}__title`]}>よくあるご質問</h2>
      <p className={styles[`${blockClass}__titleCaption`]}>ご質問いただく内容をまとめました</p>

      <ul className={styles[`${blockClass}__faq`]}>
        <Faq
          title='なぜ高額買取ができるのですか？'
          text='バディカでは高額買取を実現する2つの独自の仕組みがあります。まず、全国のバディカ店舗やバディカダイレクトを通じて買い取った車両を直接販売することで、中間マージンを削減し、お客様への高価買取を実現しています。さらに、母体であるBUDDICAは、車の鑑定書の質の高さで中古車業者からの信頼が厚く、国内最大級の中古車業者間取引サイトで1位を獲得。この信頼により、相場価格での取引が可能となり、業者だけでなくユーザー様へも高価買取を提供できる体制を整えています。' />

        <Faq
          title='電話やしつこいLINEでの連絡はきませんか？'
          text='当社は新しいクルマの販売購入体験を目指しているので、断りのない営業電話などは行いません。' />

        <Faq
          title='中古車査定の申し込みに費用はかかりますか？'
          text='当社の中古車査定サービスは完全に無料でご利用いただけます。申し込みから査定結果の受け取りまで、一切の費用は発生いたしません。' />

        <Faq
          title='査定の際、どのような点をチェックされますか？'
          text='査定では、車の外装や内装の状態、走行距離、年式、メンテナンスの履歴、修復歴の有無など、多岐にわたるポイントをチェックします。これらの情報を総合的に評価して、最終的な査定額を算出いたします。' />

        <Faq
          title='査定結果に納得がいかない場合、交渉は可能ですか？'
          text='はい、査定結果にご不満がある場合は、お気軽にご相談ください。当社では、ご提示した車両売却価格について理由をしっかり説明させていただきます。' />

        <Faq
          title='査定後、必ず車を売却しなければなりませんか？'
          text='いいえ、査定後に車を売却するかどうかはお客様の自由です。査定結果を参考に、他社に見積もりを出していただいても構いません。ご自身で最適な判断をしていただくことをお勧めします。' />

        <Faq
          title='査定から売却までの流れを教えてください。'
          text='まずオンラインフォームで査定の申し込みをしていただきます。その後、専門の査定士がお車の写真を確認し、査定額をご提示します。査定額にご納得いただけた場合、必要書類の準備を経て、正式に車の売却手続きを行います。' />

        <Faq
          title='車検が切れてる車も売却できますか？'
          text='はい、大丈夫です。車検切れの場合は陸送会社がご指定の場所まで車両を引き取りに伺います。' />

        <Faq
          title='ローンが残ってる車両も売却できますか？'
          text='はい、可能です。買取金額の中から一括でバディカから返済します。
          金額が足りない場合は、必要な金額を振り込んでいただきます。' />


        <Faq
          title='事故修復歴ある車でも売却できますか？'
          text='もちろん可能です。年式や走行距離、事故歴がある車に関しても買取可能です。' />

      </ul>

      <MediaSection />

      <div className={styles[`${blockClass}__footerFiexd`]}>
        <p>
          <TrackableLink to={formLink} analyticsData={{ eventName: linkEventName }}>
            無料：愛車査定はこちら
          </TrackableLink>
        </p>
      </div>
    </>);

};