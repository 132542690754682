import { type FunctionComponent } from 'react';
import { Breadcrumb } from '~/components/shared';

export const CommerceLawIndex: FunctionComponent = () => {
  return (
    <>
      <Breadcrumb text='特定商取引法に基づく表記' />
      <h1 className='headline1'>特定商取引法に基づく表記</h1>
      <h3 className='headline3'>1. プラットフォームの名称</h3>
      <p className='c-text'>BUDDICA DIRECT（バディカダイレクト）</p>
      <h3 className='headline3'>2. 事業者の名称</h3>
      <p className='c-text'>BUDDICA・DIRECT株式会社</p>
      <h3 className='headline3'>3. 住所</h3>
      <p className='c-text'>東京都渋谷区渋谷二丁目14-13 岡崎ビル1008</p>
      <h3 className='headline3'>4. お問い合わせ窓口</h3>
      <p className='c-text'>電話番号：03-6427-9750</p>
      <h3 className='headline3'>5. 営業時間</h3>
      <p className='c-text'>10:00～19:00(水曜日定休)</p>
      <h3 className='headline3'>6. 代表者</h3>
      <p className='c-text'>代表取締役 中野優作</p>
      <h3 className='headline3'>7. 役務の対価</h3>
      <ol className='c-numList'>
        <li>
          掲載企業が負担する対価
          <br />
          掲載企業とユーザーとの間で、対象サービスに関する契約が成立した場合に限り、掲載企業は当社に対し、当該契約の代金に対して当社が定めた手数料率を乗じた金額（税別）とします。
        </li>
        <li>
          ユーザーが負担する対価
          <br />
          ユーザーが当社に対し支払う対価はありません。
        </li>
      </ol>
      <h3 className='headline3'>8. 役務の対価の支払時期</h3>
      <p className='c-text'>月末締め、翌月末払いとします。</p>
      <h3 className='headline3'>9. 役務の対価の支払方法</h3>
      <p className='c-text'>
        当社が別途指定する銀行口座に送金する方法によるものとします。送金手数料は、掲載企業の負担とします。
      </p>
      <h3 className='headline3'>10. その他お客様の負担する費用</h3>
      <p className='c-text'>
        インターネット利用のために必要となる通信料および接続料（金額は、お客様が契約した各事業者にお問い合わせください。）
      </p>
      <h3 className='headline3'>11. 役務の内容およびその提供時期</h3>
      <p className='c-text'>
        当社は、掲載企業およびユーザーに対し、掲載企業の提供するサービスに関する契約の成立の場を提供します。なお、当社は、当該契約の当事者ではなく、その履行には一切関与しません。当社は、登録手続の完了後直ちに役務を提供します。
      </p>
      <h3 className='headline3'>12. 返品・キャンセル</h3>
      <p className='c-text'>
        当社が提供する役務については、その特性上、返品・キャンセルには応じられせん。なお、掲載企業とユーザーとの間における契約に関する返品については、「返品に関する特約」をご参照ください。
      </p>
    </>
  );
};
