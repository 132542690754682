import { type FunctionComponent } from 'react';
import { TrackableLink } from '~/components/parts';
import styles from '~/styles/page/cars/carsIndex/car-special-detail.module.css';

const blockClass = 'car-specialDetail';

export const InitialSelectionView: FunctionComponent = () => {
  return (
    <>
      <div className={styles[`${blockClass}`]}>
        <div className={styles[`${blockClass}__main`]}>
          <img src='/images/pictures/special_car_initial_selection.jpg' alt='' />
        </div>
        <h1 className={styles[`${blockClass}__title`]}>初めてのクルマ選び</h1>
        <p className={styles[`${blockClass}__text`]}>予算から機能まで、あなたにぴったりの一台が見つかります</p>
      </div>

      <div className={styles[`${blockClass}__comment`]}>
        <img src='/images/pictures/img_initial_section.png' alt='' className={styles[`${blockClass}__comment-icon`]} />
        <p className={styles[`${blockClass}__comment-text`]}>初心者の方も安心してお乗りいただける車をご紹介します！</p>
      </div>

      <div className={styles[`${blockClass}__3step`]}>
        <h4 className={styles[`${blockClass}__3step-heading`]}>クルマを選ぶ前に知っておきたい！</h4>
        <p className={styles[`${blockClass}__3step-description`]}>
          初めてのクルマ選びの不安を解消するポイントを紹介したPDFをLINEにて配布中。
        </p>
        <div className={styles[`${blockClass}__3step-img`]}>
          <img src={'/images/guide/img_guide_3step.jpg'} alt='' width={'100%'} />
        </div>
        <p className={styles[`${blockClass}__3step-button`]}>
          <TrackableLink to='/add_line_friend' analyticsData={{ eventName: 'click_download_3step_document_banner' }}>
            LINEから資料をダウンロード
          </TrackableLink>
        </p>
      </div>
    </>);

};