import { useEffect, type FunctionComponent } from 'react';
import { type FragmentType, graphql, useFragment } from '~/gql/generated';
import styles from '~/styles/page/cars/carsShow/car-score.module.css';
import Chart from 'chart.js/auto';
import { Link } from '@remix-run/react';

const blockClass = 'car-score';

const CarScoreCarStockFragment = graphql(`
  fragment CarScore_carStock on CarStock {
    id
    vihicleConditionReportUrl
    carStockDetail {
      evaluationScore
      interiorEvaluationScore
    }
    promotion {
      id
      staffComment
      scores {
        axis
        score
      }
    }
  }
`);

type Props = {
  carStock: FragmentType<typeof CarScoreCarStockFragment>;
};

export const CarScore: FunctionComponent<Props> = (props) => {
  const carStock = useFragment(CarScoreCarStockFragment, props.carStock);

  useEffect(() => {
    const scores = carStock?.promotion?.scores || [];

    if (scores.length === 0) {
      return;
    }

    const ctx = document.getElementById('car-score') as HTMLCanvasElement;
    const scoreChart = new Chart(ctx, {
      type: 'radar',
      data: {
        labels: scores.map((score) => score.axis),
        datasets: [
        {
          data: scores.map((score) => score.score),
          backgroundColor: 'rgba(236, 104, 26, 0.70)',
          borderColor: '#ec681a',
          borderWidth: 1,
          fill: true,
          pointRadius: 0
        }]

      },
      options: {
        plugins: {
          legend: {
            display: false
          }
        },
        scales: {
          r: {
            min: 0,
            max: 5,
            ticks: {
              display: false,
              stepSize: 1
            },
            grid: {
              color: 'rgba(209, 209, 209, 0.4)'
            },
            angleLines: {
              display: false
            },
            pointLabels: {
              color: '#191311'
            }
          }
        },
        maintainAspectRatio: false
      }
    });

    return () => {
      scoreChart.destroy();
    };
  }, [carStock?.promotion?.scores]);

  return (
    <div className={styles[blockClass]}>
      <p className={styles[`${blockClass}__title`]}>BUDDICA スコア</p>
      <div className={styles[`${blockClass}__score`]}>
        <canvas id='car-score' width={100} height={100} />
      </div>
      <div className={styles[`${blockClass}__evaluation`]}>
        <div className={styles[`${blockClass}__evaluation-inner`]}>
          {carStock.carStockDetail?.evaluationScore &&
          <div className={styles[`${blockClass}__evaluation-item`]}>
              <p className={styles[`${blockClass}__evaluation-title`]}>車輌評価点</p>
              <p className={styles[`${blockClass}__evaluation-car`]}>
                <span className={styles['strong']}>{carStock.carStockDetail?.evaluationScore}</span>点
              </p>
            </div>
          }
          {carStock.carStockDetail?.interiorEvaluationScore &&
          <div className={styles[`${blockClass}__evaluation-item`]}>
              <p className={styles[`${blockClass}__evaluation-title`]}>内装評価</p>
              <p className={styles[`${blockClass}__evaluation-interior`]}>
                {carStock.carStockDetail?.interiorEvaluationScore}
              </p>
            </div>
          }
        </div>
        <Link to='/rating-point'>
          <img
            src='/images/icons/ic_question_link.svg'
            alt='question'
            width={'16'}
            className={styles[`${blockClass}__evaluation-icon`]} />

          評価について
        </Link>
      </div>
      <div className={styles['flex-center']}>
        <a href={carStock.vihicleConditionReportUrl} className={styles[`${blockClass}__button`]}>
          車両鑑定書を見る
        </a>
      </div>
      {carStock.promotion?.staffComment &&
      <p className={styles[`${blockClass}__staff-comment`]}>{carStock.promotion?.staffComment}</p>
      }
    </div>);

};