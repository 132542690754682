import { useState, type FunctionComponent, useEffect } from 'react';
import { Modal } from '~/components/shared';
import { sortTypeText } from '~/constants/sortTypeText';
import { CarStockSortTypeEnum } from '~/gql/generated/graphql';
import styles from '~/styles/page/cars/carsIndex/sort-item-modal.module.css';

const blockClass = 'sort-item-modal';

type Props = {
  opened: boolean;
  defaultSortType: CarStockSortTypeEnum;
  close: () => void;
  onSubmit: (sortType: CarStockSortTypeEnum) => void;
};

export const SortItemModal: FunctionComponent<Props> = (props) => {
  const { opened, defaultSortType, close, onSubmit } = props;
  const [sortType, setSortType] = useState<CarStockSortTypeEnum>(defaultSortType);

  useEffect(() => {
    setSortType(defaultSortType);
  }, [defaultSortType]);

  return (
    <Modal
      isOpen={opened}
      className={styles[blockClass]}
      onRequestClose={close}
      style={{
        overlay: {
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 201
        }
      }}>

      {opened &&
      <>
          <ul>
            <li className={styles[`${blockClass}__item`]} onClick={() => setSortType(CarStockSortTypeEnum.Newer)}>
              <p
              className={[
              styles[`${blockClass}__text`],
              sortType === CarStockSortTypeEnum.Newer ? styles['selected'] : ''].
              join(' ')}>

                {sortTypeText[CarStockSortTypeEnum.Newer]}
              </p>
            </li>
            <hr className={styles[`${blockClass}__hr`]} />
            <li className={styles[`${blockClass}__item`]} onClick={() => setSortType(CarStockSortTypeEnum.Popular)}>
              <p
              className={[
              styles[`${blockClass}__text`],
              sortType === CarStockSortTypeEnum.Popular ? styles['selected'] : ''].
              join(' ')}>

                {sortTypeText[CarStockSortTypeEnum.Popular]}
              </p>
            </li>
            <hr className={styles[`${blockClass}__hr`]} />
            <li className={styles[`${blockClass}__item`]} onClick={() => setSortType(CarStockSortTypeEnum.AmountLower)}>
              <p
              className={[
              styles[`${blockClass}__text`],
              sortType === CarStockSortTypeEnum.AmountLower ? styles['selected'] : ''].
              join(' ')}>

                {sortTypeText[CarStockSortTypeEnum.AmountLower]}
              </p>
            </li>
            <hr className={styles[`${blockClass}__hr`]} />
            <li
            className={styles[`${blockClass}__item`]}
            onClick={() => setSortType(CarStockSortTypeEnum.AmountHigher)}>

              <p
              className={[
              styles[`${blockClass}__text`],
              sortType === CarStockSortTypeEnum.AmountHigher ? styles['selected'] : ''].
              join(' ')}>

                {sortTypeText[CarStockSortTypeEnum.AmountHigher]}
              </p>
            </li>
            <hr className={styles[`${blockClass}__hr`]} />
            <li className={styles[`${blockClass}__item`]} onClick={() => setSortType(CarStockSortTypeEnum.YearNewer)}>
              <p
              className={[
              styles[`${blockClass}__text`],
              sortType === CarStockSortTypeEnum.YearNewer ? styles['selected'] : ''].
              join(' ')}>

                {sortTypeText[CarStockSortTypeEnum.YearNewer]}
              </p>
            </li>
            <hr className={styles[`${blockClass}__hr`]} />
            <li
            className={styles[`${blockClass}__item`]}
            onClick={() => setSortType(CarStockSortTypeEnum.MileageLower)}>

              <p
              className={[
              styles[`${blockClass}__text`],
              sortType === CarStockSortTypeEnum.MileageLower ? styles['selected'] : ''].
              join(' ')}>

                {sortTypeText[CarStockSortTypeEnum.MileageLower]}
              </p>
            </li>
            <button className={styles[`${blockClass}__button`]} onClick={() => onSubmit(sortType)}>
              保存
            </button>
          </ul>
        </>
      }
    </Modal>);

};