import { Link } from '@remix-run/react';
import { type FunctionComponent } from 'react';
import { type FragmentType, graphql, useFragment } from '~/gql/generated';
import styles from '~/styles/page/cars/carsShow/car-staff.module.css';

const blockClass = 'car-staff';

const CarStaffCarStockFragment = graphql(`
  fragment CarStaff_carStock on CarStock {
    id
    promotion {
      id
      postedBy {
        id
        lastName
        imageUrl
        comment
        slug
      }
    }
  }
`);

type Props = {
  carStock: FragmentType<typeof CarStaffCarStockFragment>;
};

export const CarStaff: FunctionComponent<Props> = (props) => {
  const carStock = useFragment(CarStaffCarStockFragment, props.carStock);
  const staff = carStock.promotion?.postedBy;

  if (!staff) {
    return <></>;
  }

  return (
    <div className={styles[blockClass]}>
      <img
        src={staff?.imageUrl || '/images/pictures/no_image.png'}
        alt='staff'
        className={styles[`${blockClass}__icon`]} />

      <div className={styles[`${blockClass}__inner`]}>
        <p className={styles[`${blockClass}__name`]}>
          <span>掲載スタッフ</span>
          {staff?.lastName}
        </p>
        <p className={styles[`${blockClass}__comment`]}>{staff?.comment}</p>
        {staff.slug &&
        <div style={{ textAlign: 'right' }}>
            <Link to={`/member/${staff.slug}`} className={styles[`${blockClass}__comment`]}>
              スタッフの詳細を見る
            </Link>
          </div>
        }
      </div>
    </div>);

};