import { useEffect, type FunctionComponent } from 'react';
import { CarImages } from './CarImages';
import { CarFeature } from './CarFeature';
import { CarScore } from './CarScore';
import { CarStaff } from './CarStaff';
import { CarSpec } from './CarSpec';
import { CarEquipment } from './CarEquipment';
import { CarWarranty } from './CarWarranty';
import { ReviewSection } from '~/components/shared';
import styles from '~/styles/page/cars/carsShow/cars-show.module.css';
import { CarsList } from './CarsList';
import { CarFooter } from './CarFooter';
import { type FragmentType, graphql, useFragment } from '~/gql/generated';
import { Link } from '@remix-run/react';
import { useAuth } from '~/hooks';
import { useMutation } from 'urql';

const blockClass = 'cars-show';

const CarShowCarStockFragment = graphql(`
  fragment CarsShow_carStock on CarStock {
    id
    ulid
    salesStatus
    makeName
    carModelName
    carModelYear
    searchBodyType
    oneClickContractCounts
    ...CarFeature_carStock
    ...CarFooter_carStock
    ...CarImages_carStock
    ...CarScore_carStock
    ...CarEquipment_carStock
    ...CarStaff_carStock
    ...CarSpec_carStock
  }
`);

const viewCarStockMutationDocument = graphql(`
  mutation ViewCarStock($input: ViewCarStockInput!) {
    viewCarStock(input: $input) {
      errors {
        message
      }
    }
  }
`);

type Props = {
  carStock: FragmentType<typeof CarShowCarStockFragment>;
};

export const CarsShow: FunctionComponent<Props> = (props) => {
  const carStock = useFragment(CarShowCarStockFragment, props.carStock);
  const viewCarStock = useMutation(viewCarStockMutationDocument)[1];
  const { user } = useAuth();
  const favoritedCarStocks = user?.favoritedCarStocks || [];
  const isFavorited = favoritedCarStocks.map((favoritedCarStock) => favoritedCarStock.id).includes(carStock.id);
  const carTitle = [carStock?.makeName, carStock?.carModelName, carStock?.carModelYear].
  filter((value) => !!value).
  join(' ');
  const title = `${carTitle}の中古車 - 車の通販ならバディカダイレクト`;

  useEffect(() => {
    if (!carStock.ulid) return;
    viewCarStock({ input: { carStockUlid: carStock.ulid } });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carStock.ulid]);

  return (
    <div className={styles[blockClass]}>
      {carStock.oneClickContractCounts > 0 &&
      <div className='grid place-items-center bg-attention p-2 text-sm font-bold'>
          {`${carStock.oneClickContractCounts}件`}商談中のおクルマ
        </div>
      }
      <div>
        {carStock.salesStatus === 'CLOSED' && <div className={styles[`${blockClass}__soldout`]}>SOLD OUT</div>}
        <CarImages carStock={carStock} isFavorited={isFavorited} title={title} />
        <CarFeature carStock={carStock} />
        <CarScore carStock={carStock} />
        <CarStaff carStock={carStock} />
        <CarSpec carStock={carStock} />
        <CarEquipment carStock={carStock} />
        <CarWarranty />
        <div className={styles[`${blockClass}__reviews`]}>
          <ReviewSection />
        </div>
        <div className={styles[`${blockClass}__banner`]}>
          <Link to='/guide/'>
            <img src={'/images/banners/for_beginner.png'} alt='for_beginners' width={'100%'} />
          </Link>
        </div>
        <CarFooter carStock={carStock} isFavorited={isFavorited} title={title} />
      </div>
      <CarsList carStockId={carStock.id} searchBodyType={carStock.searchBodyType} />
    </div>);

};