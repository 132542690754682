import { useEffect, type FunctionComponent } from 'react';
import { useMutation } from 'urql';
import { Loading } from '~/components/parts';
import { lineFriendUrl } from '~/constants/line';
import { graphql } from '~/gql/generated';
import { useAuth } from '~/hooks';
import { useClientEnvContext } from '~/providers';

const createInflowSourceMutationDocument = graphql(`
  mutation CreateInflowSource($input: CreateInflowSourceInput!) {
    createInflowSource(input: $input) {
      isSuccess
      errors {
        message
        path
      }
    }
  }
`);

export const InflowSourceNew: FunctionComponent = () => {
  const [, createInflowSource] = useMutation(createInflowSourceMutationDocument);
  const { environment } = useClientEnvContext();
  const { fetching, isAuthenticated } = useAuth();

  useEffect(() => {
    (async () => {
      if (fetching) return;

      const redirectUrl = lineFriendUrl(environment);
      if (!isAuthenticated) {
        window.location.replace(redirectUrl);
        return;
      }

      try {
        const queryParameter = window.location.search.slice(1);
        await createInflowSource({ input: { queryParameter } });
      } catch (e) {}

      window.location.replace(redirectUrl);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetching]);

  return <Loading isFull />;
};
