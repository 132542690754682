import { useState, type FunctionComponent, useRef, useEffect } from 'react';
import styles from '~/styles/page/cars/carsShow/car-images.module.css';
import { type FragmentType, graphql, useFragment } from '~/gql/generated';
import { CarMoviePopup, ShareModal } from '~/components/shared';
import { useDisclosure } from '@mantine/hooks';
import { useFavorite } from '~/hooks';
import { useErrorFlashContext, useLineFavoriteModalContext } from '~/providers';
import { isSsr } from '~/utils/ssr';
import Slider from 'react-slick';
import * as Sentry from '@sentry/remix';
import { StaffPopupBanner } from '~/components/shared/banners';

const blockClass = 'car-images';

const CarImagesCarStockFragment = graphql(`
  fragment CarImages_carStock on CarStock {
    id
    images {
      id
      url
      comment
    }
    video {
      id
      url
      thumbnailUrl
    }
    ...CarMoviePopup_carStock
  }
`);

type Image = {
  isVideo: boolean;
  videoUrl?: string | null;
  url?: string | null;
  comment?: string;
};

type Props = {
  carStock: FragmentType<typeof CarImagesCarStockFragment>;
  isFavorited: boolean;
  title: string;
};

export const CarImages: FunctionComponent<Props> = (props) => {
  const carStock = useFragment(CarImagesCarStockFragment, props.carStock);
  const { isFavorited, title } = props;
  const images: Image[] = [
  {
    videoUrl: carStock?.video?.url,
    url: carStock.images?.[0]?.url,
    isVideo: true
  },
  ...(carStock.images?.map((image) => ({
    isVideo: false,
    videoUrl: null,
    url: image.url,
    comment: image.comment || ''
  })) || [])].
  filter((image) => !image.isVideo && image.url || image.isVideo && image.videoUrl);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const { open: openfavoriteModal } = useLineFavoriteModalContext();
  const [shareModalOpened, { open: shareModalOpen, close: shareModalClose }] = useDisclosure(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlayVideo, setIsPlayVideo] = useState(true);
  const [isMute, setIsMute] = useState(true);
  const [showMoviePopup, setShowMoviePopup] = useState(false);
  const { favorite } = useFavorite();
  const { open: openErrorFlash } = useErrorFlashContext();
  const allCount = images.length;
  const currentUrl = !isSsr ? window.location.href : '';
  const [slider, setSlider] = useState<Slider | null>(null);
  const [swiping, setSwiping] = useState(false);
  const [showVideoLoading, setShowVideoLoading] = useState(true);
  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    beforeChange: () => setSwiping(true),
    afterChange: (index: number) => swipeImage(index)
  };
  const videoId = `car-show-video-${carStock.id}`;

  useEffect(() => {
    const targetElement = document.getElementById(videoId) as HTMLVideoElement;
    if (!targetElement) return;
    if (!targetElement.paused) {
      setShowVideoLoading(false);
    }

    targetElement.addEventListener('play', () => {
      setShowVideoLoading(false);
    });
  }, [videoId]);

  const handleFavorite = async () => {
    const { isAuthError, isSuccess } = await favorite({
      isFavorited,
      carStockId: carStock.id,
      eventFrom: 'car-show-top'
    });

    if (isAuthError) {
      openfavoriteModal();
      return;
    }

    if (!isSuccess) {
      openErrorFlash();
      return;
    }
  };

  const playVideoHandler = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.
        play()?.
        then(() => setIsPlayVideo(true))?.
        catch((e) => Sentry.captureException(e));
      } else {
        videoRef.current.pause();
        setIsPlayVideo(false);
      }
    }
  };

  const muteVideoHandler = () => {
    if (videoRef.current) {
      if (videoRef.current.muted) {
        videoRef.current.muted = false;
        setIsMute(false);
      } else {
        videoRef.current.muted = true;
        setIsMute(true);
      }
    }
  };

  const stopVideo = () => {
    if (!videoRef.current) return;
    videoRef.current.pause();
    setIsPlayVideo(false);
  };

  // メインで表示している画像がプレビューではみ出している場合、スクロールして表示する
  const scrollPreviewImagesToDisplay = (index: number) => {
    const previewImages = document.getElementById('preview-images');
    const previewImage = document.getElementById(`preview-image-${index}`);
    if (!previewImages || !previewImage) return;

    const previewImagesRect = previewImages.getBoundingClientRect();
    const previewImageRect = previewImage.getBoundingClientRect();
    if (previewImageRect.right > previewImagesRect.right) {
      previewImages.scrollLeft += previewImageRect.right - previewImagesRect.right;
    }
    if (previewImageRect.left < previewImagesRect.left) {
      previewImages.scrollLeft -= previewImagesRect.left - previewImageRect.left;
    }
  };

  const swipeImage = (index: number) => {
    setSwiping(false);
    if (currentImageIndex === 0) {
      stopVideo();
    }
    setCurrentImageIndex(index);
    scrollPreviewImagesToDisplay(index);
  };

  const selectImage = (index: number) => {
    slider?.slickGoTo(index);
    if (currentImageIndex === 0) {
      stopVideo();
    }
    setCurrentImageIndex(index);
  };

  return (
    <>
      <div className={styles[blockClass]}>
        <div className={styles[`${blockClass}__main`]}>
          <Slider ref={(slider) => setSlider(slider)} {...sliderSettings}>
            {images.map((image, index) =>
            <div className={styles[`${blockClass}__main-item`]} key={index}>
                {image.videoUrl ?
              <>
                    <video
                  id={videoId}
                  ref={videoRef}
                  autoPlay
                  muted={isMute}
                  playsInline
                  loop
                  src={image.videoUrl}
                  className={styles[`${blockClass}__main-video`]}
                  onClick={() => {
                    if (swiping) return;
                    setShowMoviePopup(true);
                    stopVideo();
                  }}
                  poster={carStock?.video?.thumbnailUrl || '/images/pictures/video_loading.png'} />

                    <div className={styles[`${blockClass}__main-video-controls`]}>
                      <div className={styles[`${blockClass}__main-video-controls-start`]} onClick={playVideoHandler}>
                        <img src={`/images/icons/${isPlayVideo ? 'ic_stop.svg' : 'ic_start.svg'}`} alt='play' />
                      </div>
                      <div className={styles[`${blockClass}__main-video-controls-mute`]} onClick={muteVideoHandler}>
                        <img src={`/images/icons/${isMute ? 'ic_sound_mute.svg' : 'ic_sound.svg'}`} alt='mute' />
                      </div>
                    </div>
                    {showVideoLoading &&
                <div className={styles[`${blockClass}__main-loading`]}>
                        <div className={styles[`${blockClass}__main-loader`]} />
                      </div>
                }
                  </> :

              <img
                src={image.url || '/images/pictures/no_image.png'}
                alt='main_image'
                className={styles[`${blockClass}__main-image`]} />

              }
                <p className={styles[`${blockClass}__main-number`]}>
                  {index + 1} / {allCount}
                </p>
                <div className={styles[`${blockClass}__main-icons`]}>
                  <div className={styles[`${blockClass}__main-icons__icon`]} onClick={shareModalOpen}>
                    <img src={'/images/icons/ic_share.svg'} alt='share' />
                  </div>
                  <div className={styles[`${blockClass}__main-icons__icon`]}>
                    <img
                    src={`/images/icons/${isFavorited ? 'ic_favorite_filled.svg' : 'ic_favorite.svg'}`}
                    alt='share'
                    onClick={handleFavorite} />

                  </div>
                </div>
                {image?.comment && <p className={styles[`${blockClass}__main-description`]}>{image.comment}</p>}
              </div>
            )}
          </Slider>
        </div>
        <div className={styles[`${blockClass}__images`]} id='preview-images'>
          {images.map((image, index) =>
          image.isVideo ?
          <div
            key={index}
            id={`preview-image-${index}`}
            className={[styles[`${blockClass}__images-wrapper`]].join(' ')}
            onClick={() => selectImage(index)}>

                <div
              className={[
              styles[`${blockClass}__images-overlay`],
              index === currentImageIndex ? styles['active'] : ''].
              join(' ')} />

                <img src={'/images/icons/ic_play.svg'} alt='play' className={styles[`${blockClass}__images-play`]} />
                <img
              src={image.url || '/images/pictures/no_image.png'}
              alt='sumbnail'
              className={styles[`${blockClass}__images-item`]} />

              </div> :

          <img
            src={image.url || '/images/pictures/no_image.png'}
            id={`preview-image-${index}`}
            alt='sumbnail'
            className={[
            styles[`${blockClass}__images-item`],
            index === currentImageIndex ? styles['active'] : ''].
            join(' ')}
            key={index}
            onClick={() => selectImage(index)} />


          )}
        </div>
        <StaffPopupBanner />
      </div>
      <ShareModal opened={shareModalOpened} close={shareModalClose} url={currentUrl} title={title} />
      {showMoviePopup &&
      <CarMoviePopup
        carStock={carStock}
        hideBackButton={true}
        hideNextButton={true}
        hideCarContent={true}
        closePopup={() => setShowMoviePopup(false)} />

      }
    </>);

};