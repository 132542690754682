import { type FunctionComponent } from 'react';
import styles from '~/styles/page/cars/carsShow/car-warranty.module.css';
import { Link } from '@remix-run/react';

const blockClass = 'car-warranty';

export const CarWarranty: FunctionComponent = () => {
  return (
    <div className={styles[blockClass]}>
      <p className={styles[`${blockClass}__title`]}>
        購入者全員対象！
        <br />
        バディカダイレクトの充実サポート
      </p>
      <ul className={styles[`${blockClass}__block`]}>
        <li className={styles[`${blockClass}__item`]}>
          <span>
            7日間
            <br />
            返品保証
          </span>
        </li>
        <li className={styles[`${blockClass}__item`]}>
          <span>
            初期不良
            <br />
            無償対応
          </span>
        </li>
        <li className={styles[`${blockClass}__item`]}>
          <span>
            1か月
            <br />
            点検
          </span>
        </li>
      </ul>

      <div className={styles[`${blockClass}__link`]}>
        <Link to='/support'>
          もっと見る
          <img src={'/images/icons/ic_arrow_right.svg'} alt='arrow-right' />
        </Link>
      </div>
    </div>);

};