import { type FunctionComponent } from 'react';
import styles from '~/styles/page/cars/carsIndex/car-special-detail.module.css';

const blockClass = 'car-specialDetail';

export const ResaleView: FunctionComponent = () => {
  return (
    <>
      <div className={styles[`${blockClass}`]}>
        <div className={styles[`${blockClass}__main`]}>
          <img src='/images/pictures/special_resale_car.jpg' alt='' />
        </div>
        <h1 className={styles[`${blockClass}__title`]}>リセールバリュー最強カー特集</h1>
        <p className={styles[`${blockClass}__text`]}>未来を見据えた投資！リセール価値を最大化する車</p>
      </div>
    </>);

};