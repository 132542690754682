import { type FunctionComponent } from 'react';

type Props = {
  title: string;
  morePath: string;
  items: Record<string, string>[];
  hiddenDate?: boolean;
};

export const CmsSection: FunctionComponent<Props> = (props) => {
  const { title, morePath, items, hiddenDate } = props;

  if (items.length === 0) {
    return <></>;
  }

  return (
    <div className='c-news'>
      <div className='c-news__header'>
        <p className='c-news__header-title'>{title}</p>
        <a href={morePath} className='c-news__header-link'>
          もっと見る
        </a>
      </div>

      <div className='c-news__list'>
        {items?.map((item, index) => (
          <div className='c-news__item' key={index}>
            <a href={item['url']} className='c-news__item-link'>
              <img src={item['image_path']} alt='' />
              <div className='c-news__item-body'>
                {!hiddenDate && <p className='c-news__item-date'>{item['date']}</p>}
                <p className='c-news__item-title'>{item['title']}</p>
                <p className='c-news__item-description'>{item['content']}</p>
                <p className='c-news__item-detail'>詳しくはこちら</p>
              </div>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};
