import { Link } from '@remix-run/react';
import { type FunctionComponent } from 'react';
import Slider from 'react-slick';
import styles from '~/styles/page/cars/carsIndex/first-view.module.css';

const blockClass = 'first-view';

export const FirstView: FunctionComponent = () => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    centerMode: true,
    centerPadding: '0px',
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dotsClass: `${blockClass}__slick-dots slick-dots`
  };

  const slides = [
  {
    image: '/images/slider/banner05.png',
    link: '/ai_nakano',
    alt: '中野愛作'
  },
  {
    image: '/images/slider/banner01.png',
    link: '/guide',
    alt: 'バディカダイレクトとは？'
  },
  {
    image: '/images/slider/banner02.png',
    link: '/request',
    alt: 'クルマのリクエストとは？'
  },
  {
    image: '/images/slider/banner03.png',
    link: '/selling',
    alt: '査定だけでもOK！詳しくはこちら'
  },
  {
    image: '/images/slider/banner04.png',
    link: 'https://buddica.direct/specials/3pdf_hp/',
    alt: '資料をダウンロードする',
    isExternal: true
  }];


  return (
    <div className={styles[blockClass]}>
      <Slider {...sliderSettings}>
        {slides.map((slide, index) =>
        <div className={styles[`${blockClass}__item`]} key={index}>
            {slide.isExternal ?
          <a href={slide.link} className={styles[`${blockClass}__item-link`]}>
                <img src={slide.image} alt={slide.alt} className={styles[`${blockClass}__item-image`]} />
              </a> :

          <Link to={slide.link} className={styles[`${blockClass}__item-link`]}>
                <img src={slide.image} alt={slide.alt} className={styles[`${blockClass}__item-image`]} />
              </Link>
          }
          </div>
        )}
      </Slider>
    </div>);

};