import { type FunctionComponent } from 'react';
import styles from '~/styles/page/cars/carsIndex/car-special-detail.module.css';

const blockClass = 'car-specialDetail';

export const KawaiiView: FunctionComponent = () => {
  return (
    <>
      <div className={styles[`${blockClass}`]}>
        <div className={styles[`${blockClass}__main`]}>
          <img src='/images/pictures/special_kawaii_car.png' alt='' />
        </div>
        <h1 className={styles[`${blockClass}__title`]}>チャーミングな愛車を見つけよう！かわいい車特集</h1>
        <p className={styles[`${blockClass}__text`]}>
          スタイルと機能、そしてかわいさも譲れないあなたへ！コンパクトで運転しやすく、かわいらしさも兼ね備えたおすすめの車をご紹介します。
        </p>
      </div>
    </>);

};