import liff from '@line/liff';
import * as Sentry from '@sentry/remix';
import { useEffect, useState, type FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { useClient, useMutation } from 'urql';
import { graphql } from '~/gql/generated';
import {
  DocumentRequestTypeEnum,
  type RequestDocumentMutation,
  type UpdateUserMutation } from
'~/gql/generated/graphql';
import { loginMutationDocument } from '~/gql/mutations';
import { convertZipCodeQueryDocument } from '~/gql/queries';
import { useAnalytics, useAuth } from '~/hooks';
import { useErrorFlashContext, useFlashContext, useLiffContext } from '~/providers';
import styles from '~/styles/page/documentPresent/document-present.module.css';
import { zipCodeRegex } from '~/utils';
import { errorHandle } from '~/utils/graphql/errorHandle';

const blockClass = 'document-present';

const requestDocumentMutationDocument = graphql(`
  mutation RequestDocument($input: RequestDocumentInput!) {
    requestDocument(input: $input) {
      isSuccess
      errors {
        message
        path
      }
    }
  }
`);

const updateUserMutationDocument = graphql(`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        id
        zipCode
      }
      errors {
        message
        path
      }
    }
  }
`);

export type Inputs = {
  requestType: DocumentRequestTypeEnum;
  zipCode: string;
};

export const DocumentPresentNew: FunctionComponent = () => {
  const { fetching, user } = useAuth();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors }
  } = useForm<Inputs>();
  const watchZipCode = watch('zipCode');
  const [address, setAddress] = useState<string>('');
  const [prefectureName, setPrefectureName] = useState<string>('');
  const [cityName, setCityName] = useState<string>('');
  const client = useClient();
  const login = useMutation(loginMutationDocument)[1];
  const requestDocument = useMutation(requestDocumentMutationDocument)[1];
  const updateUser = useMutation(updateUserMutationDocument)[1];
  const { open: openErrorFlash } = useErrorFlashContext();
  const [isInLiffClient, setIsInLiffClient] = useState(false);
  const { sendTrackEvent } = useAnalytics();
  const [submitting, setSubmitting] = useState(false);
  const { isMiniApp } = useLiffContext();
  const { open: openFlash } = useFlashContext();

  useEffect(() => {
    const isInClient = liff.isInClient();
    setIsInLiffClient(isInClient);
  }, []);

  useEffect(() => {
    (async () => {
      if (!watchZipCode?.match(zipCodeRegex)) {
        if (address) {
          setAddress('');
          setPrefectureName('');
          setCityName('');
        }
        return;
      }

      const result = await client.query(convertZipCodeQueryDocument, { zipCode: watchZipCode });
      const resultAddress = result.data?.convertZipCode?.address || '';
      const resultPrefectureName = result.data?.convertZipCode?.prefectureName || '';
      const resultCityName = result.data?.convertZipCode?.cityName || '';
      setAddress(resultAddress);
      setPrefectureName(resultPrefectureName);
      setCityName(resultCityName);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchZipCode]);

  useEffect(() => {
    if (fetching) {
      return;
    }

    setValue('zipCode', user?.zipCode || '');
  }, [setValue, fetching, user?.zipCode]);

  const sendMessages = async (data: Inputs) => {
    const text = [
    '【アンケート回答完了】',
    data.requestType === DocumentRequestTypeEnum.Buy ?
    'クルマを買いたい' :
    data.requestType === DocumentRequestTypeEnum.Sell ?
    'クルマを売りたい' :
    null,
    `住所：${data.zipCode} [${prefectureName}] ${cityName}`].

    filter((v) => !!v).
    join('\n');

    await liff.sendMessages([{ type: 'text', text }]);
  };

  const submit = async (data: Inputs) => {
    setSubmitting(true);

    try {
      if (!user) {
        const idToken = liff.getIDToken();
        if (!idToken) {
          openErrorFlash();
          setSubmitting(false);
          return;
        }
        await login({ input: { idToken: idToken, isCreateUser: true, fromMiniapp: isMiniApp } });
      }

      const result = await Promise.all([
      requestDocument({ input: { requestType: data.requestType } }),
      updateUser({ input: { zipCode: data.zipCode } })]
      );
      const { hasError: hasErrorRequestDocument } = errorHandle<RequestDocumentMutation['requestDocument']>(
        result[0].data?.requestDocument,
        result[0].error
      );
      const { hasError: hasErrorUpdateUser } = errorHandle<UpdateUserMutation['updateUser']>(
        result[1].data?.updateUser,
        result[1].error
      );

      if (hasErrorRequestDocument || hasErrorUpdateUser) {
        openErrorFlash();
        setSubmitting(false);
        return;
      }
    } catch (e) {
      openErrorFlash();
      setSubmitting(false);
    }

    try {
      await sendMessages(data);
    } catch (e) {
      Sentry.captureException(e);
    }

    sendTrackEvent('submit_document_present');

    if (isInLiffClient) {
      liff.closeWindow();
    } else {
      openFlash(<p>お問い合わせを受け付けました！</p>);
    }
  };

  return (
    <div className={styles[blockClass]}>
      <h1 className={styles[`${blockClass}__title`]}>LINE限定資料プレゼント</h1>
      <p className={styles[`${blockClass}__description`]}>
        簡単なアンケートのご協力お願い致します。
        <br />
        アンケート回答後、限定資料をプレゼントします。
      </p>

      <form onSubmit={handleSubmit(submit)}>
        <div className={styles[`${blockClass}__item`]}>
          <div className={styles[`${blockClass}__label`]}>
            <p className={styles[`${blockClass}__label-mark`]}>必須</p>
            <p className={styles[`${blockClass}__label-text`]}>クルマに関するご要望をお選びください</p>
          </div>
          <div className={styles[`${blockClass}__row`]}>
            <div style={{ marginRight: 24 }}>
              <input
                {...register('requestType', { required: true })}
                type='radio'
                id='buy'
                value={DocumentRequestTypeEnum.Buy} />

              <label htmlFor='buy'>クルマを買いたい</label>
            </div>
            <div style={{ marginRight: 24 }}>
              <input
                {...register('requestType', { required: true })}
                type='radio'
                id='sell'
                value={DocumentRequestTypeEnum.Sell} />

              <label htmlFor='sell'>クルマを売りたい</label>
            </div>
            <div>
              <input
                {...register('requestType', { required: true })}
                type='radio'
                id='none'
                value={DocumentRequestTypeEnum.None} />

              <label htmlFor='none'>希望なし</label>
            </div>
          </div>
          {errors.requestType && <p className={styles[`${blockClass}__error`]}>どれか1つを選択してください</p>}
        </div>
        <div className={styles[`${blockClass}__item`]}>
          <div className={styles[`${blockClass}__label`]}>
            <p className={styles[`${blockClass}__label-mark`]}>必須</p>
            <p className={styles[`${blockClass}__label-text`]}>郵便番号</p>
          </div>
          <div className={`${styles[`${blockClass}__row`]} ${styles[`${blockClass}__row--center`]}`}>
            <div className={styles[`${blockClass}__group`]}>
              <input
                {...register('zipCode', { required: true, pattern: zipCodeRegex })}
                className={styles[`${blockClass}__input`]}
                placeholder='2700233' />

              {errors.zipCode && <p className={styles[`${blockClass}__error`]}>郵便番号を7桁で入力してください</p>}
            </div>
            <a
              href='https://www.post.japanpost.jp/zipcode'
              className={styles[`${blockClass}__link`]}
              target='_blank'
              rel='noopener noreferrer'>

              <p className={styles[`${blockClass}__link-text`]}>わからない方はこちら</p>
              <img src={'/images/icons/ic_other_page.svg'} alt='other_page' />
            </a>
          </div>
        </div>
        <div className={`${styles[`${blockClass}__item`]} ${styles.margin42}`}>
          <p className={styles[`${blockClass}__label`]}>住所</p>
          {address ?
          <p className={styles[`${blockClass}__address-text`]}>{address}</p> :
          watchZipCode?.match(zipCodeRegex) ?
          <p className={styles[`${blockClass}__address-error`]}>郵便番号が正しくありません</p> :

          <p className={styles[`${blockClass}__address-error`]}>郵便番号を7桁で入力してください</p>
          }
        </div>
        <button type='submit' className={styles[`${blockClass}__button`]} disabled={submitting}>
          資料をゲットする
        </button>
      </form>
    </div>);

};