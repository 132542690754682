import { json, type LoaderFunctionArgs, type MetaFunction, type TypedResponse } from '@remix-run/cloudflare';
import { useLoaderData } from '@remix-run/react';
import { StandardError } from '~/components/errors';
import { Layout } from '~/components/layouts';
import { CarsSearch } from '~/components/pages';
import { amountTypeText, bodyTypeText } from '~/constants/searchTypeText';
import { graphql } from '~/gql/generated';
import {
  CarSalesStatusEnum,
  type CarStockSearchAmountTypeEnum,
  type CarStockSearchBodyTypeEnum,
  type CarStockSortTypeEnum,
  type CarStocksSearchPageQuery
} from '~/gql/generated/graphql';
import { ssrClient } from '~/graphql/client';
import { getEnvironmentVariables } from '~/infrastructure/environmentVariables';
import { loaderErrorHandle } from '~/infrastructure/errorHandle';
import { mergeMetaTags } from '~/utils/meta';

const FIRST_FETCH_CAR_STOCKS_COUNT = 18;

export const carStocksSearchPageQueryDocument = graphql(`
  query CarStocksSearchPage(
    $first: Int
    $after: String
    $salesStatus: CarSalesStatusEnum
    $bodyType: CarStockSearchBodyTypeEnum
    $amountType: CarStockSearchAmountTypeEnum
    $sortType: CarStockSortTypeEnum
    $carModelNameKeyword: String
    $hasVideo: Boolean
    $includeTotalCount: Boolean!
  ) {
    carStocks(
      first: $first
      after: $after
      salesStatus: $salesStatus
      bodyType: $bodyType
      amountType: $amountType
      sortType: $sortType
      carModelNameKeyword: $carModelNameKeyword
      hasVideo: $hasVideo
    ) {
      edges {
        node {
          ...CarsSearch_carStock
        }
      }
      totalCount @include(if: $includeTotalCount)
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`);

type CarStockType = NonNullable<
  NonNullable<NonNullable<CarStocksSearchPageQuery['carStocks']['edges']>[number]>['node']
>;

type Variables = {
  salesStatus: CarSalesStatusEnum;
  bodyType: CarStockSearchBodyTypeEnum;
  amountType: CarStockSearchAmountTypeEnum;
  sortType: CarStockSortTypeEnum;
  carModelNameKeyword: string | null;
  first: number;
  includeTotalCount: boolean;
};

export const variablesFromSearchParams = (searchParams: URLSearchParams, first: number): Variables => {
  const salesStatus =
    searchParams.get('status') === CarSalesStatusEnum.Closed ? CarSalesStatusEnum.Closed : CarSalesStatusEnum.OnSale;
  const bodyType = searchParams.get('bodyType') as CarStockSearchBodyTypeEnum;
  const amountType = searchParams.get('amountType') as CarStockSearchAmountTypeEnum;
  const sortType = searchParams.get('sortType') as CarStockSortTypeEnum;
  const carModelNameKeyword = searchParams.get('nameKeyword');

  return {
    salesStatus,
    bodyType,
    amountType,
    sortType,
    carModelNameKeyword,
    first,
    includeTotalCount: salesStatus === CarSalesStatusEnum.OnSale
  };
};

type LoadData = {
  carStocks: CarStockType[];
  totalCount?: number;
  hasNextPage: boolean;
  endCursor?: string | null;
  variables?: Variables;
  hasError: boolean;
};

export const loader = async ({ context, request }: LoaderFunctionArgs): Promise<TypedResponse<LoadData>> => {
  const env = getEnvironmentVariables(context);
  const url = new URL(request.url);
  const searchParams = url.searchParams;

  const variables = variablesFromSearchParams(searchParams, FIRST_FETCH_CAR_STOCKS_COUNT);
  const graphqlClient = ssrClient({ baseUrl: env.graphqlBaseUrl });
  const res = await graphqlClient.query(carStocksSearchPageQueryDocument, variables);
  const hasError = !!res.error;

  if (hasError) {
    throw loaderErrorHandle(res.operation.context.url, res.error!);
  }

  return json<LoadData>({
    carStocks: (res.data?.carStocks?.edges?.map((edge) => edge?.node).filter((node) => !!node) || []) as CarStockType[],
    totalCount: res.data?.carStocks.totalCount,
    hasNextPage: res.data?.carStocks?.pageInfo?.hasNextPage || false,
    endCursor: res.data?.carStocks?.pageInfo?.endCursor,
    variables: variables,
    hasError: hasError
  });
};

export const meta: MetaFunction<typeof loader> = ({ data, matches }) => {
  const variables = data?.variables;
  const isScoped = !!variables?.bodyType || !!variables?.amountType;
  if (!isScoped) {
    return matches.flatMap((match) => match.meta ?? []);
  }

  const scopedText = variables?.bodyType ? bodyTypeText[variables?.bodyType] : amountTypeText[variables?.amountType];
  const title = `バディカダイレクトの${scopedText}中古車一覧 - 車の通販ならバディカダイレクト`;
  const metaTags = [{ title: title }, { name: 'title', content: title }, { property: 'og:title', content: title }];

  return mergeMetaTags({ metaTags, matches });
};

export default function SearchRoute() {
  const data = useLoaderData<typeof loader>();

  if (data.hasError) {
    return (
      <Layout>
        <StandardError />
      </Layout>
    );
  }

  return (
    <Layout>
      <CarsSearch
        carStocks={data.carStocks}
        totalCount={data.totalCount}
        firstHasNextCarStocks={data.hasNextPage}
        firstEndCursor={data.endCursor}
      />
    </Layout>
  );
}
