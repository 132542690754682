import {
  json,
  type HeadersArgs,
  type HeadersFunction,
  type LoaderFunctionArgs,
  type MetaFunction,
  type TypedResponse
} from '@remix-run/cloudflare';
import { useLoaderData } from '@remix-run/react';
import { NotFound, StandardError } from '~/components/errors';
import { Layout } from '~/components/layouts';
import { SpecialShow } from '~/components/pages';
import { graphql } from '~/gql/generated';
import { CarSalesStatusEnum, TagGroupEnum, type SpecialShowPageQuery } from '~/gql/generated/graphql';
import { ssrClient } from '~/graphql/client';
import { getEnvironmentVariables } from '~/infrastructure/environmentVariables';
import { loaderErrorHandle } from '~/infrastructure/errorHandle';
import { noCache } from '~/infrastructure/header';
import { mergeMetaTags } from '~/utils/meta';

const FIRST_FETCH_CAR_STOCKS_COUNT = 18;

export const specialShowPageQueryDocument = graphql(`
  query SpecialShowPage($first: Int, $after: String, $salesStatus: CarSalesStatusEnum, $tagGroup: TagGroupEnum) {
    carStocks(first: $first, after: $after, salesStatus: $salesStatus, tagGroup: $tagGroup) {
      edges {
        node {
          id
          landTransportCosts {
            cost
            prefectureCode
          }
          ...SpecialShow_carStock
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`);

export type TagGroupSlug = 'initial-selection' | 'resale' | 'outdoor' | 'family' | 'kawaii';
export const pathToTagGroup = {
  'initial-selection': TagGroupEnum.InitialSelection,
  resale: TagGroupEnum.Resale,
  outdoor: TagGroupEnum.Outdoor,
  family: TagGroupEnum.Family,
  kawaii: TagGroupEnum.Kawaii
};
export const pathToTitle = {
  'initial-selection': '初めてのクルマ選び特集',
  resale: 'リセール最強特集',
  outdoor: 'アウトドア特集',
  family: 'ファミリーにおすすめ特集',
  kawaii: 'かわいい車特集'
};

type CarStockType = NonNullable<NonNullable<NonNullable<SpecialShowPageQuery['carStocks']['edges']>[number]>['node']>;

type LoadData = {
  carStocks: CarStockType[];
  hasNextPage: boolean;
  endCursor?: string | null;
  hasError: boolean;
  notFound: boolean;
};

export const loader = async ({ context, params }: LoaderFunctionArgs): Promise<TypedResponse<LoadData>> => {
  const tagGroupSlug = (params.slug || '') as TagGroupSlug;
  const tagGroup = pathToTagGroup[tagGroupSlug];
  if (!tagGroup) {
    throw new Response('NOT FOUND', { status: 404 });
  }

  const env = getEnvironmentVariables(context);
  const variables = { salesStatus: CarSalesStatusEnum.OnSale, first: FIRST_FETCH_CAR_STOCKS_COUNT, tagGroup: tagGroup };
  const graphqlClient = ssrClient({ baseUrl: env.graphqlBaseUrl });
  const res = await graphqlClient.query(specialShowPageQueryDocument, variables);
  const hasError = !!res.error;

  if (hasError) {
    throw loaderErrorHandle(res.operation.context.url, res.error!);
  }

  return json<LoadData>(
    {
      carStocks: (res.data?.carStocks?.edges?.map((edge) => edge?.node).filter((node) => !!node) ||
        []) as CarStockType[],
      hasNextPage: res.data?.carStocks?.pageInfo?.hasNextPage || false,
      endCursor: res.data?.carStocks?.pageInfo?.endCursor,
      hasError: !!res.error,
      notFound: false
    },
    {
      headers: { 'Cache-Control': hasError ? noCache : 'public, max-age=0, s-maxage=60' }
    }
  );
};

export const headers: HeadersFunction = ({ parentHeaders, loaderHeaders }: HeadersArgs) => {
  const loaderCacheControl = loaderHeaders.get('cache-control');

  return {
    ...parentHeaders,
    'Cache-Control': loaderCacheControl || 'public, max-age=0, s-maxage=60'
  };
};

export const meta: MetaFunction = ({ matches, params }) => {
  const tagGroupSlug = (params.slug || '') as TagGroupSlug;
  const titlePrefix = pathToTitle[tagGroupSlug];
  const title = `${titlePrefix} | 車の通販バディカダイレクト - LINEで相談`;
  const metaTags = [{ title: title }, { name: 'title', content: title }, { property: 'og:title', content: title }];

  return mergeMetaTags({ metaTags, matches });
};

export default function SpecialsShowRoute() {
  const data = useLoaderData<typeof loader>();

  if (data.hasError) {
    return (
      <Layout>
        <StandardError />
      </Layout>
    );
  }

  if (data.notFound) {
    return (
      <Layout>
        <NotFound />
      </Layout>
    );
  }

  return (
    <Layout>
      <SpecialShow
        carStocks={data.carStocks}
        firstHasNextCarStocks={data.hasNextPage}
        firstEndCursor={data.endCursor}
      />
    </Layout>
  );
}
