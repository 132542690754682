import dayjs from 'dayjs';
import { useEffect, type FunctionComponent } from 'react';

import { Modal } from '~/components/shared';
import { graphql, useFragment, type FragmentType } from '~/gql/generated';
import { UserReviewReviewTypeEnum } from '~/gql/generated/graphql';
import styles from '~/styles/page/reviews/reviews-modal.module.css';

const blockClass = 'reviews-modal';

const ReviewModalUserReviewFragment = graphql(`
  fragment ReviewModal_userReview on UserReview {
    id
    reviewType
    satisfactionRate
    closedMonth
    makeName
    carModelName
    title
    conveniencePoint
    worryPoint
    reason
    staffComment
    staff {
      id
      fullName
      imageUrl
    }
  }
`);

type Props = {
  userReview?: FragmentType<typeof ReviewModalUserReviewFragment>;
  opened: boolean;
  close: () => void;
};

export const ReviewsModal: FunctionComponent<Props> = (props) => {
  const userReview = useFragment(ReviewModalUserReviewFragment, props.userReview);
  const { opened, close } = props;

  useEffect(() => {
    const bodyElement = document.querySelector('body');
    if (!bodyElement) return;

    bodyElement.style.overflow = opened ? 'hidden' : 'visible';

    return () => {
      bodyElement.style.overflow = 'visible';
    };
  }, [opened]);

  return (
    <Modal
      isOpen={opened}
      className={styles[blockClass]}
      onRequestClose={close}
      style={{
        overlay: {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          zIndex: 201
        }
      }}>

      {opened && userReview &&
      <>
          <div className={styles[`${blockClass}__close`]} onClick={close}>
            <img src={'/images/icons/ic_close2.svg'} alt='close' />
          </div>
          <div className={styles[`${blockClass}__wrap`]}>
            <div className={styles[`${blockClass}__item`]}>
              {userReview.satisfactionRate &&
            <div className={styles[`${blockClass}__item-star`]}>
                  {Array.from({ length: userReview.satisfactionRate }).map((_, index) =>
              <img src={'/images/icons/ic_star.svg'} alt='star' key={index} />
              )}
                </div>
            }

              {userReview.title && <h2 className={styles[`${blockClass}__item-title`]}>{userReview.title}</h2>}

              <div className={styles[`${blockClass}__item-head`]}>
                {userReview.closedMonth &&
              <p className={styles[`${blockClass}__item-date`]}>
                    {dayjs(userReview.closedMonth).format('YYYY年MM月')}
                  </p>
              }
                <p className={styles[`${blockClass}__item-car`]}>
                  {[
                userReview.makeName,
                userReview.carModelName,
                userReview.reviewType === UserReviewReviewTypeEnum.Buying ? '購入' : '売却'].

                filter(Boolean).
                join(' ')}
                </p>
              </div>

              <div className={styles[`${blockClass}__item-description`]}>
                {userReview.conveniencePoint &&
              <p className={styles[`${blockClass}__item-text`]}>
                    {userReview.conveniencePoint.split(/\n/).map((text, index) =>
                <p key={index}>{text}</p>
                )}
                  </p>
              }
                {userReview.worryPoint &&
              <div>
                    <h4 className={styles[`${blockClass}__item-heading`]}>不安を感じた点・改善した方がよい点</h4>
                    <p className={styles[`${blockClass}__item-text`]}>
                      {userReview.worryPoint.split(/\n/).map((text, index) =>
                  <p key={index}>{text}</p>
                  )}
                    </p>
                  </div>
              }
                {userReview.reason &&
              <div>
                    <h4 className={styles[`${blockClass}__item-heading`]}>
                      バディカダイレクトで{userReview.reviewType === UserReviewReviewTypeEnum.Buying ? '購入' : '売却'}
                      を決めた理由
                    </h4>
                    <p className={styles[`${blockClass}__item-text`]}>
                      {userReview.reason.split(/\n/).map((text, index) =>
                  <p key={index}>{text}</p>
                  )}
                    </p>
                  </div>
              }
              </div>

              {userReview.staffComment &&
            <div className={styles[`${blockClass}__comment`]}>
                  <p className={styles[`${blockClass}__comment-title`]}>担当者からのコメント</p>
                  <p className={styles[`${blockClass}__comment-text`]}>
                    {userReview.staffComment.split(/\n/).map((text, index) =>
                <p key={index}>{text}</p>
                )}
                  </p>
                  {userReview.staff &&
              <p className={styles[`${blockClass}__comment-staff`]}>
                      {userReview.staff.imageUrl &&
                <img src={userReview.staff.imageUrl} alt={userReview.staff.fullName} />
                }
                      {userReview.staff.fullName}
                    </p>
              }
                </div>
            }
            </div>
          </div>
        </>
      }
    </Modal>);

};