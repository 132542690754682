import { Link } from '@remix-run/react';
import { type FunctionComponent } from 'react';

import styles from '~/styles/page/cars/carsIndex/specials-image.module.css';

const blockClass = 'specials-image';

export const SpecialImagesSection: FunctionComponent = () => {
  const specials = [
  {
    image: '/images/pictures/special_car_initial_selection.jpg',
    link: '/car-specials/initial-selection',
    alt: '初めてのクルマ選び'
  },
  {
    image: '/images/pictures/special_kawaii_car.png',
    link: '/car-specials/kawaii',
    alt: 'かわいい車特集'
  },
  {
    image: '/images/pictures/special_family_car.jpg',
    link: '/car-specials/family',
    alt: 'ファミリー向け車大集合！'
  },
  {
    image: '/images/pictures/special_outdoor_car.jpg',
    link: '/car-specials/outdoor',
    alt: 'アウトドアに最適な車特集'
  },
  {
    image: '/images/pictures/special_resale_car.jpg',
    link: '/car-specials/resale',
    alt: 'リセールバリュー最強カー特集'
  }];


  return (
    <div className={styles[blockClass]}>
      <p className={styles[`${blockClass}__header-title`]}>特集から見つける</p>
      <div className={styles[`${blockClass}__list`]}>
        {specials.map((special, index) =>
        <div className={styles[`${blockClass}__item`]} key={index}>
            <Link to={special.link} className={styles[`${blockClass}__item-link`]}>
              <img src={special.image} alt={special.alt} className={styles[`${blockClass}__item-image`]} />
            </Link>
          </div>
        )}
      </div>
    </div>);

};