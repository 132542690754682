import { Link } from '@remix-run/react';
import { type FunctionComponent } from 'react';
import { Loading } from '~/components/parts';
import { SmallCarCard } from '~/components/shared';
import { graphql, useFragment, type FragmentType } from '~/gql/generated';
import { useAuth } from '~/hooks';
import styles from '~/styles/page/cars/carsIndex/cars-section.module.css';

const blockClass = 'cars-section';

const CarsSectionCarStocksFragment = graphql(`
  fragment CarsSection_carStock on CarStock {
    id
    ...SmallCarCard_carStock
  }
`);

type Props = {
  title: string;
  morePath: string;
  carStocks: readonly FragmentType<typeof CarsSectionCarStocksFragment>[];
  isLoading?: boolean;
};
export const CarsSection: FunctionComponent<Props> = (props) => {
  const { title, morePath, isLoading } = props;
  const carStocks = useFragment(CarsSectionCarStocksFragment, props.carStocks);
  const { user } = useAuth();
  const favoritedCarStockIds = user?.favoritedCarStocks?.map((favoritedCarStock) => favoritedCarStock.id) || [];

  return (
    <div className={styles[blockClass]}>
      <div className={styles[`${blockClass}__header`]}>
        <p className={styles[`${blockClass}__header-title`]}>{title}</p>
        <Link to={morePath} className={styles[`${blockClass}__header-link`]}>
          もっと見る
        </Link>
      </div>
      {!isLoading ?
      <div className={styles[`${blockClass}__list`]}>
          {carStocks.map((carStock) =>
        <div key={carStock.id} className={styles[`${blockClass}__list-item`]}>
              <SmallCarCard
            carStock={carStock}
            isFavorited={favoritedCarStockIds.includes(carStock!.id)}
            imageHeight={164} />

            </div>
        )}
        </div> :

      <div style={{ transform: 'scale(0.5)' }}>
          <Loading />
        </div>
      }
    </div>);

};