import { type FunctionComponent } from 'react';
import { Breadcrumb } from '~/components/shared';

export const RatingPointIndex: FunctionComponent = () => {
  return (
    <>
      <Breadcrumb text='車両状態鑑定書の見方' />
      <h1 className='headline1'>車両状態鑑定書の見方</h1>
      <p className='c-text'>
        評価点については、オートサーバーの検査基準に基づいております。
        <br />
        鑑定項目は外装、内装、年式、走行距離などの車両の総合的な評価を点数としてます。
      </p>
      <h2 className='headline2'>車輌評価点について</h2>
      <p className='c-text c-text--center'>
        <img src={'/images/ratingpoint/img_1.svg'} alt='' />
      </p>
      <ul className='c-table c-contents'>
        <li className='c-table__item'>
          <h4 className='c-table__title is-sizeS'>S点</h4>
          <div className='c-table__body'>
            <p>新車登録後12ヶ月未満、走行距離1千km以下で、内外装にダメージがない、とても綺麗な状態です。</p>
          </div>
        </li>
        <li className='c-table__item'>
          <h4 className='c-table__title is-sizeS'>6点</h4>
          <div className='c-table__body'>
            <p>新車登録後36ヶ月未満、走行距離3万km以下で、内外装にダメージがほとんどない、とても綺麗な状態です。</p>
          </div>
        </li>
        <li className='c-table__item'>
          <h4 className='c-table__title is-sizeS'>5点</h4>
          <div className='c-table__body'>
            <p>新車登録後60ヶ月未満、走行距離5万km以下で、内外装にダメージがほとんどない、良好な状態です。</p>
          </div>
        </li>
        <li className='c-table__item'>
          <h4 className='c-table__title is-sizeS'>4.5点</h4>
          <div className='c-table__body'>
            <p>内外装に目立たない軽微なキズ、ヘコミが少し認められますが、良好な状態です。</p>
          </div>
        </li>
        <li className='c-table__item'>
          <h4 className='c-table__title is-sizeS'>4点</h4>
          <div className='c-table__body'>
            <p>内外装に目立たない多少のキズ、ヘコミが認められる状態です。</p>
          </div>
        </li>
        <li className='c-table__item'>
          <h4 className='c-table__title is-sizeS'>3.5点</h4>
          <div className='c-table__body'>
            <p>内外装に多少のキズ、ヘコミが認められる状態です。</p>
          </div>
        </li>
        <li className='c-table__item'>
          <h4 className='c-table__title is-sizeS'>3点</h4>
          <div className='c-table__body'>
            <p>内外装に目に留まるキズ、ヘコミが認められる状態です。</p>
          </div>
        </li>
        <li className='c-table__item'>
          <h4 className='c-table__title is-sizeS'>2点</h4>
          <div className='c-table__body'>
            <p>冠水車やボディに腐食が大きくあるもの</p>
          </div>
        </li>
        <li className='c-table__item'>
          <h4 className='c-table__title is-sizeS'>1点</h4>
          <div className='c-table__body'>
            <p>改造車【規格外パーツが取り付けられており、車検が通らないもの】</p>
          </div>
        </li>
        <li className='c-table__item'>
          <h4 className='c-table__title is-sizeS'>R点</h4>
          <div className='c-table__body'>
            <p>修復歴はありますが、走行に支障がない状態です。</p>
          </div>
        </li>
      </ul>

      <h2 className='headline2'>内装評価点について</h2>
      <ul className='c-table c-contents'>
        <li className='c-table__item'>
          <h4 className='c-table__title is-sizeS'>A</h4>
          <div className='c-table__body'>
            <p>目立たない軽微な汚れはありますが、良好な状態です。</p>
          </div>
        </li>
        <li className='c-table__item'>
          <h4 className='c-table__title is-sizeS'>B</h4>
          <div className='c-table__body'>
            <p>通常使用による、経年劣化で多少のスレや汚れがあります。</p>
          </div>
        </li>
        <li className='c-table__item'>
          <h4 className='c-table__title is-sizeS'>C</h4>
          <div className='c-table__body'>
            <p>内装を気にしない方向けのお車です。【臭いや目立つ汚れスレあります】</p>
          </div>
        </li>
      </ul>
      <h2 className='headline2'>傷のレベルについて</h2>
      <p className='c-text c-text--center'>
        <img src={'/images/ratingpoint/img_2.jpg'} alt='' width={400} />
      </p>
      <h3 className='headline3'>キズ</h3>
      <ul className='c-table c-contents'>
        <li className='c-table__item'>
          <h4 className='c-table__title is-sizeS'>A1</h4>
          <div className='c-table__body'>
            <p>約10cm程度の傷</p>
          </div>
        </li>
        <li className='c-table__item'>
          <h4 className='c-table__title is-sizeS'>A2</h4>
          <div className='c-table__body'>
            <p>約30cm程度の傷</p>
          </div>
        </li>
        <li className='c-table__item'>
          <h4 className='c-table__title is-sizeS'>A3</h4>
          <div className='c-table__body'>
            <p>A2を超える傷</p>
          </div>
        </li>
      </ul>
      <h3 className='headline3'>ヘコミ</h3>
      <ul className='c-table c-contents'>
        <li className='c-table__item'>
          <h4 className='c-table__title is-sizeS'>U1</h4>
          <div className='c-table__body'>
            <p>エクボ・ゴルフボール程度までのヘコミ（小）</p>
          </div>
        </li>
        <li className='c-table__item'>
          <h4 className='c-table__title is-sizeS'>U2</h4>
          <div className='c-table__body'>
            <p>ベースボール程度までのヘコミ（中）</p>
          </div>
        </li>
        <li className='c-table__item'>
          <h4 className='c-table__title is-sizeS'>U3</h4>
          <div className='c-table__body'>
            <p>加修で直る程度のヘコミ（大）</p>
          </div>
        </li>
      </ul>

      <h3 className='headline3'>キズを伴うヘコミ</h3>
      <ul className='c-table c-contents'>
        <li className='c-table__item'>
          <h4 className='c-table__title is-sizeS'>B1</h4>
          <div className='c-table__body'>
            <p>ゴルフボール程度までの傷を伴うエクボ・ヘコミ（小）</p>
          </div>
        </li>
        <li className='c-table__item'>
          <h4 className='c-table__title is-sizeS'>B2</h4>
          <div className='c-table__body'>
            <p>ベースボール程度までの傷を伴うヘコミ（中）</p>
          </div>
        </li>
        <li className='c-table__item'>
          <h4 className='c-table__title is-sizeS'>B3</h4>
          <div className='c-table__body'>
            <p>加修で直る程度の傷を伴うヘコミ（大）</p>
          </div>
        </li>
      </ul>

      <h3 className='headline3'>補修跡塗装波</h3>
      <ul className='c-table c-contents'>
        <li className='c-table__item'>
          <h4 className='c-table__title is-sizeS'>W1</h4>
          <div className='c-table__body'>
            <p>良質な仕上げの補修跡塗装波</p>
          </div>
        </li>
        <li className='c-table__item'>
          <h4 className='c-table__title is-sizeS'>W2</h4>
          <div className='c-table__body'>
            <p>通常の仕上げの補修跡塗装波</p>
          </div>
        </li>
        <li className='c-table__item'>
          <h4 className='c-table__title is-sizeS'>W3</h4>
          <div className='c-table__body'>
            <p>再補修を要す補修跡塗装波</p>
          </div>
        </li>
      </ul>

      <h3 className='headline3'>さび</h3>
      <ul className='c-table c-contents'>
        <li className='c-table__item'>
          <h4 className='c-table__title is-sizeS'>S1</h4>
          <div className='c-table__body'>
            <p>小錆数箇所まで</p>
          </div>
        </li>
        <li className='c-table__item'>
          <h4 className='c-table__title is-sizeS'>S2</h4>
          <div className='c-table__body'>
            <p>ゴルフボール大までの錆</p>
          </div>
        </li>
        <li className='c-table__item'>
          <h4 className='c-table__title is-sizeS'>S3</h4>
          <div className='c-table__body'>
            <p>ベースボール大までの錆</p>
          </div>
        </li>
      </ul>

      <h3 className='headline3'>ガラス</h3>
      <ul className='c-table c-contents'>
        <li className='c-table__item'>
          <h4 className='c-table__title is-sizeS'>リペア跡</h4>
          <div className='c-table__body'>
            <p>良好な仕上げのガラスリペア跡</p>
          </div>
        </li>
        <li className='c-table__item'>
          <h4 className='c-table__title is-sizeS'>トビ石</h4>
          <div className='c-table__body'>
            <p>ガラス点傷</p>
          </div>
        </li>
        <li className='c-table__item'>
          <h4 className='c-table__title is-sizeS'>ヒビ</h4>
          <div className='c-table__body'>
            <p>約1cm程度までのガラスヒビ</p>
          </div>
        </li>
        <li className='c-table__item'>
          <h4 className='c-table__title is-sizeS'>ワレ</h4>
          <div className='c-table__body'>
            <p>ヒビを超えるガラスの割れ</p>
          </div>
        </li>
      </ul>

      <h3 className='headline3'>その他の表現</h3>
      <ul className='c-table c-contents'>
        <li className='c-table__item'>
          <h4 className='c-table__title is-sizeS'>×</h4>
          <div className='c-table__body'>
            <p>交換要す</p>
          </div>
        </li>
        <li className='c-table__item'>
          <h4 className='c-table__title is-sizeS'>××</h4>
          <div className='c-table__body'>
            <p>交換済</p>
          </div>
        </li>
        <li className='c-table__item'>
          <h4 className='c-table__title is-sizeS'>ワレ</h4>
          <div className='c-table__body'>
            <p>割れ</p>
          </div>
        </li>
        <li className='c-table__item'>
          <h4 className='c-table__title is-sizeS'>色アセ</h4>
          <div className='c-table__body'>
            <p>色褪せ</p>
          </div>
        </li>
        <li className='c-table__item'>
          <h4 className='c-table__title is-sizeS'>Pアト</h4>
          <div className='c-table__body'>
            <p>ペイント跡</p>
          </div>
        </li>
        <li className='c-table__item'>
          <h4 className='c-table__title is-sizeS'>C</h4>
          <div className='c-table__body'>
            <p>腐食</p>
          </div>
        </li>
      </ul>
    </>
  );
};
