import { CarStockSortTypeEnum } from '~/gql/generated/graphql';

type SortTypeTextType = {
  [k in CarStockSortTypeEnum]: string;
};

export const sortTypeText: SortTypeTextType = {
  [CarStockSortTypeEnum.Newer]: '新着順',
  [CarStockSortTypeEnum.Popular]: '人気（いいね）順',
  [CarStockSortTypeEnum.AmountLower]: '本体価格：安い順',
  [CarStockSortTypeEnum.AmountHigher]: '本体価格：高い順',
  [CarStockSortTypeEnum.YearNewer]: '年式：新しい順',
  [CarStockSortTypeEnum.MileageLower]: '走行距離：少ない順'
};
