import { useLocation, useSearchParams } from '@remix-run/react';
import { useEffect, useRef, useState, type FunctionComponent } from 'react';
import { useClient } from 'urql';
import { BottomNav, CarDeliveryAddress, CarEmpty, CarRequest, CarStatusBar } from '~/components/shared';
import { InquireSellingPopupBanner } from '~/components/shared/banners';
import { graphql, useFragment, type FragmentType } from '~/gql/generated';
import { CarSalesStatusEnum, type CarsSearch_CarStockFragment } from '~/gql/generated/graphql';
import { useCarsSearchParams } from '~/hooks';
import { useCarListDisplayTypeContext, useInfiniteScrollContext } from '~/providers';
import { carStocksSearchPageQueryDocument, variablesFromSearchParams } from '~/routes/search';
import styles from '~/styles/page/cars/carsSearch/cars-search.module.css';
import { CarsSearchResult } from '../CarsIndex/CarSearchResult';
import { SortSection } from '../CarsIndex/SortSection';
import { OneColumnCarCard } from './OenColumnCarCard';
import { TwoColumnCarCard } from './TwoColumnCarCard';

const FETCH_CAR_STOCKS_COUNT = 20;

const blockClass = 'cars-search';

const CarsSearchCarStocksFragment = graphql(`
  fragment CarsSearch_carStock on CarStock {
    id
    ...OneColumnCarCard_carStock
    ...TwoColumnCarCard_carStock
  }
`);

const useCarStocks = ({
  firstCarStocks,
  firstEndCursor,
  firstHasNextCarStocks




}: {firstCarStocks: readonly CarsSearch_CarStockFragment[];firstEndCursor?: string | null;firstHasNextCarStocks: boolean;}): {
  carStocks: readonly CarsSearch_CarStockFragment[];
  hasNextCarStocks: boolean;
  initializing: boolean;
  observerTarget: React.MutableRefObject<null>;
} => {
  const [carStocks, setCarStocks] = useState(firstCarStocks);
  const [endCursor, setEndCursor] = useState(firstEndCursor);
  const [hasNextCarStocks, setHasNextCarStocks] = useState(firstHasNextCarStocks);
  const [initializing, setInitializing] = useState(true);
  const [isScrolledBottom, setIsScrolledBottom] = useState(false);
  const [searchParams] = useSearchParams();
  const observerTarget = useRef(null);
  const location = useLocation();
  const client = useClient();
  const { infiniteScroll, addScrolledCursor } = useInfiniteScrollContext();

  useEffect(() => {
    setCarStocks(firstCarStocks);
    setHasNextCarStocks(firstHasNextCarStocks);
    setEndCursor(firstEndCursor);
    initializeCarStocks();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstCarStocks, firstEndCursor, firstHasNextCarStocks]);

  const initializeCarStocks = async () => {
    const targetCursors = infiniteScroll[location.key]?.cursors || [];

    if (targetCursors.length === 0) {
      setInitializing(false);
      return;
    }

    targetCursors.forEach(async (cursor, index) => {
      const variables = {
        ...variablesFromSearchParams(searchParams, FETCH_CAR_STOCKS_COUNT),
        ...{ after: cursor, includeTotalCount: false }
      };
      const result = await client.query(carStocksSearchPageQueryDocument, variables);
      if (result.error) return;

      const resultCarStocks = (result.data?.carStocks?.edges?.map((edge) => edge?.node).filter((node) => !!node) ||
      []) as CarsSearch_CarStockFragment[];
      setCarStocks((prev) => [...prev, ...resultCarStocks]);

      if (index === targetCursors.length - 1) {
        const resultHasNextPage = result.data?.carStocks?.pageInfo?.hasNextPage || false;
        const resultEndCursor = result.data?.carStocks?.pageInfo?.endCursor;
        setHasNextCarStocks(resultHasNextPage);
        setEndCursor(resultEndCursor);
      }
    });
    setInitializing(false);
    setTimeout(() => {
      const elementId = infiniteScroll[location.key]?.elementId;
      if (!elementId) return;
      const targetElement = document.getElementById(elementId);
      if (!targetElement) return;
      targetElement.scrollIntoView({ block: 'center' });
    }, 100);
  };

  const fetchNextCarStocks = async () => {
    if (!hasNextCarStocks) {
      return;
    }
    if (!isScrolledBottom) return;

    const currentEndCursor = endCursor;
    const variables = {
      ...variablesFromSearchParams(searchParams, FETCH_CAR_STOCKS_COUNT),
      ...{ after: endCursor, includeTotalCount: false }
    };
    const result = await client.query(carStocksSearchPageQueryDocument, variables);
    if (result.error) {
      setIsScrolledBottom(false);
      return;
    }
    const resultCarStocks = (result.data?.carStocks?.edges?.map((edge) => edge?.node).filter((node) => !!node) ||
    []) as CarsSearch_CarStockFragment[];
    const resultHasNextPage = result.data?.carStocks?.pageInfo?.hasNextPage || false;
    const resultEndCursor = result.data?.carStocks?.pageInfo?.endCursor;
    setCarStocks((prev) => [...prev, ...resultCarStocks]);
    setHasNextCarStocks(resultHasNextPage);
    setEndCursor(resultEndCursor);

    if (currentEndCursor) {
      addScrolledCursor(location.key, currentEndCursor);
    }

    setIsScrolledBottom(false);
  };

  useEffect(() => {
    if (!isScrolledBottom) return;
    fetchNextCarStocks();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isScrolledBottom]);

  useEffect(() => {
    setIsScrolledBottom(false);
  }, [location]);

  useEffect(() => {
    if (initializing) return;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          if (hasNextCarStocks) {
            setIsScrolledBottom(true);
          }
        }
      },
      { threshold: 1.0 }
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initializing]);

  return { carStocks, hasNextCarStocks, initializing, observerTarget };
};

type Props = {
  carStocks: FragmentType<typeof CarsSearchCarStocksFragment>[];
  totalCount?: number;
  firstHasNextCarStocks: boolean;
  firstEndCursor?: string | null;
};

export const CarsSearch: FunctionComponent<Props> = (props) => {
  const firstCarStocks = useFragment(CarsSearchCarStocksFragment, props.carStocks);
  const { totalCount, firstHasNextCarStocks, firstEndCursor } = props;
  const { carStocks, hasNextCarStocks, observerTarget } = useCarStocks({
    firstCarStocks,
    firstEndCursor,
    firstHasNextCarStocks
  });
  const { displayType, setDisplayType } = useCarListDisplayTypeContext();
  const [searchParams] = useSearchParams();
  const { changeStatus, changeZipCodeAndAddress } = useCarsSearchParams();

  const toggleDisplayType = () => {
    setDisplayType(displayType === 'oneColumn' ? 'twoColumn' : 'oneColumn');
  };

  return (
    <div className={styles[blockClass]}>
      <CarStatusBar
        status={
        searchParams.get('status') === CarSalesStatusEnum.Closed ?
        CarSalesStatusEnum.Closed :
        CarSalesStatusEnum.OnSale
        }
        onChangeStatus={changeStatus} />

      <div className={styles[`${blockClass}__row`]}>
        <CarDeliveryAddress onComplete={changeZipCodeAndAddress} />
      </div>
      <CarsSearchResult />
      <div className={styles[`${blockClass}__tools`]}>
        <div className={styles[`${blockClass}__total`]}>{totalCount && <p>{totalCount}件</p>}</div>
        <div style={{ display: 'flex' }}>
          <div className={styles[`${blockClass}__result`]}>
            <SortSection />
          </div>
          <div className={styles[`${blockClass}__display`]} onClick={toggleDisplayType}>
            <img
              src={displayType === 'oneColumn' ? '/images/icons/ic_display1.svg' : '/images/icons/ic_display2.svg'}
              alt='display'
              className={styles[`${blockClass}__display-icon`]} />

            <p className={styles[`${blockClass}__display-text`]}>表示切替</p>
          </div>
        </div>
      </div>

      {carStocks.length > 0 ?
      <>
          {displayType === 'oneColumn' ?
        <div>
              {carStocks.map((carStock) =>
          <OneColumnCarCard carStock={carStock} key={carStock.id} />
          )}
            </div> :

        <div className={styles[`${blockClass}__two-list`]}>
              {carStocks.map((carStock) =>
          <TwoColumnCarCard carStock={carStock} key={carStock.id} />
          )}
            </div>
        }
          {!hasNextCarStocks && <CarRequest />}
        </> :

      <CarEmpty />
      }
      <div ref={observerTarget}>
        {hasNextCarStocks &&
        <div
          className={[
          styles[`${blockClass}__loader`],
          carStocks.length === 0 ? styles[`${blockClass}__loader--hide`] : ''].
          join(' ')} />

        }
      </div>
      <InquireSellingPopupBanner />
      <BottomNav />
    </div>);

};