import {
  json,
  type HeadersArgs,
  type HeadersFunction,
  type LoaderFunctionArgs,
  type MetaFunction,
  type TypedResponse
} from '@remix-run/cloudflare';
import { useLoaderData } from '@remix-run/react';
import { StandardError } from '~/components/errors';
import { Layout } from '~/components/layouts';
import { CarsMovies } from '~/components/pages';
import { graphql } from '~/gql/generated';
import { CarSalesStatusEnum, type CarsMoviesPageQuery } from '~/gql/generated/graphql';
import { ssrClient } from '~/graphql/client';
import { getEnvironmentVariables } from '~/infrastructure/environmentVariables';
import { loaderErrorHandle } from '~/infrastructure/errorHandle';
import { noCache } from '~/infrastructure/header';
import { mergeMetaTags } from '~/utils/meta';

const FETCH_CAR_STOCKS_COUNT = 20;

export const carsMoviesPageQueryDocument = graphql(`
  query CarsMoviesPage($first: Int, $after: String, $hasVideo: Boolean, $salesStatus: CarSalesStatusEnum) {
    carStocks(first: $first, after: $after, hasVideo: $hasVideo, salesStatus: $salesStatus) {
      edges {
        node {
          id
          landTransportCosts {
            cost
            prefectureCode
          }
          ...CarMovies_carStock
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`);

type CarStockType = NonNullable<NonNullable<NonNullable<CarsMoviesPageQuery['carStocks']['edges']>[number]>['node']>;

export const variablesFromSearchParams = (searchParams: URLSearchParams) => {
  const salesStatus =
    searchParams.get('status') === CarSalesStatusEnum.Closed ? CarSalesStatusEnum.Closed : CarSalesStatusEnum.OnSale;
  return {
    salesStatus,
    hasVideo: true,
    first: FETCH_CAR_STOCKS_COUNT
  };
};

type LoadData = {
  carStocks: CarStockType[];
  hasNextPage: boolean;
  endCursor?: string | null;
  hasError: boolean;
};

export const loader = async ({ context, request }: LoaderFunctionArgs): Promise<TypedResponse<LoadData>> => {
  const env = getEnvironmentVariables(context);
  const url = new URL(request.url);
  const searchParams = url.searchParams;
  const graphqlClient = ssrClient({ baseUrl: env.graphqlBaseUrl });
  const variables = variablesFromSearchParams(searchParams);
  const res = await graphqlClient.query(carsMoviesPageQueryDocument, variables);
  const hasError = !!res.error;

  if (hasError) {
    throw loaderErrorHandle(res.operation.context.url, res.error!);
  }

  return json<LoadData>(
    {
      carStocks: (res.data?.carStocks?.edges?.map((edge) => edge?.node).filter((node) => !!node) ||
        []) as CarStockType[],
      hasNextPage: res.data?.carStocks?.pageInfo?.hasNextPage || false,
      endCursor: res.data?.carStocks?.pageInfo?.endCursor,
      hasError: !!res.error
    },
    {
      headers: { 'Cache-Control': hasError ? noCache : 'public, max-age=0, s-maxage=60' }
    }
  );
};

export const headers: HeadersFunction = ({ parentHeaders, loaderHeaders }: HeadersArgs) => {
  const loaderCacheControl = loaderHeaders.get('cache-control');

  return {
    ...parentHeaders,
    'Cache-Control': loaderCacheControl || 'public, max-age=0, s-maxage=60'
  };
};

export const meta: MetaFunction = ({ matches }) => {
  const title = '車両の動画ツアー | 車の通販バディカダイレクト - LINEで相談';
  const metaTags = [{ title: title }, { name: 'title', content: title }, { property: 'og:title', content: title }];

  return mergeMetaTags({ metaTags, matches });
};

export default function CarsMoviesRoute() {
  const data = useLoaderData<typeof loader>();

  if (data.hasError) {
    return (
      <Layout>
        <StandardError />
      </Layout>
    );
  }

  return (
    <Layout>
      <CarsMovies carStocks={data.carStocks} firstHasNextCarStocks={data.hasNextPage} firstEndCursor={data.endCursor} />
    </Layout>
  );
}
