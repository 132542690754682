import { useClickOutside, useDebouncedValue } from '@mantine/hooks';
import { useNavigate, useSearchParams } from '@remix-run/react';
import type { Dispatch, FunctionComponent } from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useClient, useMutation, useQuery } from 'urql';
import { Loading } from '~/components/parts';
import { graphql } from '~/gql/generated';
import type {
  DestroyCarStockSearchConditionMutation,
  SearchConditionCarNamesAttributes,
  UpdateCarStockSearchConditionMutation } from
'~/gql/generated/graphql';
import { SearchConditionBodyTypeGroupEnum } from '~/gql/generated/graphql';
import { useToastContext } from '~/providers';
import styles from '~/styles/page/searchConditionShow/search-condition-show.module.css';
import { errorHandle } from '~/utils/graphql/errorHandle';

const blockClass = 'search-condition-show';

const searchConditionShowQueryDocument = graphql(`
  query SearchConditionShow {
    viewer {
      id
      lineAccount {
        id
        carStockSearchCondition {
          id
          minAmount
          maxAmount
          maxMileageKm
          minCarModeYearBeforeYear
          bodyTypeGroups {
            id
            bodyTypeGroup
          }
          searchConditionCarNames {
            id
            carModelName
            makeName
          }
        }
      }
    }
  }
`);

const carStockNamesQueryDocument = graphql(`
  query SearchConditionShow_CarStockNames($carModelNameKeyword: String, $makeNameKeyword: String) {
    carStockNames(carModelNameKeyword: $carModelNameKeyword, makeNameKeyword: $makeNameKeyword) {
      carModelNames
      makeNames
    }
  }
`);

const updateCarStockSearchConditionMutationDocument = graphql(`
  mutation UpdateCarStockSearchCondition($input: UpdateCarStockSearchConditionInput!) {
    updateCarStockSearchCondition(input: $input) {
      carStockSearchCondition {
        id
        minAmount
        maxAmount
        maxMileageKm
        minCarModeYearBeforeYear
        bodyTypeGroups {
          id
          bodyTypeGroup
        }
        searchConditionCarNames {
          id
          carModelName
          makeName
        }
      }
      errors {
        message
        path
      }
    }
  }
`);

const destroyCarStockSearchConditionMutationDocument = graphql(`
  mutation DestroyCarStockSearchCondition($input: DestroyCarStockSearchConditionInput!) {
    destroyCarStockSearchCondition(input: $input) {
      isSuccess
      errors {
        message
        path
      }
    }
  }
`);

type BodyGroupType = {
  enum: SearchConditionBodyTypeGroupEnum;
  name: string;
  icon: string;
};
type AmountType = {
  minAmount: number | null;
  maxAmount: number | null;
  name: string;
  icon: string;
  selected: boolean;
};
type MileageKmType = {
  maxMileageKm: number | null;
  text: string;
};
type YearType = {
  minCarModeYearBeforeYear: number | null;
  text: string;
};
type CarNameType = {
  id?: string;
  makeName?: string | null;
  carModelName?: string | null;
  destroy?: boolean;
  activeType: 'makeName' | 'carModelName';
  openSuggestion?: boolean;
  isDefault?: boolean;
};

type MakeNameSuggestionsProps = {
  makeName?: string | null;
  open: boolean;
  onClose: () => void;
  selectMakeName: (makeName: string) => void;
};
const MakeNameSuggestions: FunctionComponent<MakeNameSuggestionsProps> = (props) => {
  const { makeName, open, selectMakeName, onClose } = props;
  const [debounced] = useDebouncedValue(makeName, 250);
  const [makeNames, setMakeNames] = useState<string[]>([]);
  const client = useClient();
  const ref = useClickOutside(() => onClose());

  useEffect(() => {
    if (!open) return;

    (async () => {
      const result = await client.query(carStockNamesQueryDocument, { makeNameKeyword: debounced ?? null });
      setMakeNames(result?.data?.carStockNames?.makeNames || []);
    })();
  }, [debounced, open, client]);

  if (!open) {
    return null;
  }

  return (
    <>
      <div className={styles[`${blockClass}__car-name__item-suggestion`]} ref={ref}>
        {makeNames.slice(0, 5).map((makeName, index) =>
        <p
          key={index}
          className={styles[`${blockClass}__car-name__item-suggestion__item`]}
          onClick={() => {
            selectMakeName(makeName);
          }}>

            {makeName}
          </p>
        )}
      </div>
    </>);

};

type CarModelNameSuggestionsProps = {
  carModelName?: string | null;
  open: boolean;
  onClose: () => void;
  selectCarModelName: (carModelName: string) => void;
};
const CarModelNameSuggestions: FunctionComponent<CarModelNameSuggestionsProps> = (props) => {
  const { carModelName, open, selectCarModelName, onClose } = props;
  const [debounced] = useDebouncedValue(carModelName, 250);
  const [carModelNames, setCarModelNames] = useState<string[]>([]);
  const client = useClient();
  const ref = useClickOutside(() => onClose());

  useEffect(() => {
    if (!open) return;

    (async () => {
      const result = await client.query(carStockNamesQueryDocument, { carModelNameKeyword: debounced ?? null });
      setCarModelNames(result?.data?.carStockNames?.carModelNames || []);
    })();
  }, [debounced, open, client]);

  if (!open) {
    return null;
  }

  return (
    <>
      <div className={styles[`${blockClass}__car-name__item-suggestion`]} ref={ref}>
        {carModelNames.slice(0, 5).map((carModelName, index) =>
        <p
          key={index}
          className={styles[`${blockClass}__car-name__item-suggestion__item`]}
          onClick={() => {
            selectCarModelName(carModelName);
          }}>

            {carModelName}
          </p>
        )}
      </div>
    </>);

};

type CarNamesFormProps = {
  carNames: CarNameType[];
  setCarNames: Dispatch<React.SetStateAction<CarNameType[]>>;
  isClickedButton: boolean;
};
const CarNamesForm: FunctionComponent<CarNamesFormProps> = (props) => {
  const { carNames, setCarNames, isClickedButton } = props;

  const addItem = () => {
    setCarNames((prev) => [...prev, { activeType: 'makeName' }]);
  };

  const removeItem = (carName: CarNameType, index: number) => {
    if (carName.id) {
      setCarNames((prev) => prev.map((item, i) => i === index ? { ...item, destroy: true } : item));
    } else {
      setCarNames((prev) => prev.filter((_, i) => i !== index));
    }
  };

  return (
    <div className={styles[`${blockClass}__group`]}>
      <p className={styles[`${blockClass}__group-title`]}>
        メーカー名・車種名<span className={styles[`${blockClass}__group-title__normal`]}> (複数入力可)</span>
      </p>
      {carNames.map((carName, index) => {
        return (
          <div
            className={styles[`${blockClass}__car-name__item`]}
            key={index}
            style={{ display: carName.destroy ? 'none' : 'block' }}>

            <div className={styles[`${blockClass}__car-name__item-switch`]}>
              <p
                className={[
                styles[`${blockClass}__car-name__item-switch__tab`],
                carName.activeType === 'makeName' ? styles[`${blockClass}__car-name__item-switch__tab--active`] : null].
                join(' ')}
                onClick={() =>
                setCarNames((prev) =>
                prev.map((item, i) => i === index ? { ...item, activeType: 'makeName' } : item)
                )
                }>

                メーカー名
              </p>
              <p
                className={[
                styles[`${blockClass}__car-name__item-switch__tab`],
                carName.activeType === 'carModelName' ?
                styles[`${blockClass}__car-name__item-switch__tab--active`] :
                null].
                join(' ')}
                onClick={() =>
                setCarNames((prev) =>
                prev.map((item, i) => i === index ? { ...item, activeType: 'carModelName' } : item)
                )
                }>

                車種名
              </p>
            </div>
            {isClickedButton &&
            !carName.destroy && (
            carName.activeType === 'makeName' && !carName.makeName && !carName.isDefault ||
            carName.activeType === 'carModelName' && !carName.carModelName && !carName.isDefault) &&
            <p className={styles[`${blockClass}__car-name__item-error`]}>入力してください</p>
            }
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
              <div style={{ flex: 1, marginRight: 8, position: 'relative' }}>
                <input
                  key={`makeName-${index}-${carName.id}`}
                  className={styles[`${blockClass}__car-name__item-input`]}
                  placeholder='メーカー名'
                  style={{ display: carName.activeType === 'makeName' ? 'block' : 'none' }}
                  value={carName.makeName || ''}
                  onChange={(e) =>
                  setCarNames((prev) =>
                  prev.map((item, i) => i === index ? { ...item, makeName: e.target.value } : item)
                  )
                  }
                  onFocus={() =>
                  setCarNames((prev) =>
                  prev.map((item, i) => i === index ? { ...item, openSuggestion: true } : item)
                  )
                  } />

                <MakeNameSuggestions
                  makeName={carName.makeName}
                  open={carName.activeType === 'makeName' && (carName.openSuggestion || false)}
                  onClose={() =>
                  setCarNames((prev) =>
                  prev.map((item, i) => i === index ? { ...item, openSuggestion: false } : item)
                  )
                  }
                  selectMakeName={(makeName) => {
                    setCarNames((prev) => prev.map((item, i) => i === index ? { ...item, makeName } : item));
                  }} />


                <input
                  key={`carModelName-${index}-${carName.id}`}
                  className={styles[`${blockClass}__car-name__item-input`]}
                  placeholder='車種名'
                  style={{ display: carName.activeType === 'carModelName' ? 'block' : 'none' }}
                  value={carName.carModelName || ''}
                  onChange={(e) =>
                  setCarNames((prev) =>
                  prev.map((item, i) => i === index ? { ...item, carModelName: e.target.value } : item)
                  )
                  }
                  onFocus={() =>
                  setCarNames((prev) =>
                  prev.map((item, i) => i === index ? { ...item, openSuggestion: true } : item)
                  )
                  } />

                <CarModelNameSuggestions
                  carModelName={carName.carModelName}
                  open={carName.activeType === 'carModelName' && (carName.openSuggestion || false)}
                  onClose={() =>
                  setCarNames((prev) =>
                  prev.map((item, i) => i === index ? { ...item, openSuggestion: false } : item)
                  )
                  }
                  selectCarModelName={(carModelName) => {
                    setCarNames((prev) => prev.map((item, i) => i === index ? { ...item, carModelName } : item));
                  }} />

              </div>

              <p onClick={() => removeItem(carName, index)} className={styles[`${blockClass}__car-name__item-delete`]}>
                ×削除
              </p>
            </div>
          </div>);

      })}
      <p className={styles[`${blockClass}__car-name__plus`]} onClick={addItem}>
        ＋ さらに追加する
      </p>
    </div>);

};

export const SearchConditionShow: FunctionComponent = () => {
  const [{ fetching, data }] = useQuery({ query: searchConditionShowQueryDocument, requestPolicy: 'network-only' });
  const carStockSearchCondition = data?.viewer?.lineAccount?.carStockSearchCondition;
  const [{ fetching: updateSubmitting }, updateCarStockSearchCondition] = useMutation(
    updateCarStockSearchConditionMutationDocument
  );
  const [{ fetching: destroySubmitting }, destroyCarStockSearchCondition] = useMutation(
    destroyCarStockSearchConditionMutationDocument
  );
  const submitting = updateSubmitting || destroySubmitting;
  const defaultCarNames: CarNameType[] = useMemo(() => [{ activeType: 'makeName', isDefault: true }], []);
  const { open } = useToastContext();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const redirectPath = searchParams.get('redirectPath') || '/';
  const designatedMakeName = searchParams.get('makeName');
  const designatedCarModelName = searchParams.get('carModelName');
  const [bodyTypeGroups, setBodyTypeGroups] = useState<SearchConditionBodyTypeGroupEnum[]>([]);
  const [minAmount, setMinAmount] = useState<number | null>(null);
  const [maxAmount, setMaxAmount] = useState<number | null>(null);
  const [maxMileageKm, setMaxMileageKm] = useState<number | null>(null);
  const [minCarModeYearBeforeYear, setMinCarModeYearBeforeYear] = useState<number | null>(null);
  const [carNames, setCarNames] = useState<CarNameType[]>(defaultCarNames);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isCreated, setIsCreated] = useState<boolean>(false);
  const [isClickedButton, setIsClickedButton] = useState<boolean>(false);

  useEffect(() => {
    if (fetching) return;

    if (!carStockSearchCondition) {
      const searchConditionCarNames: CarNameType[] = [];
      const updatedCarNames = updateCarNames(searchConditionCarNames);
      setCarNames(updatedCarNames);
      return;
    }

    const defaultBodyTypeGroups = carStockSearchCondition.bodyTypeGroups?.map((group) => group.bodyTypeGroup) || [];
    const defaultMinAmount = carStockSearchCondition.minAmount || null;
    const defaultMaxAmount = carStockSearchCondition.maxAmount || null;
    const defaultMaxMileageKm = carStockSearchCondition.maxMileageKm || null;
    const defaultMinCarModeYearBeforeYear = carStockSearchCondition.minCarModeYearBeforeYear || null;
    setBodyTypeGroups(defaultBodyTypeGroups);
    setMinAmount(defaultMinAmount);
    setMaxAmount(defaultMaxAmount);
    setMaxMileageKm(defaultMaxMileageKm);
    setMinCarModeYearBeforeYear(defaultMinCarModeYearBeforeYear);

    const searchConditionCarNames: CarNameType[] =
    carStockSearchCondition.searchConditionCarNames && carStockSearchCondition.searchConditionCarNames.length > 0 ?
    carStockSearchCondition.searchConditionCarNames.map((carName) => {
      return {
        id: carName.id,
        makeName: carName.makeName,
        carModelName: carName.carModelName,
        activeType: carName.makeName ? 'makeName' : 'carModelName'
      };
    }) :
    [];
    const updatedCarNames = updateCarNames(searchConditionCarNames);
    setCarNames(updatedCarNames);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetching, defaultCarNames]);

  const updateCarNames = (baseCarNames: CarNameType[]): CarNameType[] => {
    const updatedCarNames = [...baseCarNames];

    if (designatedMakeName) {
      updatedCarNames.push({
        makeName: designatedMakeName,
        activeType: 'makeName'
      });
    }
    if (designatedCarModelName) {
      updatedCarNames.push({
        carModelName: designatedCarModelName,
        activeType: 'carModelName'
      });
    }

    return updatedCarNames.length > 0 ? updatedCarNames : defaultCarNames;
  };

  const bodyGroups: BodyGroupType[] = [
  { enum: SearchConditionBodyTypeGroupEnum.KeiCar, name: '軽自動車', icon: '/images/icons/ic_car_kei.svg' },
  { enum: SearchConditionBodyTypeGroupEnum.Compact, name: 'コンパクト', icon: '/images/icons/ic_car_compact.svg' },
  { enum: SearchConditionBodyTypeGroupEnum.Suv, name: 'SUV', icon: '/images/icons/ic_car_suv.svg' },
  { enum: SearchConditionBodyTypeGroupEnum.Minivan, name: 'ミニバン', icon: '/images/icons/ic_car_minivan.svg' },
  { enum: SearchConditionBodyTypeGroupEnum.Other, name: 'その他', icon: '/images/icons/ic_car_other.svg' }];

  const amounts: AmountType[] = [
  {
    minAmount: null,
    maxAmount: 1000000,
    name: '~100万円',
    icon: '/images/icons/ic_coin_few.svg',
    selected: minAmount === null && maxAmount === 1000000
  },
  {
    minAmount: 1000000,
    maxAmount: 2000000,
    name: '100~200万円',
    icon: '/images/icons/ic_coin_normal.svg',
    selected: minAmount === 1000000 && maxAmount === 2000000
  },
  {
    minAmount: 2000000,
    maxAmount: 3000000,
    name: '200〜300万円',
    icon: '/images/icons/ic_coin_many.svg',
    selected: minAmount === 2000000 && maxAmount === 3000000
  },
  {
    minAmount: 3000000,
    maxAmount: null,
    name: '300万円〜',
    icon: '/images/icons/ic_coin4.svg',
    selected: minAmount === 3000000 && maxAmount === null
  }];

  const mileageKms: MileageKmType[] = [
  { maxMileageKm: 10000, text: '~10,000km' },
  { maxMileageKm: 30000, text: '~30,000km' },
  { maxMileageKm: 50000, text: '~50,000km' },
  { maxMileageKm: 100000, text: '~100,000km' }];

  const years: YearType[] = [
  { minCarModeYearBeforeYear: 1, text: '1年以内' },
  { minCarModeYearBeforeYear: 3, text: '3年以内' },
  { minCarModeYearBeforeYear: 5, text: '5年以内' },
  { minCarModeYearBeforeYear: 10, text: '10年以内' }];


  const handleClickBodyTypeGroup = (selectBodyTypeGroup: SearchConditionBodyTypeGroupEnum) => {
    if (bodyTypeGroups.includes(selectBodyTypeGroup)) {
      setBodyTypeGroups(bodyTypeGroups.filter((group) => group !== selectBodyTypeGroup));
    } else {
      setBodyTypeGroups([...bodyTypeGroups, selectBodyTypeGroup]);
    }
  };

  const handleClickAmount = (selectMinAmount: number | null, selectMaxAmount: number | null) => {
    if (selectMinAmount === minAmount && selectMaxAmount === maxAmount) {
      setMinAmount(null);
      setMaxAmount(null);
      return;
    }

    setMinAmount(selectMinAmount);
    setMaxAmount(selectMaxAmount);
  };

  const handleClickMileageKm = (selectMaxMileageKm: number | null) => {
    if (selectMaxMileageKm === maxMileageKm) {
      setMaxMileageKm(null);
      return;
    }

    setMaxMileageKm(selectMaxMileageKm);
  };

  const handleClickYear = (selectMinCarModeYearBeforeYear: number | null) => {
    if (selectMinCarModeYearBeforeYear === minCarModeYearBeforeYear) {
      setMinCarModeYearBeforeYear(null);
      return;
    }

    setMinCarModeYearBeforeYear(selectMinCarModeYearBeforeYear);
  };

  const update = async () => {
    setIsClickedButton(true);
    setErrorMessage(null);

    const activeCarNames = carNames.filter((carName) => {
      if (carName.destroy) {
        return false;
      }
      if (carName.isDefault) {
        return !!carName.makeName || !!carName.carModelName;
      }
      return true;
    });
    if (
    bodyTypeGroups.length === 0 &&
    minAmount === null &&
    maxAmount === null &&
    maxMileageKm === null &&
    minCarModeYearBeforeYear === null &&
    activeCarNames.length === 0)
    {
      setErrorMessage('条件をひとつ以上設定してください');
      return;
    }

    if (
    activeCarNames.some(
      (carName) =>
      carName.activeType === 'makeName' && !carName.makeName ||
      carName.activeType === 'carModelName' && !carName.carModelName
    ))
    {
      return;
    }

    try {
      const { data, error } = await updateCarStockSearchCondition({
        input: {
          bodyTypeGroups: bodyTypeGroups,
          minAmount: minAmount,
          maxAmount: maxAmount,
          maxMileageKm: maxMileageKm,
          minCarModeYearBeforeYear: minCarModeYearBeforeYear,
          searchConditionCarNamesAttributes: carNames.
          map((carName) => {
            if (carName.isDefault && !carName.makeName && !carName.carModelName) {
              return null;
            }

            return {
              id: carName.id,
              makeName: carName.activeType === 'makeName' ? carName.makeName : null,
              carModelName: carName.activeType === 'carModelName' ? carName.carModelName : null,
              _destroy: carName.destroy
            };
          }).
          filter((carName) => carName !== null) as SearchConditionCarNamesAttributes[]
        }
      });
      const { hasError } = errorHandle<UpdateCarStockSearchConditionMutation['updateCarStockSearchCondition']>(
        data?.updateCarStockSearchCondition,
        error
      );

      if (hasError) {
        setErrorMessage('エラーが発生しました。もう一度お願いします。');
        return;
      }
    } catch (e) {
      setErrorMessage('エラーが発生しました。もう一度お願いします。');
    }

    setIsCreated(true);
    open('新着車両のお知らせ設定が完了しました。\nご希望の条件にマッチする車両が入荷されたらLINEでお知らせします。');
  };

  const destroy = async () => {
    setErrorMessage(null);

    try {
      const { data, error } = await destroyCarStockSearchCondition({ input: {} });
      const { hasError } = errorHandle<DestroyCarStockSearchConditionMutation['destroyCarStockSearchCondition']>(
        data?.destroyCarStockSearchCondition,
        error
      );

      if (hasError) {
        setErrorMessage('エラーが発生しました。もう一度お願いします。');
        return;
      }
    } catch (e) {
      setErrorMessage('エラーが発生しました。もう一度お願いします。');
    }

    navigate(redirectPath);
    open('新着車両のお知らせ設定を解除しました。');
  };

  if (fetching) {
    return <Loading />;
  }

  return (
    <div className={styles[blockClass]}>
      <p className={styles[`${blockClass}__title`]}>新着車両のお知らせ設定</p>
      <p className={styles[`${blockClass}__description`]}>
        登録した条件にマッチする車両が入荷したらLINEでお知らせを受け取ることが可能です。
        <br />
        <br />
        ご希望の条件をひとつ以上設定してください
      </p>
      {carStockSearchCondition && <p className={styles[`${blockClass}__mark`]}>現在以下の条件が設定されています</p>}

      {/* メーカー名・車種名 */}
      <CarNamesForm carNames={carNames} setCarNames={setCarNames} isClickedButton={isClickedButton} />

      {/* タイプ */}
      <div className={styles[`${blockClass}__group`]}>
        <p className={styles[`${blockClass}__group-title`]}>
          タイプ<span className={styles[`${blockClass}__group-title__normal`]}> (複数選択可)</span>
        </p>
        <div className={styles[`${blockClass}__body-tiles`]}>
          {bodyGroups.map((bodyGroup, index) =>
          <div
            className={[
            styles[`${blockClass}__body-tile`],
            bodyTypeGroups.includes(bodyGroup.enum) ? styles[`selected`] : ''].
            join(' ')}
            key={index}
            onClick={() => handleClickBodyTypeGroup(bodyGroup.enum)}>

              <div>
                <img src={bodyGroup.icon} alt='car' className={styles[`${blockClass}__body-tile__image`]} />
                <p className={styles[`${blockClass}__body-tile__text`]}>{bodyGroup.name}</p>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* 価格帯 */}
      <div className={styles[`${blockClass}__group`]}>
        <p className={styles[`${blockClass}__group-title`]}>価格帯</p>
        <div className={styles[`${blockClass}__amount-tiles`]}>
          {amounts.map((amount, index) =>
          <div
            className={[styles[`${blockClass}__amount-tile`], amount.selected ? styles[`selected`] : ''].join(' ')}
            key={index}
            onClick={() => handleClickAmount(amount.minAmount, amount.maxAmount)}>

              <div>
                <img src={amount.icon} alt='car' className={styles[`${blockClass}__amount-tile__image`]} />
                <p className={styles[`${blockClass}__amount-tile__text`]}>{amount.name}</p>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* 走行距離 */}
      <div className={styles[`${blockClass}__group`]}>
        <p className={styles[`${blockClass}__group-title`]}>走行距離</p>
        <div className={styles[`${blockClass}__mileage-tiles`]}>
          {mileageKms.map((mileageKm, index) =>
          <div
            className={[
            styles[`${blockClass}__mileage-tile`],
            mileageKm.maxMileageKm === maxMileageKm ? styles[`selected`] : ''].
            join(' ')}
            key={index}
            onClick={() => handleClickMileageKm(mileageKm.maxMileageKm)}>

              <p className={styles[`${blockClass}__mileage-tile__text`]}>{mileageKm.text}</p>
            </div>
          )}
        </div>
      </div>

      {/* 年式 */}
      <div className={styles[`${blockClass}__group`]}>
        <p className={styles[`${blockClass}__group-title`]}>年式</p>
        <div className={styles[`${blockClass}__year-tiles`]}>
          {years.map((year, index) =>
          <div
            className={[
            styles[`${blockClass}__year-tile`],
            year.minCarModeYearBeforeYear === minCarModeYearBeforeYear ? styles[`selected`] : ''].
            join(' ')}
            key={index}
            onClick={() => handleClickYear(year.minCarModeYearBeforeYear)}>

              <p className={styles[`${blockClass}__year-tile__text`]}>{year.text}</p>
            </div>
          )}
        </div>
      </div>

      {errorMessage && <p className={styles[`${blockClass}__error`]}>{errorMessage}</p>}
      <button className={styles[`${blockClass}__button`]} disabled={submitting} onClick={update}>
        {carStockSearchCondition || isCreated ? '登録条件を更新' : '登録'}
      </button>
      {(carStockSearchCondition || isCreated) &&
      <button className={styles[`${blockClass}__delete-button`]} disabled={submitting} onClick={destroy}>
          登録条件を削除
        </button>
      }
    </div>);

};