import liff from '@line/liff';
import { Link } from '@remix-run/react';
import * as Sentry from '@sentry/remix';
import { useEffect, useState, type FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { useClient, useMutation } from 'urql';
import { graphql } from '~/gql/generated';
import { type InquireBuyingNewInquireBuyingMutation } from '~/gql/generated/graphql';
import { convertZipCodeQueryDocument } from '~/gql/queries';
import { useAnalytics, useAuth } from '~/hooks';
import { useErrorFlashContext, useFlashContext } from '~/providers';
import styles from '~/styles/page/inquireBuyingNew/inquire-buying-new.module.css';
import { zipCodeRegex } from '~/utils';
import { errorHandle } from '~/utils/graphql/errorHandle';

const blockClass = 'inquire-buying-new';

const InquireBuyingNewInquireBuyingMutationDocument = graphql(`
  mutation InquireBuyingNewInquireBuying($input: InquireBuyingInput!) {
    inquireBuying(input: $input) {
      inquiry {
        id
        user {
          id
          lastName
          zipCode
          firstName
        }
      }
      errors {
        message
        path
      }
    }
  }
`);

export type Inputs = {
  lastName: string;
  firstName: string;
  zipCode: string;
  requestContent: string;
  preferredDeliveryMonth: string;
  carModelName: string | null;
  budget: string | null;
  carModelYear: string | null;
  mileageKm: string | null;
  color: string | null;
  requiredEquipment: string | null;
  comment: string | null;
};

export const InquireBuyingNew: FunctionComponent = () => {
  const { fetching, user } = useAuth();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors }
  } = useForm<Inputs>();
  const watchZipCode = watch('zipCode');
  const [address, setAddress] = useState<string>('');
  const [prefectureName, setPrefectureName] = useState<string>('');
  const [cityName, setCityName] = useState<string>('');
  const client = useClient();
  const { open: openErrorFlash } = useErrorFlashContext();
  const inquireBuying = useMutation(InquireBuyingNewInquireBuyingMutationDocument)[1];
  const [isInLiffClient, setIsInLiffClient] = useState(false);
  const { sendTrackEvent } = useAnalytics();
  const [submitting, setSubmitting] = useState(false);
  const { open: openFlash } = useFlashContext();
  const requestContentChoices = [
  '車種が決まってない',
  '利用用途だけ決まってる（街乗り・子供の送迎）',
  '他社で探してるが、見つからない',
  'はじめての車選び',
  'なにも決まってない',
  'この中に当てはまらない'];


  useEffect(() => {
    const isInClient = liff.isInClient();
    setIsInLiffClient(isInClient);
  }, []);

  useEffect(() => {
    (async () => {
      if (!watchZipCode?.match(zipCodeRegex)) {
        if (address) {
          setAddress('');
          setPrefectureName('');
          setCityName('');
        }
        return;
      }

      const result = await client.query(convertZipCodeQueryDocument, { zipCode: watchZipCode });
      const resultAddress = result.data?.convertZipCode?.address || '';
      const resultPrefectureName = result.data?.convertZipCode?.prefectureName || '';
      const resultCityName = result.data?.convertZipCode?.cityName || '';
      setAddress(resultAddress);
      setPrefectureName(resultPrefectureName);
      setCityName(resultCityName);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchZipCode]);

  useEffect(() => {
    if (fetching) {
      return;
    }

    setValue('zipCode', user?.zipCode || '');
  }, [setValue, fetching, user?.zipCode]);

  useEffect(() => {
    if (user) {
      setValue('lastName', user?.lastName || '');
      setValue('firstName', user?.firstName || '');
    }
  }, [user, setValue]);

  const sendMessages = async (inputData: Inputs) => {
    const text = [
    '【車両のお問い合わせ】',
    `お問い合わせ内容：${inputData.requestContent}`,
    `名前：${inputData.lastName} ${inputData.firstName}`,
    `住所：${inputData.zipCode} [${prefectureName}] ${cityName}`,
    `納車希望時期：${inputData.preferredDeliveryMonth}`,
    inputData.carModelName ? `車種：${inputData.carModelName}` : null,
    inputData.budget ? `予算：${inputData.budget}` : null,
    inputData.carModelYear ? `年式：${inputData.carModelYear}年以降` : null,
    inputData.mileageKm ? `走行距離：${inputData.mileageKm}km以内` : null,
    inputData.color ? `カラー：${inputData.color}` : null,
    inputData.requiredEquipment ? `必要装備：${inputData.requiredEquipment}` : null,
    inputData.comment ? `備考欄：\n${inputData.comment}` : null].

    filter((v) => !!v).
    join('\n');

    await liff.sendMessages([{ type: 'text', text }]);
  };

  const submit = async (inputData: Inputs) => {
    setSubmitting(true);

    try {
      const { data, error } = await inquireBuying({
        input: {
          lastName: inputData.lastName,
          firstName: inputData.firstName,
          zipCode: inputData.zipCode,
          requestContent: inputData.requestContent,
          preferredDeliveryMonth: inputData.preferredDeliveryMonth,
          carModelName: inputData.carModelName,
          budget: inputData.budget,
          carModelYear: inputData.carModelYear,
          mileageKm: inputData.mileageKm ? Number(inputData.mileageKm) : null,
          color: inputData.color,
          requiredEquipment: inputData.requiredEquipment,
          comment: inputData.comment
        }
      });

      const { hasError } = errorHandle<InquireBuyingNewInquireBuyingMutation['inquireBuying']>(
        data?.inquireBuying,
        error
      );
      if (hasError) {
        openErrorFlash();
        setSubmitting(false);
        return;
      }
    } catch (e) {
      openErrorFlash();
      setSubmitting(false);
      return;
    }

    try {
      await sendMessages(inputData);
    } catch (e) {
      Sentry.captureException(e);
    }

    sendTrackEvent('submit_inquire_buying');

    if (isInLiffClient) {
      liff.closeWindow();
    } else {
      openFlash(<p>お問い合わせを受け付けました！</p>);
    }
  };

  return (
    <div className={styles[blockClass]}>
      <h1 className={styles[`${blockClass}__title`]}>車両のお問い合せ</h1>
      <form onSubmit={handleSubmit(submit)}>
        <div className={styles[`${blockClass}__item`]}>
          <div className={styles[`${blockClass}__label`]}>
            <p className={styles[`${blockClass}__label-mark`]}>必須</p>
            <p className={styles[`${blockClass}__label-text`]}>近しいものを１つ教えてください</p>
          </div>
          <select {...register('requestContent', { required: true })} className={styles[`${blockClass}__input`]}>
            <option value=''>選択してください</option>
            {requestContentChoices.map((choice) =>
            <option key={choice} value={choice}>
                {choice}
              </option>
            )}
          </select>
          {errors.requestContent && <p className={styles[`${blockClass}__error`]}>近しいものを１つ選択してください</p>}
        </div>
        <div className={styles[`${blockClass}__item`]}>
          <div className={styles[`${blockClass}__label`]}>
            <p className={styles[`${blockClass}__label-mark`]}>必須</p>
            <p className={styles[`${blockClass}__label-text`]}>名前</p>
          </div>
          <div className={styles[`${blockClass}__row`]}>
            <div className={styles[`${blockClass}__group`]} style={{ marginRight: 16 }}>
              <input
                {...register('lastName', { required: true })}
                className={styles[`${blockClass}__input`]}
                placeholder='中野' />

              {errors.lastName && <p className={styles[`${blockClass}__error`]}>姓を入力してください</p>}
            </div>
            <div className={styles[`${blockClass}__group`]}>
              <input
                {...register('firstName', { required: true })}
                className={styles[`${blockClass}__input`]}
                placeholder='優作' />

              {errors.firstName && <p className={styles[`${blockClass}__error`]}>名を入力してください</p>}
            </div>
          </div>
        </div>
        <div className={styles[`${blockClass}__item`]}>
          <div className={styles[`${blockClass}__label`]}>
            <p className={styles[`${blockClass}__label-mark`]}>必須</p>
            <p className={styles[`${blockClass}__label-text`]}>郵便番号</p>
          </div>
          <div className={`${styles[`${blockClass}__row`]} ${styles[`${blockClass}__row--center`]}`}>
            <div className={styles[`${blockClass}__group`]}>
              <input
                {...register('zipCode', { required: true, pattern: zipCodeRegex })}
                className={styles[`${blockClass}__input`]}
                placeholder='2700233' />

              {errors.zipCode && <p className={styles[`${blockClass}__error`]}>郵便番号を7桁で入力してください</p>}
            </div>
            <a
              href='https://www.post.japanpost.jp/zipcode'
              className={styles[`${blockClass}__link`]}
              target='_blank'
              rel='noopener noreferrer'>

              <p className={styles[`${blockClass}__link-text`]}>わからない方はこちら</p>
              <img src={'/images/icons/ic_other_page.svg'} alt='other_page' />
            </a>
          </div>
        </div>
        <div className={`${styles[`${blockClass}__item`]} ${styles.margin42}`}>
          <p className={styles[`${blockClass}__label`]}>住所</p>
          {address ?
          <p className={styles[`${blockClass}__address-text`]}>{address}</p> :
          watchZipCode?.match(zipCodeRegex) ?
          <p className={styles[`${blockClass}__address-error`]}>郵便番号が正しくありません</p> :

          <p className={styles[`${blockClass}__address-error`]}>郵便番号を7桁で入力してください</p>
          }
        </div>
        <div className={styles[`${blockClass}__item`]}>
          <div className={styles[`${blockClass}__label`]}>
            <p className={styles[`${blockClass}__label-mark`]}>必須</p>
            <p className={styles[`${blockClass}__label-text`]}>納車希望時期</p>
          </div>
          <div className={styles[`${blockClass}__row`]}>
            <div style={{ marginRight: 24 }}>
              <input
                {...register('preferredDeliveryMonth', { required: true })}
                type='radio'
                id='month-3'
                value={'3ヶ月以内'} />

              <label htmlFor='month-3'>3ヶ月以内</label>
            </div>
            <div style={{ marginRight: 24 }}>
              <input
                {...register('preferredDeliveryMonth', { required: true })}
                type='radio'
                id='month-6'
                value={'6ヶ月以内'} />

              <label htmlFor='sell'>6ヶ月以内</label>
            </div>
            <div>
              <input
                {...register('preferredDeliveryMonth', { required: true })}
                type='radio'
                id='month-12'
                value={'未定'} />

              <label htmlFor='none'>未定</label>
            </div>
          </div>
          {errors.preferredDeliveryMonth &&
          <p className={styles[`${blockClass}__error`]}>どれか1つを選択してください</p>
          }
        </div>
        <div className={styles[`${blockClass}__item`]}>
          <div className={styles[`${blockClass}__label`]}>
            <p
              className={[styles[`${blockClass}__label-mark`], styles[`${blockClass}__label-mark--optional`]].join(' ')}>

              任意
            </p>
            <p className={styles[`${blockClass}__label-text`]}>車種（グレード）</p>
          </div>
          <input {...register('carModelName')} className={styles[`${blockClass}__input`]} placeholder='NBOX' />
        </div>
        <div className={styles[`${blockClass}__item`]}>
          <div className={styles[`${blockClass}__label`]}>
            <p
              className={[styles[`${blockClass}__label-mark`], styles[`${blockClass}__label-mark--optional`]].join(' ')}>

              任意
            </p>
            <p className={styles[`${blockClass}__label-text`]}>予算</p>
          </div>
          <input {...register('budget')} className={styles[`${blockClass}__input`]} placeholder='300万以下' />
          {errors.budget && <p className={styles[`${blockClass}__error`]}>予算を入力してください</p>}
        </div>
        <div className={styles[`${blockClass}__item`]}>
          <div className={styles[`${blockClass}__label`]}>
            <p
              className={[styles[`${blockClass}__label-mark`], styles[`${blockClass}__label-mark--optional`]].join(' ')}>

              任意
            </p>
            <p className={styles[`${blockClass}__label-text`]}>年式</p>
          </div>
          <div className={`${styles[`${blockClass}__row`]} ${styles[`${blockClass}__row--end`]}`}>
            <input {...register('carModelYear')} className={styles[`${blockClass}__input`]} placeholder='H24' />
            <p className={styles[`${blockClass}__input-unit`]}>年以降</p>
          </div>
        </div>
        <div className={styles[`${blockClass}__item`]}>
          <div className={styles[`${blockClass}__label`]}>
            <p
              className={[styles[`${blockClass}__label-mark`], styles[`${blockClass}__label-mark--optional`]].join(' ')}>

              任意
            </p>
            <p className={styles[`${blockClass}__label-text`]}>走行距離</p>
          </div>
          <div className={`${styles[`${blockClass}__row`]} ${styles[`${blockClass}__row--end`]}`}>
            <input
              {...register('mileageKm')}
              className={styles[`${blockClass}__input`]}
              placeholder='50000'
              type='number' />

            <p className={styles[`${blockClass}__input-unit`]}>km以下</p>
          </div>
        </div>
        <div className={styles[`${blockClass}__item`]}>
          <div className={styles[`${blockClass}__label`]}>
            <p
              className={[styles[`${blockClass}__label-mark`], styles[`${blockClass}__label-mark--optional`]].join(' ')}>

              任意
            </p>
            <p className={styles[`${blockClass}__label-text`]}>カラー</p>
          </div>
          <input {...register('color')} className={styles[`${blockClass}__input`]} placeholder='黒、パープル' />
        </div>
        <div className={styles[`${blockClass}__item`]}>
          <div className={styles[`${blockClass}__label`]}>
            <p
              className={[styles[`${blockClass}__label-mark`], styles[`${blockClass}__label-mark--optional`]].join(' ')}>

              任意
            </p>
            <p className={styles[`${blockClass}__label-text`]}>必要装備</p>
          </div>
          <input
            {...register('requiredEquipment')}
            className={styles[`${blockClass}__input`]}
            placeholder='ドラレコ・電動スライドドア' />

        </div>
        <div className={styles[`${blockClass}__item`]}>
          <div className={styles[`${blockClass}__label`]}>
            <p
              className={[styles[`${blockClass}__label-mark`], styles[`${blockClass}__label-mark--optional`]].join(' ')}>

              任意
            </p>
            <p className={styles[`${blockClass}__label-text`]}>備考欄</p>
          </div>
          <textarea
            {...register('comment')}
            className={styles[`${blockClass}__input`]}
            placeholder={'はじめての車探しをしてます。\n初心者でも乗りやすい車を探したいです。'} />

          {errors.comment && <p className={styles[`${blockClass}__error`]}>備考欄を入力してください</p>}
        </div>
        <p className={styles[`${blockClass}__terms`]}>
          「送信」をクリックすることで、当社の<Link to='/terms'>利用規約</Link>及び
          <Link to='/privacy'>プライバシーポリシー</Link>に同意したものとみなします。
        </p>
        <button type='submit' className={styles[`${blockClass}__button`]} disabled={submitting}>
          送信
        </button>
      </form>
    </div>);

};