import { type FunctionComponent } from 'react';
import { Breadcrumb } from '~/components/shared';

export const TermsIndex: FunctionComponent = () => {
  return (
    <>
      <Breadcrumb text='BUDDICA DIRECT利用規約' />
      <h1 className='headline1'>BUDDICA DIRECT利用規約</h1>
      <p className='c-text'>
        このBUDDICA
        DIRECT利用規約（以下「本規約」といいます）は、BUDDICA・DIRECT株式会社（以下「当社」といいます）が運営する「本サービス」（第2項で定義します）の利用者（以下「ユーザー」といいます）による利用に関する権利義務関係を定めるものです。ユーザーは、本サービスを利用することにより、本規約に同意したものとみなされますので、本サービスの利用に先立ち、本規約の内容を十分にご確認ください。
      </p>

      <h2 className='headline2'>1.適用</h2>
      <ol className='c-numList'>
        <li>本規約は、ユーザーと当社との間の本サービスの利用に関わる一切の関係に適用されるものとします。</li>
        <li>
          当社が本規約以外に別途ユーザー向けに提示する本サービスに関する利用条件等並びに当社が本プラットフォーム（第2項で定義します）上で随時掲載する本サービスに関するルール、ガイドライン及び諸規定等（以下本項において利用条件等を含めて「諸規定等」といいます）は本規約の一部を構成するものとします。
        </li>
        <li>本規約の内容と、諸規定等の内容とが矛盾抵触する場合には、本規約の規定が優先して適用されるものとします。</li>
      </ol>
      <h2 className='headline2'>2.定義</h2>
      <ol className='c-numList'>
        <li>
          「本プラットフォーム」とは、当社が提供する車両通販プラットフォーム「BUDDICA
          DIRECT」のウェブサイト、アプリケーションその他のプラットフォームをいいます。
        </li>
        <li>
          「掲載企業」とは、別途当社が定める規約等に基づき掲載ページを通じて車両の販売、整備その他のサービスの提供を行う事業者をいいます。
        </li>
        <li>「会員」とは、次項の規定に基づき会員登録の申請を行い、当社がこれを承認したユーザーをいいます。</li>
        <li>「対象サービス」とは、掲載企業が本プラットフォームを通じて提供するサービスをいいます。</li>
        <li>「対象車両」とは、掲載企業が本プラットフォームに情報を掲載する車両をいいます。</li>
        <li>
          「掲載ページ」とは、掲載企業の情報、対象車両の情報その他当社が定める事項を記載し、当社及びユーザーとの連絡の送受信その他各種設定を行うためのウェブページをいいます。
        </li>
        <li>
          「本サービス」とは、当社が本プラットフォーム上で提供する対象車両の販売の媒介その他サービスに関する媒介サービスをいいます。
        </li>
        <li>
          「個人情報」とは、個人情報の保護に関する法律（平成15年5月30日法律第57号、以下「個人情報保護法」といいます）第2条第1項により定義された個人情報に該当するものをいいます。
        </li>
        <li>
          「反社会的勢力等」とは、暴力団、暴力団員、暴力団員でなくなったときから 5 年を経過しない者、
          暴力団準構成員、暴力団関係企業、総会屋、その他暴力、威力又は詐欺的手法を使用して経済的利益を追求する集団又は個人をいいます。
        </li>
        <li>
          「知的財産権」とは、著作権、特許権、実用新案権、商標権及び意匠権その他の知的財産権（それらの権利を取得し、又はそれらの権利につき登録等を出願する権利を含みます。）をいいます。なお、著作権については、著作権法（昭和45年法律第48号）第27条及び第28条に定める権利を含みます。
        </li>
      </ol>

      <h2 className='headline2'>3.会員登録</h2>
      <ol className='c-numList'>
        <li>
          本サービスのうち、本プラットフォームに掲載された情報の閲覧は、本規約を遵守することに同意する場合に限り利用することができます。
        </li>
        <li>
          本サービスに基づき対象車両の購入その他の対象サービスの利用を希望するユーザーは、当社の定める以下の各号の情報（以下「登録情報」といいます）を当社所定の方法により当社に提供することにより、会員登録の申請をする必要があります。会員登録の申請は必ず本サービスを利用するユーザー自身が行わなければならず、代理人による会員登録の申請は認められません。また、会員になろうとするユーザーは、会員登録の申請にあたり、真実、正確かつ最新の情報を当社に提供しなければなりません。
          <ol>
            <li>氏名及び住所</li>
            <li>LINEのID情報</li>
            <li>当社からの連絡を遅滞なく確認できる電子メールアドレス､電話番号その他当社所定の事項</li>
            <li>その他当社が指定する事項</li>
          </ol>
        </li>
        <li>
          当社は、当社の定める基準に従い、前項に基づいて会員登録の申請を行ったユーザーの会員登録の可否を判断し、当社が会員登録を承認する場合には、その旨を当該ユーザーに通知するものとします。
        </li>
        <li>
          当社は、ユーザーに以下の各号のいずれかの事由があると判断した場合、会員登録の承認を行わず、また既に行った承認を取り消すことがあります。なお、その理由については一切の開示義務を負わないものとします。
          <ol>
            <li>登録情報の全部又は一部につき、虚偽、誤記又は記載漏れがあった場合</li>
            <li>本規約に違反したことがある者又はその関係者からの申請であると当社が判断した場合</li>
            <li>
              反社会的勢力等である、又は資金提供その他を通じて反社会的勢力等の維持、運営若しくは経営に協力若しくは関与する等反社会的勢力との何らかの交流若しくは関与を行っていると当社が判断した場合
            </li>
            <li>過去に本規約違反を行った等の理由から、会員登録の取消しなどの処分を受けていることが判明した場合</li>
            <li>当社に対する債務の支払いを怠ったことがある場合</li>
            <li>
              未成年者、成年被後見人、被保佐人又は被補助人のいずれかであり、法定代理人、後見人､保佐人又は補助人の同意等を得ていない場合
            </li>
            <li>
              本サービスが犯罪による収益の移転やテロ資金の供与のために用いられるおそれがあると、当社が判断した場合
            </li>
            <li>その他、当社が本サービスの利用を相当でないと判断した場合</li>
          </ol>
        </li>
        <li>会員は、登録情報に変更があった場合、当該変更事項を遅滞なく当社に通知するものとします。</li>
      </ol>

      <h2 className='headline2'>4.会員登録の取消し（退会）</h2>
      <p className='c-text'>
        会員は、自らの意思で、会員登録の取消し（退会）を行い、本サービスの利用に関する契約（以下「本サービス利用契約」といいます）の解約を希望する場合には、当社所定の手続きを経ることで、これを行うことができます。なお、この場合、会員は当社に対して負っている債務の一切について、当然に期限の利益を失い、当社に対し、直ちに当該債務の全額を弁済するものとします。
      </p>

      <h2 className='headline2'>5.ID</h2>
      <ol className='c-numList'>
        <li>
          当社は、会員登録に際し、会員が当社に対し提出したLINEのID情報を確認した場合、当該LINEのIDを保有するものとして登録された会員が当該LINEのIDを利用したものとみなします。
        </li>
        <li>
          会員は、自己のLINEのIDを厳重に管理するものとし、LINEのIDの漏洩や使用上の過誤等により生じた会員の損害について、当社は一切の責任を負いません。
        </li>
        <li>
          会員は、LINEのIDの盗難、漏洩があった場合、LINEのIDを失念した場合又はLINEのIDが会員の意に反して第三者に使用されていることが疑われる場合には、直ちに当社にその旨を連絡し、当社の指示に従うものとします。
        </li>
        <li>
          会員が前項の連絡をしなかったことにより、本サービスを利用できない等何らかの不利益を被った場合、当社は一切の責任を負わないものとします。
        </li>
      </ol>

      <h2 className='headline2'>6.対象サービス</h2>
      <ol className='c-numList'>
        <li>
          ユーザーは、本サービスが掲載企業の対象サービスに関する情報の提供等を行ものにとどまり、当社はユーザーに対し対象サービスに関する契約の成立の場を提供するプラットフォーマーであること、当社は、対象サービスに関する契約の当事者ではなく、対象サービスに関する契約の履行には一切関与しないこと、対象サービスに関する契約はユーザーと掲載企業との間で直接に締結され成立するものであることを確認し、これを異議なく承諾するものとします。ユーザーは、対象サービスに関する契約の締結にあたっては、掲載企業が定める規約その他の契約内容を自己の責任において確認するものとします。
        </li>
        <li>
          当社は、掲載ページの情報については、常に正確かつ最新の情報を提示できるよう努力するものとしますが、掲載企業からの提供に基づく情報であるため、正確でない情報又は最新でない情報が含まれることがあり、ユーザーは、対象サービスに関する契約の締結に当たり、掲載企業が提示する情報その他の契約内容を自己の責任で確認するものとします。
        </li>
        <li>
          当社は、対象サービスに関する契約に関する紛争について一切責任を負わず、また、当該紛争の解決のためのあっせん、調停、仲裁その他の紛争解決に向けた措置を講じません。なお、当社は、本サービスの健全性確保等の見地から、事実関係の確認等をする場合があり、ユーザーはこれに誠実に協力するものとします。
        </li>
      </ol>

      <h2 className='headline2'>7.知的財産権等</h2>
      <p className='c-text'>
        本サービスに関する知的財産権は、すべて当社又は当社にライセンスを許諾している第三者に帰属しており、本規約に基づくユーザーへの本サービスの提供は、ユーザーに対する何らかの権利移転又は利用許諾を意味するものではありません。ユーザーは、いかなる理由によっても当社又は当社にライセンスを許諾している者のこれら権利を侵害するおそれのある行為（逆アセンブル、逆コンパイル、リバースエンジニアリングを含みますが、これらに限定されません）をしてはなりません。
      </p>

      <h2 className='headline2'>8.禁止事項</h2>
      <p className='c-text'>
        ユーザーは、本サービスの利用にあたり、次の各号のいずれかに該当する行為又は該当すると当社が判断する行為（不作為を含みます）をしてはなりません。
      </p>
      <ol className='c-numList'>
        <li>法令等又は公序良俗に違反する行為又はそのおそれのある行為</li>
        <li>犯罪行為、又は犯罪行為に関連し、若しくはそのおそれのある行為</li>
        <li>
          当社、他のユーザー､掲載企業その他の第三者に対し､財産権（知的財産権を含みます）の侵害､名誉・プライバシーの侵害､誹謗中傷､その他の不利益を与える行為又はそのおそれのある行為
        </li>
        <li>当社サービスの運営・維持を妨げる行為</li>
        <li>本サービスに関して利用し得る情報を改ざんする行為</li>
        <li>有害なコンピュータプログラム､メール等を本サービスに送信又は書き込む行為</li>
        <li>サーバその他当社のコンピュータに不正にアクセスする行為</li>
        <li>本サービスを通じた、本サービスの利用と関連のない営利目的行為又はその準備を目的とする行為</li>
        <li>
          ユーザーが当社の事業と競合する事業を行う事業者の役職員である場合において、本サービスを調査する目的で本サービスを利用する行為、本サービスと競合するサービスを宣伝する行為
        </li>
        <li>本サービスを模倣したサービスの制作、準備等を目的とした本サービスの利用行為</li>
        <li>本規約に違反する行為</li>
        <li>その他当社が不適切と判断する行為</li>
      </ol>

      <h2 className='headline2'>9.機密情報の保護</h2>
      <p className='c-text'>
        ユーザーは、本サービスの提供に必要なシステム（以下「本システム」といいます）及び本サービスに関する情報並びに当社が機密である旨を書面又は電磁的方法で明示して開示した情報（以下「機密情報」といいます）を、厳に機密として保持し、開示された目的以外には利用せず、また公表、利用、複写、開示若しくは漏洩等してはならないものとします。但し、以下の情報は機密情報に該当しないものとします。
      </p>
      <ol className='c-numList'>
        <li>開示を受けた時、既に自己が保有していたことを証明できる情報</li>
        <li>開示を受けた時、既に公知であった情報又はその後自己の責に帰すべき事由によらずに公知となった情報</li>
        <li>開示を受けた後に、第三者から守秘義務を負うことなく適法に取得した情報</li>
        <li>開示された機密情報によらず独自に開発し又は創作した情報</li>
      </ol>

      <h2 className='headline2'>10.個人情報の保護</h2>
      <ol className='c-numList'>
        <li>
          当社は、ユーザーの個人情報について、当社プライバシーポリシー（https://buddica.direct/privacy/）に従って利用及び管理等するものとし、ユーザーは、これに同意するものとします。
        </li>
        <li>
          当社は、掲載企業に対し、対象サービスに関する契約に関する限度で、以下に定める会員の情報の全部又は一部を提供するものとし（なお、提供する情報は変更することがあります。）、ユーザーはこれに同意するものとします。
          <p>①提供する目的</p>
          <div style={{ paddingLeft: 16 }}>
            <p>(a) 対象サービスの提供</p>
            <p>(b) 対象サービスの品質管理のためのアンケート調査及び分析</p>
            <p>(c) 対象サービスのアフターケア、問い合わせ対応</p>
            <p>(d) その他これらに付随する目的</p>
          </div>
          <p>②提供する情報</p>
          <div style={{ paddingLeft: 16 }}>
            <p>(a) 氏名</p>
            <p>(b) 住所</p>
            <p>(c) メールアドレス</p>
            <p>(d) LINEのID情報</p>
            <p>(e) 購入した車両に関する情報</p>
            <p>(f) その他対象サービスに関する契約の締結および履行に必要な情報</p>
          </div>
          <p>③提供方法</p>
          <p>データによる提供</p>
        </li>
        <li>
          前各項に定めるほか、当社は、個人情報について、個人情報保護法及び同法施行令並びに関係省庁による同法令に関するガイドライン等、個人情報保護に関する一切の法規及び当社プライバシーポリシーに従い適正に取り扱い、善良なる管理者の注意義務を持って管理するものとします。
        </li>
      </ol>

      <h2 className='headline2'>11.サービスの提供の停止等</h2>
      <p className='c-text'>
        当社は、以下のいずれかの事由があると判断した場合、ユーザーへの通知なく、本サービスの全部又は一部の提供を停止又は中断することができるものとします。
      </p>
      <ol className='c-numList'>
        <ol>
          <li>本システムの定期保守、点検、若しくは更新を行う場合、又はこれらを緊急に行う必要がある場合</li>
          <li>
            通常のウィルス対策では防止できないウィルスによる被害、火災、停電、天災地変などの不可抗力により、本サービスの提供が困難若しくは不可能な場合
          </li>
          <li>本システムの故障等が発生した場合</li>
          <li>法令又はこれらに基づく措置により本サービスの運営が不能となった場合</li>
          <li>その他不測の事態の発生により、本サービスの提供が困難若しくは不可能な場合</li>
        </ol>
      </ol>

      <h2 className='headline2'>12.利用停止及び解除</h2>
      <p className='c-text'>
        当社は、ユーザーが以下の各号のいずれかに該当する場合には、ユーザーへの事前の通知又は催告なく、当該ユーザーに対し、本サービスの全部若しくは一部の利用を制限し又は会員としての登録を抹消し、本サービス利用契約を解除することができるものとします。
      </p>
      <ol className='c-numList'>
        <ol>
          <li>本規約のいずれかの条項に違反した場合</li>
          <li>登録情報の全部又は一部につき、虚偽、誤記又は提供漏れがあることが判明した場合</li>
          <li>
            支払停止若しくは支払不能となり、破産、民事再生、会社更生若しくは特別清算の手続開始等の申立がなされ、又はその信用状況が悪化したと合理的に認められると当社が判断したとき
          </li>
          <li>死亡した場合又は後見開始、保佐開始若しくは補助開始の審判を受けた場合</li>
          <li>その他、当社が本サービスの利用を適当でないと判断した場合</li>
        </ol>
      </ol>

      <h2 className='headline2'>13.本サービスの内容の変更及び終了</h2>
      <ol className='c-numList'>
        <li>
          当社は、当社の都合により、本サービスの内容の変更若しくは追加又は提供の終了（以下本項において「変更等」といいます）をすることができます。
        </li>
        <li>
          前項の場合、当社は、ユーザーに対し、変更等の予定日の1か月前までに、ユーザーに対して変更等について通知するものとし、当該予定日までにユーザーによる契約終了の意思表示が当社に到達しない場合は、ユーザーは当該変更等を了承したものとみなされます。
        </li>
        <li>
          前項にかかわらず、当該変更等がユーザーに重大な不利益を生じさせるものでない場合又は緊急の必要があると当社が認める場合には、前項の通知なく変更等を行うことができるものとします。なお、この場合、事後的に当社が適切と判断する時期及び方法によりユーザーに対して通知するよう努力するものとします。
        </li>
      </ol>

      <h2 className='headline2'>14.非保証及び免責</h2>
      <ol className='c-numList'>
        <li>
          本サービスは現状有姿で提供されるものであり、当社は、本サービスにつき、エラー、バグ、不具合又はセキュリティ上の欠陥が存在しないこと、第三者の知的財産権その他の権利を侵害しないこと、ユーザーの期待する機能・商品的価値・正確性・有用性を有すること、特定の目的に適合すること、及びユーザーによる本サービスの利用がユーザーに適用のある法令等又は業界団体の内部規則等に適合することについて何ら保証しません。
        </li>
        <li>
          当社は、本プラットフォーム上において掲載企業から提供され掲載する一切の情報に関して、正確性、最新性、有用性、適合性、完全性、安全性、合法性及びその他一切の事由について何ら保証しません。
          本サービスの提供にあたり当社がユーザーに対し負担する損害賠償額は、損害の事由が生じた時点から遡って過去1年間に当該ユーザーが当社に対し支払った金員の総額（当該総額が0円の場合は1万円とします。）を上限とします。ただし、当社の故意又は重過失による場合を除きます。
        </li>
      </ol>

      <h2 className='headline2'>15.反社会的勢力の排除</h2>
      <ol className='c-numList'>
        <li>ユーザーは、自らが､反社会的勢力等でないことを誓約するものとします。</li>
        <li>
          当社は､ユーザーが次の各号に一つでも該当する場合､何らの通知又は催告なしに直ちに、当該ユーザーに対し、本サービスの全部若しくは一部の利用を制限し又は会員としての登録を抹消し、本サービス利用契約を解除することができるものとします。
          <ol>
            <li>
              ユーザーが自ら又は第三者を利用して､当社に対し暴力的行為､詐術､強迫的言辞､業務妨害行為などの行為をした場合
            </li>
            <li>ユーザーが､反社会的勢力等であることが判明した場合</li>
            <li>
              ユーザーが当社から求められた反社会的勢力等でないことの確認に関する調査等に協力せず､資料等を提出しない場合
            </li>
          </ol>
        </li>
      </ol>

      <h2 className='headline2'>16.損害賠償</h2>
      <ol className='c-numList'>
        <li>
          本サービスの利用に関し、ユーザーの責に帰すべき事由に起因して当社若しくは他のユーザー、掲載企業その他の第三者に損害が発生した場合、ユーザーは、その一切の損害（弁護士費用、当社又は本サービスの信用、イメージ等の回復に要する費用等の一切を含みます）を賠償する責を負います。
        </li>
        <li>
          前項のほか、ユーザーの行為に起因して、当社が掲載企業その他の第三者からの請求に応じて損害賠償その他の支払いを行うことを余儀なくされた場合、当該原因を作出したユーザーは、当社に対し、当社が被った一切の損害につき賠償するものとします。
        </li>
        <li>
          ユーザーが、本サービスに関連して、掲載企業、他のユーザーその他の第三者からクレームを受け又はそれらの者との間で紛争を生じた場合には、直ちにその内容を当社に通知するとともに、ユーザーの費用と責任において当該クレーム又は紛争を処理し、当社からの要請に基づき、その経過及び結果を当社に報告するものとします。
        </li>
        <li>
          当社は、本サービスに関して、ユーザーと掲載企業、他のユーザー又は第三者との間において生じた取引、連絡又は紛争等について一切責任を負いません。
        </li>
      </ol>

      <h2 className='headline2'>17.通知等</h2>
      <ol className='c-numList'>
        <li>
          ユーザーから当社に対する通知又は連絡（以下「通知等」といいます）は、別途当社の定める方法で行うものとします。
        </li>
        <li>
          当社からユーザーに対する通知等は、本プラットフォーム上での適宜の場所における掲載、本プラットフォームにおける通知その他当社が適切と判断する方法により行うものとし、その効力は、当社が当該通知等を発信した時点をもって発生するものとします。
        </li>
        <li>前項に定める通知等の効力は、ユーザーが現実に通知等を受領又は認識したかを問わず、発生するものとします。</li>
      </ol>

      <h2 className='headline2'>18.本規約の変更</h2>
      <p className='c-text'>
        当社は、当社が必要と判断した場合、いつでも本規約の変更、追加、削除等（以下本項において「変更等」といいます）をすることができるものとします。当社は、本規約を変更等する場合には、相当期間を定めて、当該変更等の内容及び効力発生時期を、本プラットフォーム上での適宜の場所における掲載その他の適切な方法により事前に周知するものとし、変更等に係る規約は、当該効力発生時期が到来した時点でその効力が生じるものとします。
      </p>

      <h2 className='headline2'>19.分離可能性</h2>
      <p className='c-text'>
        本規約のいずれかの条項又はその一部が、管轄権を有する裁判所により、違法、無効、又は執行不能とされた場合においても、残存部分についてはなお有効に存続します。また、残存部分について、当該条項若しくは部分の趣旨に最も近い有効な条項を無効な条項若しくは部分と置き換えて適用し、又は当該条項若しくは部分の趣旨に最も近い有効な条項となるよう合理的な解釈を加えて適用します。
      </p>

      <h2 className='headline2'>20.準拠法及び管轄裁判所</h2>
      <p className='c-text'>
        本規約及び本サービス利用契約に関しては、日本法が適用されるものとし、本規約又は本サービス利用契約に関する一切の訴訟については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。
      </p>
      <p className='c-text c-text--right'>2024年1月24日制定</p>
    </>
  );
};
